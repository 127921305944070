import styled, { css } from 'styled-components';

import {
   
    TitleLg,
    TitleSm,
} from "components/elements";
import { Col3, Row } from 'components/elements';
import {
    sizeWideScreen,
    sizeXlDevice,
    sizeLaptopMd,
    sizeTabletLg,
    sizeMobileLg,
    sizeMobileMd,
    sizeMobileSm, sizeDesktopMd
} from "components/devices";
import {
    BlogCardWrap, 
} from "components/BlogCard/elements";
import { InputWrap } from 'components/SubscribeCard';

export const BlogDetailPageWrap = styled.div`
    position: relative;
    ${TitleSm} {
        max-width: 1000px;
        margin-bottom: 45px;
        line-height: 1.3;
        font-size: 32px;
        ${sizeTabletLg(`
            margin-bottom: 25px;
        `)}

        /* For 4k Device */
        ${sizeXlDevice(`
            font-size: 2.2vw;
            max-width: 1440px;
        `)}
    }
    ${TitleLg} {
      ${sizeMobileMd(`
          font-size: 2.25em;
      `)}
      ${sizeMobileSm(`
          font-size: 2em;
      `)}
    }
  .blogCards-row {
    .blogCards-col {
      ${sizeLaptopMd(`
        flex: 0 0 50%;
        max-width: 50%;
      `)}
      ${sizeTabletLg(`
        flex: 0 0 100%;
        max-width: 100%;
      `)}
    }
  }
${Row}{
    padding: 0 15px;
    ${sizeMobileLg(`
        padding: 0 0;
    
    `)}
}
  ${Col3}{
    flex: 0 0 25%;

  }
`;

// BackLink styled-component
export const BackLink = styled.a`
  position: relative;
  top: 2px;
  color: #333333;
  font-size: 14px;
  font-family: 'Helvetica Now Display', sans-serif;
  font-weight: 700;
  letter-spacing: 0.5px;
  word-wrap: break-word;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
export const BackButtonWrap = styled.div`
  max-width: 1300px;
  margin: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0 30px;
  gap: 10px;
`;
// Back Button Container
export const BackButtonContainer = styled.div`
  width: 100%;
  padding: 16px 30px;
  background: white;
  border-bottom: 1px solid #999999;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 16px 30px;
  }
`;

export const BlogContainer = styled.div`
    padding: 0 40px 100px 40px;
    ${sizeTabletLg(`
        padding: 0 25px 50px 25px;
        overflow: hidden;
    `)}
`;
export const BlogInnerContainer = styled.div`
    padding: 0;
    box-sizing: border-box;
    ${sizeLaptopMd(`
        padding: 0;
    `)}


    ${sizeWideScreen(`
        max-width: 100%;
        width: 100%;
        margin: 0 auto;
        padding: 0 30px 0 0;
    `)}
`;
export const BlogContainerSm = styled.div`
    max-width: 1400px;
    padding-left: 27%;
    box-sizing: border-box;
    ${sizeTabletLg(`
        padding-left: 0;
    `)}

    /* For 4k Device */
    ${sizeXlDevice(`
        max-width: 1440px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    `)}


    /* Wide Screen CSS */
    ${sizeWideScreen(`
        max-width: 100% !important;
        width: 100%;
        margin: 0 auto;
        padding-left: 0;
    `)}
`;



// Blog Cards Mobile
export const BlogCardsMobile = styled.div`
    display: none;
    border-top: 1px solid #707070;;
    ${sizeMobileLg(`
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    `)}
    ${BlogCardWrap} {
        border: none;
        border-right: 1px solid #707070;
        border-bottom: 1px solid #707070;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        width: 50%;
        box-sizing: border-box;
        ${sizeMobileLg(`
            border-left: none;
            border-right: none;
            flex: 0 0 100%;
            width: 100%;
        `)}
    }
`;


export const BlogSubscribe = styled.div`
    position: fixed;
    left: -40px;
    top: 60%;
    transform: translateY(-50%);
    transition: .4s all ease;
    opacity: 0;
    z-index: -1;
    &.showSubcribe {
        opacity: 1;
        z-index: 99;
        left: 30px;
    }
  ${sizeDesktopMd(`
    top: 55%;
  `)}
    ${sizeTabletLg(`
        position: static;
        transform: none;
        padding: 0;
        opacity: 1;
        z-index: 99;
        margin: 0 -15px;
        padding:20px 0;
    `)}


    ${sizeWideScreen(css`
        ${InputWrap} {
            margin-top: 25px;
        }
    `)}
`;
export const SubscibeClose = styled.button`
    background-color: transparent;
    border: none;
    box-shadow: none;
    outline: none;
    position: absolute;
    left: 0;
    top: 15px;
    cursor: pointer;
    &:focus {
        outline: none;
        border: none;
        box-shadow: none;
    }
    svg {
        width: 45px;
        height: 45px;
        ${sizeWideScreen(css`
            width: 80px;
            height: 80px;
        `)}
    }
    ${sizeTabletLg(`
        display: none;
    `)}
`;

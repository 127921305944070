import React, {useCallback} from 'react';
import styled from 'styled-components';
import { Row, Col12, Col6 } from "../../components/elements";
import MediaCard from '../MediaCardNew';
import Button from "./MediaButton";
import { useAppContext } from '../../store';

// Define breakpoints for responsiveness
const breakpoints = {
  tablet: '1024px',
  mobile: '768px',
};

// Styled Components

const Container = styled.div`
  padding: 16px;
`;

const Title = styled.div`
  color: black;
  font-weight: 700;
  word-wrap: break-word;
  margin-bottom: 24px;
  font-size: ${(props) => (props.activeFilter ? '48px' : '80px')};

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${(props) => (props.activeFilter ? '36px' : '60px')};
  }

  @media (max-width: ${breakpoints.mobile}) {
    font-size: ${(props) => (props.activeFilter ? '28px' : '48px')};
  }
`;

const GridRow = styled(Row)`
  display: flex;
  flex-wrap: nowrap;
  gap: 24px;
  margin-bottom: 24px;
  align-items: stretch; /* Ensure both columns stretch to the same height */

  @media (max-width: ${breakpoints.tablet}) {
    gap: 16px;
    margin-bottom: 16px;
    flex-wrap: wrap;
    align-items: initial; /* Reset on smaller screens if desired */
  }
`;

const GridCol = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.type === 'large' &&
    `
    flex: 0 0 100%;
  `}

  ${(props) =>
    props.type === 'medium' &&
    `
    flex: 0 0 calc(50% - 12px);
  `}

  ${(props) =>
    props.type === 'small' &&
    `
    flex: 0 0 calc(50% - 12px);
    justify-content: space-between; 
  `}

  ${(props) =>
    props.type === 'small-no-image' &&
    `
    flex: 0 0 calc(50% - 12px);
  `}

  @media (max-width: ${breakpoints.tablet}) {
    flex: 0 0 100%;
    height: auto; /* Reset for mobile if needed */
    justify-content: flex-start;
  }
`;

const ButtonRow = styled(Row)`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  @media (max-width: ${breakpoints.tablet}) {
    justify-content: center;
    margin-top: 16px;
  }
`;

function MediaGrid({ featuredEvents, isLoading }) {


  const { selectedMediaFilters, setActiveMediaFilter, toggleMediaFilter } = useAppContext();

  // A helper to handle the "View All" button
  const handleViewAllClick = useCallback(() => {
    // 1) Set the store filters to "all"
    toggleMediaFilter('listAll');
    window.scrollTo(0, 0)
  }, [toggleMediaFilter, setActiveMediaFilter]);

  if (!featuredEvents || featuredEvents.length === 0) return null;




  // Limit to the first 6 events to match the layout structure
  const events = featuredEvents.slice(0, 6);

  // Create a helper function to extract image URL
  const getImageUrl = (article) => {
    const image = article.image
    if (!image) return '';
    if (typeof image === 'string') return image;
    return image.file?.url || '';
  };
  // Mapping from filter IDs to display names
  const filterDisplayNames = {
    BlogPost: 'Blogs',
    Podcast: 'Podcasts',
    Report: 'Reports',
    PressRelease: 'Press Releases',
    Event: 'Events',
    ArtificialIntelligence: 'Artificial Intelligence',
    Advertising: 'Advertising',
    AnalyticsInsights: 'Analytics + Insights',
    Activation: 'Activation',
    IntegratedCommunications: 'Integrated Communications',
    AnalyticsInsights: 'Analytics + Insights',
    MedicalCommunications: 'Medical Communications',
  };

  // Determine the title based on activeMediaFilter
  const title = (() => {
    const activeFilter = selectedMediaFilters[0];
    if (!activeFilter || activeFilter === 'all') {
      return 'Featured News';
    }

    // Check for specific filters
    if (['BlogPost', 'Podcast', 'PressRelease', 'Event', 'Report'].includes(activeFilter)) {
      return `Featured ${filterDisplayNames[activeFilter]}`;
    }

    // Default case for other filters
    return `What we are saying about '${filterDisplayNames[activeFilter]}'`;
  })();

  const isActiveFilter = selectedMediaFilters[0] && selectedMediaFilters[0] !== 'all';

  return (
    <Container>
      {/* Dynamic Title */}
      <Title activeFilter={isActiveFilter}>{title}</Title>

      {/* First Row: Large Card */}
      {events[0] && (
        <GridRow>
          <GridCol type="large">
            <MediaCard
              variant="large"
              isLoading={isLoading}
              padding="32px"
              cardType={events[0].type}
              link={events[0].detailLink || `article${events[0].slug}`}
              date={events[0].date}
              title={events[0].title}
              subtitle={events[0].overview ? events[0]?.overview : ''}
              chips={events[0].chips}
              img={getImageUrl(events[0])}
              ctaText="Read More"
              height="500px"
            />
          </GridCol>
        </GridRow>
      )}

      {/* Second Row: Medium and Small Cards */}
      {(events[1] || events[2] || events[3]) && (
        <GridRow>
          {/* Medium Card */}
          {events[1] && (
            <GridCol type="medium">
              <MediaCard
                variant="medium"
                isLoading={isLoading}
                padding="32px"
                cardType={events[1].type}
                link={events[1].detailLink || `article${events[1].slug}`} date={events[1].date}
                title={events[1].title}
                subtitle={events[1].overview ? events[1]?.overview : ''}
                chips={events[1].chips}
                img={getImageUrl(events[1])}
                ctaText="Read More"
                style={{ flex: 1 }}
              />
            </GridCol>
          )}

          {/* Two Small Cards */}
          {(events[2] || events[3]) && (
            <GridCol type="small">
              {events[2] && (
                <MediaCard
                  variant="small"
                  isLoading={isLoading}
                  padding="32px"
                  cardType={events[2].type}
                  link={events[2].detailLink || `article${events[2].slug}`} date={events[2].date}
                  title={events[2].title}
                  subtitle={events[2].overview ? events[2]?.overview : ''}
                  chips={events[2].chips}
                  img={getImageUrl(events[2])}
                  ctaText="Read More"
                  style={{ marginBottom: events[3] ? '24px' : '0' }}
                />
              )}
              {events[3] && (
                <MediaCard
                  variant="small"
                  isLoading={isLoading}
                  padding="32px"
                  cardType={events[3].type}
                  link={events[3].detailLink || `article${events[3].slug}`}
                  date={events[3].date}
                  title={events[3].title}
                  subtitle={events[3].overview ? events[3]?.overview : ''}
                  chips={events[3].chips}
                  img={getImageUrl(events[3])}
                  ctaText="Read More"
                />
              )}
            </GridCol>
          )}
        </GridRow>
      )}

      {/* Third Row: Small No-Image Cards */}
      {(events[4] || events[5]) && (
        <GridRow>
          {events[4] && (
            <GridCol type="small-no-image">
              <MediaCard
                variant="small-no-image"
                isLoading={isLoading}
                padding="32px"
                cardType={events[4].type}
                link={events[4].detailLink || `article${events[4].slug}`}
                date={events[4].date}
                title={events[4].title}
                subtitle={events[4].overview ? events[4]?.overview : ''}
                chips={events[4].chips}
                img={getImageUrl(events[4])}
                ctaText="Read More"
                height="300px"
              />
            </GridCol>
          )}
          {events[5] && (
            <GridCol type="small-no-image">
              <MediaCard
                variant="small-no-image"
                isLoading={isLoading}
                padding="32px"
                cardType={events[5].type}
                link={events[5].detailLink || `article${events[5].slug}`}
                date={events[5].date}
                title={events[5].title}
                subtitle={events[5].overview ? events[5]?.overview : ''}
                chips={events[5].chips}
                img={getImageUrl(events[5])}
                ctaText="Read More"
                height="300px"
              />
            </GridCol>
          )}
        </GridRow>
      )}

      {/* Button Row */}
      <ButtonRow>
        <Button
          variant="primary"
          // url="/all-news"
          onClick={handleViewAllClick}
          title="View All"
          width="245px"
          height="44px"
        />
      </ButtonRow>
    </Container>
  );
}

export default MediaGrid;

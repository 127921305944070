import React from 'react';
import styled, { css } from 'styled-components';
import Button from 'components/MediaGrid/MediaButton';

// ---------- Styled Components ----------

const Container = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 139px;
  width: 100%;
  height: 100%;
  padding: 96px 30px;

  /* Swap the flex direction if 'invertLayout' is true */
  ${({ invertLayout }) =>
    invertLayout &&
    css`
      flex-direction: row-reverse;
    `}

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    gap: 40px;
    padding: 48px 20px; 
  }
`;

const LeftWrapper = styled.div`
  width: 479px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;

  @media (max-width: 768px) {
    width: 90%;
    align-items: center;
    gap: 20px;
  }
`;

const TitleBlock = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 768px) {
    align-items: center;
    height: auto;
  }
`;

const Title = styled.div`
  align-self: stretch;
  color: #333333;
  font-size: 48px;
  font-family: 'Helvetica Now Display', sans-serif;
  font-weight: 700;
  line-height: 48px;
  word-wrap: break-word;

  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 36px;
  }
`;

const Description = styled.div`
  align-self: stretch;
  color: #333333;
  font-size: 16px;
  font-family: 'Helvetica Now Display', sans-serif;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.5px;
  word-wrap: break-word;
`;

const ButtonRow = styled.div`
  align-self: stretch;
  height: 50px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: center;
    height: auto;
  }
`;

const RightWrapper = styled.div`
  max-width: 582px;
  width: 100%;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const RightImage = styled.img`
  display: block;
  width: 100%;
  max-width: 100%;
  height: 492px;
  object-fit: cover; 
`;

// ---------- React Component ----------
/**
 * @param {string} title - Title text.
 * @param {string} description - Description text.
 * @param {string} imageSrc - Path to the right-side image.
 * @param {boolean} invertLayout - If true, swaps the text/image columns.
 * @param {string} [link="/media-resources"] - Destination for the button link.
 */
function MediaResources({ 
  title, 
  description, 
  imageSrc, 
  invertLayout = false, 
  link = "/media-resources" 
}) {
  return (
    <Container invertLayout={invertLayout}>
      <LeftWrapper>
        <TitleBlock>
          <Title>{title}</Title>
        </TitleBlock>

        <Description>{description}</Description>

        <ButtonRow>
          <Button
            variant="primary"
            url={link}
            title="Learn More"
            width="245px"
            height="44px"
          />
        </ButtonRow>
      </LeftWrapper>

      <RightWrapper>
        <RightImage src={imageSrc} alt="Media Resource" />
      </RightWrapper>
    </Container>
  );
}

export default MediaResources;

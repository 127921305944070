import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

// Components/Layouts
import PurpleAnimHeader from 'components/PurpleAnimHeader';
import FooterAnimation from 'components/FooterAnimation';
import { WideScreenContainer } from 'components/elements';

// New import
import MediaResourcesCard from 'components/MediaResourcesCard';

// (Optional) If you have a styled page wrapper
// import { InsightPageWrap } from './elements';

// Styled-components (or CSS Modules) for your grid
import styled from 'styled-components';

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  gap: 24px; /* space between cards */
  margin: 40px;

  /* Optional: stack on smaller screens */
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const heroIntroData = {
  content: [
    {
      content: [
        {
          value: `This is your one-stop destination for everything Real Chemistry. Designed exclusively for members of the press and multimedia professionals, this page provides quick access to essential company materials.
                Here, you’ll find corporate fact sheets, high-quality images, logos, archived press releases, and our latest social media updates. You can also explore media coverage featuring Real Chemistry.
                Use the navigation buttons below to access the resources you need.
                `,
        },
      ],
    },
  ],
};

function MediaResourcesPage() {
  // Force scroll to top on initial load.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="pageWrapper">
      {/* Basic SEO */}
      <Helmet>
        <title>Media Resources</title>
        <link rel="canonical" href={window.location.pathname} />
        <meta
          name="description"
          content="Welcome to Real Chemistry’s Media Resource Center. Access essential company materials including fact sheets, images, logos, press releases, and more."
        />
        <meta
          name="og:description"
          content="Welcome to Real Chemistry’s Media Resource Center. Access essential company materials including fact sheets, images, logos, press releases, and more."
        />
        <meta name="og:title" content="Media Resources" />
        <meta
          name="og:url"
          content={
            window.location.protocol +
            '//' +
            window.location.host +
            window.location.pathname
          }
        />
        <meta name="og:type" content="website" />
        <meta name="og:site_name" content="Real Chemistry" />
      </Helmet>

      <PurpleAnimHeader
        sectionLabel="Media Resources"
        heroIntro={heroIntroData}
        headerSplit="4"
        maxWidth="30%"
        marginRight="12%"
        backgroundImage="/images/what-we-do-header.svg"
        backgroundImgMbl="/images/what-we-do-mbl.svg"
        backgroundImgTitle=""
        // subHead={"So much talk. So little transformation. "}
        header="Welcome to Real Chemistry’s Media Resource Center"
        titleMdPadding="18% 0 0"
        headerMaxWidth="65%"
      />

      <WideScreenContainer>
        <CardGrid>
          <MediaResourcesCard
            title="Fact Sheet"
            buttonText="View Resource"
            url="https://assets.ctfassets.net/fzkxe4nzcokr/4rR4QtwLpHDECygDUkP9F6/706a2a8658b63834aeedb09cbfc0cbcc/RC_At-A-Glance_2022.pdf"
          />
          <MediaResourcesCard
            title="Meet Our Leaders"
            buttonText="View Resource"
            url="https://ent.box.com/s/ibd1b7k4hyjrmkhtksc8w23n5duv9eel"
          />
          <MediaResourcesCard
            title="ESG Fact Sheet"
            buttonText="View Resource"
            url="https://assets.ctfassets.net/fzkxe4nzcokr/rsBgNORNkGwuNCPB16poQ/3ebb55b7e566c73560714347e7fc8c25/RealChemistry_ESG_FactSheet_Final.pdf"
          />
          <MediaResourcesCard
            title="Contact Our Media Team"
            buttonText="View Resource"
            url="mailto:media@realchemistry.com"
          />
          <MediaResourcesCard
            title="Financials"
            buttonText="View Resource"
            url="/financial"
          />
          <MediaResourcesCard
            title="Recent News Releases"
            buttonText="View Resource"
            url="/press-releases"
          />
          <MediaResourcesCard
            title="Real Chemistry’s Brand and Multimedia Files for Use"
            buttonText="View Resource"
            url="http://ent.box.com/s/6rn9ctrit9b2p11vtvngciskokxipdnw"
          />
          <MediaResourcesCard
            title="Media Placements"
            buttonText="View Resource"
            url="/news-events#news"
          />
        </CardGrid>
      </WideScreenContainer>

      <FooterAnimation
        svgDesktop="/images/purple-footer.svg"
        svgMobile="/images/purple-footer-mbl.svg"
        borderTop="none"
      />
    </div>
  );
}

export default MediaResourcesPage;

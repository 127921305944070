import React from 'react'

import {
    WideScreenContainer,
	TitleXl,
	BodySm
} from 'components/elements'

import PurpleAnimHeader from 'components/PurpleAnimHeader';
// import ViewAll from 'components/ViewAll';

// import {r3cPageContent} from 'constants/index'
// import {findContent, getImageDimensions, isEmptyObject} from 'generalHelpers'

import FooterAnimation from 'components/FooterAnimation'
import LearnAboutOtherNewsletters from 'components/LearnAboutOtherNewsletters';
import { NewslettersPageWrap } from '../elements';
import {TitleXlContainer, BodySmContainer} from './elements';
// import useRememberScrollPosition from 'hooks/useRememberScrollPosition';
import LoadingHoc from 'hoc/LoadingHOC';



// import {Helmet} from "react-helmet";

// import useRememberScrollPosition from "../../../hooks/useRememberScrollPosition";



function Subscribe(props) {

	const {setLoading} = props		 //receive the loading trigger change function from the HOC

	React.useEffect(() => {

			setLoading(false)

		// eslint-disable-next-line
	}, [])


	// useRememberScrollPosition('subscribe')
	
	return (
		<NewslettersPageWrap className="pageWrapper">

			<PurpleAnimHeader
                //  sectionLabel={"Newsletters"}
				 sectionTitle={undefined}
				 heroIntro={undefined}
				 headerSplit={"2"}
				 backgroundImage={"/images/newsletters-header-dsktop.svg"}
				 backgroundImgMbl={"/images/newsletters-header-mbl.svg"}
				 backgroundImgTitle={""}
				 header={`Thanks for subscribing`}
				 introCopy={"Have we got a story for you. Our newsworthy moments don’t just revolve around press; they’re about our people too."}
				 titleMdPadding={"15% 0 0"}
				 maxWidth={"30%"}
				 marginRight={"15%"}
				 maxHeight={"700px"}

            />


			<WideScreenContainer>
				
					<TitleXlContainer>
						<TitleXl color="#000" fontWeight="900">You’re on the list for our blog.</TitleXl>
					</TitleXlContainer>
					<BodySmContainer>
						<BodySm color="#000" >We’ll share expert insights and perspectives that are crucial for healthcare leaders. You’ll be notified via email whenever we publish a new blog, press release, report, or podcast.</BodySm>
					</BodySmContainer>

				<LearnAboutOtherNewsletters
				  monthly={false}
				  healthEquity={true}
				  valueReport={true}
                  rdv={true}
				  displayHeader={false}

				/>

			</WideScreenContainer>

			<FooterAnimation
				svgDesktop="/images/purple-footer.svg"
				svgMobile="/images/purple-footer-mbl.svg"
				borderTop="none"
			/>
		</NewslettersPageWrap>
	)
}


export default LoadingHoc(Subscribe);

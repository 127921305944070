import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import { TitleSm } from 'components/elements'; // Adjust the import path as necessary
import { Link } from 'react-router-dom';

const ArrowIcon = ({ strokeColor = "none", fillColor = "white" }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Frame 4238">
      <path
        id="arrow_forward"
        d="M11.4904 8.35714H2V7.64286H11.4904L6.93939 3.50554L7.5 3L13 8L7.5 13L6.93939 12.4945L11.4904 8.35714Z"
        fill={fillColor}
        stroke={strokeColor}
      />
    </g>
  </svg>
);

// Styled Components
const ShrinkWrap = styled.div`
  width: 100%;
  height: 100%;
  padding: 9px 8px;
  border: 1px solid white;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  & > div {
    width: 16px;
    height: 16px;
    position: relative;

    & > div {
      width: 11px;
      height: 10px;
      position: absolute;
      left: 2px;
      top: 3px;
      background: white;
    }
  }
`;

const ButtonLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: 85%;
  height: 100%;
  text-decoration: none !important;
  background: ${({ variant }) => (variant === 'primary' ? '#000' : '#fff')};
  transition: 0.4s all ease;

  ${TitleSm} {
    font-size: 16px;
    line-height: 1;
    letter-spacing: 0.135em;
    transition: 0.4s all ease;
    font-weight: 700;
    color: ${({ variant }) => (variant === 'primary' ? '#fff' : '#000')};
  }
`;

const ArrowWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 3% 0 3%;
  position: relative;
  right: 2px;
  height: 100%;
  transition: 0.4s all ease;
  margin: 0 auto;
  background: ${({ variant }) => (variant === 'primary' ? '#fff' : '#000')};
  border-color: ${({ variant }) => (variant === 'primary' ? '#000' : '#fff')};
  
  svg {
    width: 20px;
    height: 28px;
    margin: 0 0 2px;
    transition: 0.4s all ease;
  }
`;

const ButtonWrap = styled.div`
&:focus,
&:hover {
  outline: none;
}
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid;
  border-color: ${({ variant }) => (variant === 'primary' ? '#000' : '#fff')};
  height: ${({ height }) => height || '44px'};
  width: ${({ width }) => width || '245px'};
  cursor: pointer;
  transition: 0.4s all ease;

  &:hover {
    ${ButtonLink} {
      background: ${({ variant }) => (variant === 'primary' ? '#fff' : '#000')};
      
      border-color: ${({ variant }) => (variant === 'primary' ? '#000 !important' : '#fff !important')};
      border-left: 2px solid;
      border-top: 2px solid;
      border-bottom: 2px solid;
      ${TitleSm} {
        color: ${({ variant }) => (variant === 'primary' ? '#000' : '#fff')};
      }
    }

    ${ArrowWrap} {
      background: ${({ variant }) => (variant === 'primary' ? '#000' : '#fff')};
      border-color: ${({ variant }) => (variant === 'primary' ? '#fff' : '#000')};
      
      svg path {
        stroke: ${({ variant }) => (variant === 'primary' ? '#fff' : '#000')};
      }
    }
    svg path {
      stroke: ${({ variant }) => (variant === 'primary' ? 'blue' : 'blue')};
    }
    border-color: ${({ variant }) => (variant === 'primary' ? '#fff' : '#000')};
  }
`;

// Button Component
const Button = ({
  variant = 'primary',
  link = '#',
  target = '_self',
  url,
  title,
  width,
  height,
  onClick,
  mobileShrink = false,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1050);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1250);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  if (mobileShrink && isMobile) {
    return (
      <ButtonWrap variant={variant} height={'37px'} width={'34px'} onClick={onClick}>
        <a href={url}>
        <ArrowIcon
          strokeColor={variant === 'primary' ? '#000' : '#fff'}
          fillColor={variant === 'primary' ? '#fff' : '#000'}
        
        />
        </a>
      </ButtonWrap>
    );
  }

  return (
    <ButtonWrap variant={variant} width={width} height={height} onClick={onClick}>
      <ButtonLink href={url}  variant={variant}>
        <TitleSm>{title}</TitleSm>
      </ButtonLink>
      <ArrowWrap variant={variant}>
        <ArrowIcon
          strokeColor={variant === 'primary' ? '#000' : '#fff'}
          fillColor={variant === 'primary' ? '#fff' : '#000'}
        />
      </ArrowWrap>
    </ButtonWrap>
  );
};

export default Button;

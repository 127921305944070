import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { smartTruncate } from 'generalHelpers';

// -------------------- Styled Components --------------------
const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 46px 30px;
  background: white;
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 1300px;
  margin: auto;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Title = styled.div`
  font-size: 24px;
  font-family: 'Helvetica Now Display', sans-serif;
  font-weight: 700;
  line-height: 28px;
  color: #333333;
  word-wrap: break-word;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const ListHeader = styled.div`
  width: 100%;
  padding: 8px;
  border-bottom: 1px solid #bdbdbd;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;

  @media (max-width: 768px) {
    display: none;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
  }
`;

const HeaderCell = styled.div`
  width: ${({ width }) => width || 'auto'};
  color: #333333;
  font-size: 20px;
  font-family: 'Helvetica Now Display', sans-serif;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ListRow = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 8px;
  background: white;
  border-bottom: 1px solid #bdbdbd;
  gap: 20px;
  cursor: pointer;

  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
  }
`;

const ListItemColumn = styled.div`
  width: ${({ width }) => width || 'auto'};
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: 768px) {
    width: 100%;
    overflow: scroll;
  }
`;

const ItemText = styled.div`
  font-size: ${({ size }) => size || '12px'};
  font-family: 'Helvetica Now Display', sans-serif;
  font-weight: ${({ weight }) => weight || '400'};
  width: 100%;
  line-height: 16px;
  color: #333333;
  word-wrap: break-word;
`;

const LoadMoreButton = styled.div`
  width: 150px;
  margin: 0 auto;
  padding: 13px 18px 12px 18px;
  border-radius: 3px;
  border: 1px solid #212121;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  margin-top: 16px;

  &:hover {
    background-color: #f0f0f0;
  }
`;

// -------------------- Custom Hook for Media Query --------------------
function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);
    setMatches(mediaQueryList.matches);

    const listener = (e) => setMatches(e.matches);
    mediaQueryList.addEventListener('change', listener);

    return () => {
      mediaQueryList.removeEventListener('change', listener);
    };
  }, [query]);

  return matches;
}

// -------------------- TopNewsListView Component --------------------
function TopNewsListView({ items = [] }) {
  const [visibleCount, setVisibleCount] = useState(8);
  // Determine if the viewport is mobile-sized
  const isMobile = useMediaQuery('(max-width: 768px)');
  const truncateLength = isMobile ? 80 : 120;

  // Only show a certain number of items; load more on button click
  const displayedItems = useMemo(
    () => items?.slice(0, visibleCount),
    [items, visibleCount]
  );

  const handleLoadMore = () => {
    setVisibleCount((prev) => prev + 8);
  };

  return (
    <Container>
      <Header>
        <Title>In The News</Title>
      </Header>

      {displayedItems.length === 0 ? (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          No top news available.
        </div>
      ) : (
        <>
          <ListContainer>
            <ListHeader>
              <HeaderCell width="60%">Title</HeaderCell>
              <HeaderCell width="20%">Date</HeaderCell>
              <HeaderCell width="20%">Source</HeaderCell>
            </ListHeader>

            {displayedItems.map((item, index) => (
              <ListRow
                key={index}
                onClick={() => window.open(item.externalUrl, '_blank')}
              >
                <ListItemColumn width="60%">
                  <ItemText weight="700" size="14px">
                    {smartTruncate(item.title, truncateLength)}
                  </ItemText>
                </ListItemColumn>

                <ListItemColumn width="20%">
                  <ItemText size="14px">{item.date}</ItemText>
                </ListItemColumn>

                <ListItemColumn width="20%">
                  <ItemText size="14px">{item.source}</ItemText>
                </ListItemColumn>
              </ListRow>
            ))}
          </ListContainer>

          {visibleCount < items.length && (
            <LoadMoreButton onClick={handleLoadMore}>
              <div
                style={{
                  color: '#212121',
                  fontSize: '15px',
                  fontFamily: 'Helvetica Now Display, sans-serif',
                  fontWeight: '600',
                  lineHeight: '15px',
                  wordWrap: 'break-word',
                }}
              >
                Load More...
              </div>
            </LoadMoreButton>
          )}
        </>
      )}
    </Container>
  );
}

export default TopNewsListView;

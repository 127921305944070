// RelatedContent.jsx
import React from 'react';
import styled from 'styled-components';
import { smartTruncate } from 'generalHelpers';

// Styled Components
const Container = styled.div`
  max-width: 1300px;
  margin: auto;
  padding: 0 25px 50px 25px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  @media (max-width: 768px) {
    padding: 0 50px 50px 50px
  }
`;

const TopBar = styled.div`
  /* Spans the full width, row with left "Dive deeper" and right "View more" */
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 24px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;

  @media (max-width: 768px) {
    gap: 20px;
  }
`;

const Title = styled.h2`
  margin: 0;
  color: #212121;
  font-size: 36px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  line-height: 45px;
  word-wrap: break-word;

  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 36px;
  }
`;

const ViewMoreWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;

  @media (max-width: 768px) {
    align-items: flex-start;
    gap: 12px;
  }
`;

const ViewMoreLink = styled.a`
  text-align: right;
  color: #212121;
  font-size: 24px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  line-height: 36px;
  word-wrap: break-word;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #376ff6; /* Optional hover color */
  }

  @media (max-width: 768px) {
    text-align: left;
    font-size: 20px;
    line-height: 30px;
  }
`;

const ItemsContainer = styled.div`
  /* Container for all items; can break into multiple rows if needed */
  display: flex;
  flex-direction: column;
  gap: 80px;
  width: 100%;

  @media (max-width: 768px) {
    gap: 40px;
  }
`;

// Modified parts of the RelatedContent.jsx
const ItemsRow = styled.div`
  /* Row that wraps items if needed */
  display: flex;
  flex-wrap: wrap;
  gap: 36px;
  width: 100%;
  justify-content: flex-start;

  @media (max-width: 1200px) {
    gap: 24px;
  }
`;

const ItemWrapper = styled.div`
  flex: 1 1 300px; /* Changed from 0 to 300px base */
  display: flex;
  flex-direction: column;
  gap: 40px;
  min-width: 240px; /* Reduced from 280px */
  max-width: 376px; /* Added max-width to match image size */

  @media (max-width: 768px) {
    gap: 20px;
    flex: 1 1 100%;
    max-width: 100%;
  }
`;

const RelatedImage = styled.img`
  width: 100%; /* Changed from fixed 376px */
  height: 206px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0.03) 100%);
  border-radius: 5px;
  object-fit: cover;
  cursor: pointer;

  @media (max-width: 780px) {
    height: auto;
  }
`;

const ItemTitle = styled.div`
  width: 100%; /* Changed from fixed 376px */
  color: #212121;
  font-size: 24px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  line-height: 36px;
  word-wrap: break-word;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #376ff6;
  }

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

const ItemInfoContainer = styled.div`
  /* Title + Author + Date container */
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const MetaRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap; /* Allows text to wrap on smaller screens */
`;

const MetaText = styled.div`
  color: ${({ color }) => color || '#212121'};
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 21px;
  word-wrap: break-word;
`;

const RelatedContent = ({ relatedContent = [] }) => {
  if (!relatedContent.length) return null;


  // Only show up to 3 items (edit if you want more)
  const displayedItems = relatedContent.slice(0, 3);

  const handleNavigate = (slug) => {
    window.location.href = `/article${slug}`;
  };

  return (
    <Container>
      {/* Top Bar */}
      <TopBar>
        <TitleWrapper>
          <Title>Dive Deeper</Title>
        </TitleWrapper>
        {/* <ViewMoreWrapper>
          <ViewMoreLink href='/news-events#all'>
            View More →
          </ViewMoreLink>
        </ViewMoreWrapper> */}
      </TopBar>

      {/* Items section */}
      <ItemsContainer>
        <ItemsRow>
          {displayedItems.map((item, idx) => (
            <ItemWrapper key={idx}>
              <RelatedImage
                src={item.image?.file?.url || item.image}
                alt={item.title}
                onClick={() => handleNavigate(item.slug)}
              />
              <ItemInfoContainer>
                <ItemTitle onClick={() => handleNavigate(item.slug)}>
                  {smartTruncate(item.title, 80)}
                </ItemTitle>

              </ItemInfoContainer>
            </ItemWrapper>
          ))}
        </ItemsRow>
      </ItemsContainer>
    </Container>
  );
};

export default RelatedContent;

import React, { useRef, useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import PropTypes from 'prop-types';
import Button from 'components/MediaGrid/MediaButton';

function TrendingNewsBanner({ copy, linkText, linkURL, imageSrc }) {
  // Refs to measure widths
  const containerRef = useRef(null);
  const textRef = useRef(null);
  const [textWidth, setTextWidth] = useState(100);

  // This state controls whether we apply the animation
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [startAnimation, setStartAnimation] = useState(false);

  // Measure text/container widths on mount & on window resize
  useEffect(() => {
    function checkOverflow() {
      if (containerRef.current && textRef.current) {
        // Compare scrollWidth of text to clientWidth of container
        const containerWidth = containerRef.current.clientWidth;
        const textWidth = textRef.current.scrollWidth;
        setTextWidth(textWidth);
        setShouldAnimate(textWidth > containerWidth);
      }
    }

    // Check on mount
    checkOverflow();

    // Start animation after 1.5 seconds
    const timer = setTimeout(() => {
      setStartAnimation(true);
    }, 1500);

    // Also re-check on window resize
    window.addEventListener('resize', checkOverflow);
    return () => {
      window.removeEventListener('resize', checkOverflow);
      clearTimeout(timer);
    };
  }, [copy]);

  return (
    <BlackWrapper>
      <BannerWrapper>
        <TrendingSection>
          <TrendingTitle>Trending</TrendingTitle>
          {/* Example usage of imageSrc if you want an <img> instead of the inline SVG */}
          {/* <TrendingImage src={imageSrc} alt="Trending icon" /> */}
        </TrendingSection>

        {/* Inline SVG or Icon */}
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            id="Union"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.26316 1.26316H11.3684V11.3684H1.26316V1.26316ZM0 12.6316V0H12.6316V11.3684H24V24H11.3684V12.6316H0ZM12.6316 12.6316H22.7369V22.7369H12.6316V12.6316Z"
            fill="white"
          />
        </svg>

        <ContentSection>
          <TextContainer ref={containerRef}>
            <CopyText ref={textRef} shouldAnimate={shouldAnimate && startAnimation} textWidth={textWidth}>
              {copy}
            </CopyText>
          </TextContainer>

          <LinkSection>
            <Button
              variant="secondary"
              url={linkURL}
              title={linkText}
              width="245px"
              height="44px"
              mobileShrink
            />
          </LinkSection>
        </ContentSection>
      </BannerWrapper>
    </BlackWrapper>
  );
}

TrendingNewsBanner.propTypes = {
  copy: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  linkURL: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
};

export default TrendingNewsBanner;

// ==================== Styled Components ====================

const BlackWrapper = styled.div`
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: center;

  @media (min-width: 2500px) {
    margin-top: 40px;
  }
`;

const BannerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 1300px;
  padding: 16px 30px;
  background: black;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: inline-flex;

  @media (max-width: 768px) {
    padding: 16px 20px;
    flex-wrap: wrap;
    align-items: center;
  }
`;

const TrendingSection = styled.div`
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
`;

const TrendingTitle = styled.div`
  color: white;
  font-size: 20px;
  font-family: 'Helvetica Now Display', sans-serif;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
`;

const TrendingImage = styled.img`
  width: 24px;
  height: 24px;
`;

// Keyframes for marquee effect (leftward scroll)
const marquee = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const ContentSection = styled.div`
  flex: 1 1 0;
  height: 34px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: flex;

  @media (max-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    height: auto;
  }
`;

const TextContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;   /* hides the text as it scrolls out of view */
  white-space: nowrap; /* ensures text stays on a single line */
`;

const CopyText = styled.div`
  display: inline-block;
  color: #fafafa;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 20px;
  @media (max-width: 1000px) {
    width: calc(100vw - 250px);
  }
  @media (max-width: 3000px) {
    width: calc(100vw - 450px);
  }
  /* Conditionally add the marquee animation only if shouldAnimate is true */
  ${({ shouldAnimate, textWidth }) =>
    shouldAnimate &&
    css`
  animation: ${keyframes`
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-${textWidth}px);
    }
  `} 45s linear infinite;
  
  @media (max-width: 768px) {
    animation: ${keyframes`
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-${textWidth}px);
      }
    `} 45s linear infinite;
  }
    `}
`;

const LinkSection = styled.div`
  position: relative;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  gap: 10px;

  @media (max-width: 950px) {
    top: 4px;
  }
`;

import React, { useState, useEffect } from "react";

import { PrivacyPolicyPage, ContactUsWrap } from "./elements";

import PrivacyInfoSection from "./PrivacyInfoSection";
import AccordionSection from "./AccordionSection";
import Copyrights from "./Copyrights";
import ViewAll from "components/ViewAll";
import NextPage from "components/NextPage";
import ContactModal from "components/ContactModal";
import { WideScreenContainer } from "components/elements";
import SEO from "../../components/Helmets/SEO";
import { Helmet } from "react-helmet";
import LoadingHoc from "../../hoc/LoadingHOC";
import useRememberScrollPosition from "../../hooks/useRememberScrollPosition";

function PrivacyPolicy(props) {
  useRememberScrollPosition('privacy-policy');
  const { setLoading } = props;
  const [openModal, setOpenModal] = useState(false);
  const [openInfoAccordion, setOpenInfoAccordion] = useState(false);
  const [openOtherAccordion, setOpenOtherAccordion] = useState([
    {
      id: "updatesPrivacyPolicy",
      state: false,
    },
    {
      id: "useData",
      state: false,
    },
    { id: "researchServices", state: false },
    {
      id: "informationDisclosed",
      state: false,
    },
    {
      id: "retentionPeriod",
      state: false,
    },
    {
      id: "security",
      state: false,
    },
    {
      id: "choiceAndAccess",
      state: false,
    },
    {
      id: "cookiesTrackingTech",
      state: false
    },
    {
      id: "californiaConsumers",
      state: false,
    },
    {
      id: "ukEuUsers",
      state: false,
    },
    {
      id: "candidateContractors",
      state: false,
    },
    {
      id: "contactUs",
      state: false,
    },
    {
      id: "subsidiaries",
      state: false,
    },
  ]);


  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 500)
    // eslint-disable-next-line
  }, [])


  useEffect(() => {
    const pageURL = window.location.href.split("#");

    if (pageURL[1] === "information-we-collect") {
      setOpenInfoAccordion(true);
    } else if (pageURL[1] === "cookiesTrackingTech") {
      let updatedCard = openOtherAccordion.filter(
        (a) => a.id === "cookiesTrackingTech"
      );
      updatedCard[0].state = true;
      setOpenOtherAccordion([...openOtherAccordion, updatedCard[0]]);
    } else if (pageURL[1] === "subsidiaries") {
      let updatedCard = openOtherAccordion.filter(
        (a) => a.id === "subsidiaries"
      );
      updatedCard[0].state = true;
      setOpenOtherAccordion([...openOtherAccordion, updatedCard[0]]);
    } else if (pageURL[1] === "contactUs") {
      let updatedCard = openOtherAccordion.filter((a) => a.id === "contactUs");
      updatedCard[0].state = true;
      setOpenOtherAccordion([...openOtherAccordion, updatedCard[0]]);
    } else if (pageURL[1] === "californiaConsumers") {
      let updatedCard = openOtherAccordion.filter(
        (a) => a.id === "californiaConsumers"
      );
      updatedCard[0].state = true;
      setOpenOtherAccordion([...openOtherAccordion, updatedCard[0]]);
    } else if (pageURL[1] === "ukEuUsers") {
      let updatedCard = openOtherAccordion.filter((a) => a.id === "ukEuUsers");
      updatedCard[0].state = true;
      setOpenOtherAccordion([...openOtherAccordion, updatedCard[0]]);
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [window.location.href]);


  const closeContactUsModal = () => {
    setOpenModal(false);
  };

  const handleToggleOtherAccordion = (id, state) => {
    let updatedCard = openOtherAccordion.filter((a) => a.id === id);
    updatedCard[0].state = state;

    setOpenOtherAccordion([...openOtherAccordion, updatedCard[0]]);
  };

  const handleOpenAccordion = (e) => {
    if (e && e.target && e.target.className === "internal-page-link") {
      setOpenInfoAccordion(true);
    }
  };

  const handleToggleInfoAccordion = (state) => {
    setOpenInfoAccordion(state);
  };

  return (
    <PrivacyPolicyPage className="pageWrapper">
      <SEO
        title={"Privacy Policy | Real Chemistry"}
        description={"Privacy policy"}
        pathname={window.location.pathname}
      />
      <Helmet>
        <title>{"Privacy Policy | Real Chemistry"}</title>
        <link rel="canonical" href={window.location.pathname} />
        <meta name="description" content={"Privacy policy"} />
        <meta name="og:description" content={"Privacy policy"} />
        <meta name="og:title" content={"Privacy Policy | Real Chemistry"} />
        <meta name="og:url" content={window.location.protocol + "//" + window.location.host + window.location.pathname} />
        <meta name="og:type" content="website" />
        <meta name="og:site_name" content="Real Chemistry" />
      </Helmet>

      <WideScreenContainer style={{ paddingTop: "50px" }} borderTop="1px solid #707070" borderLeft="1px solid #707070" borderRight="1px solid #707070">
        <PrivacyInfoSection
          handleToggleOtherAccordion={handleToggleOtherAccordion}
          date="MARCH 28, 2025"
          title="Privacy Policy"
        />

        <AccordionSection
          openInfoAccordion={openInfoAccordion}
          openOtherAccordion={openOtherAccordion}
          handleOpenAccordion={handleOpenAccordion}
          handleToggleOtherAccordion={handleToggleOtherAccordion}
          handleToggleInfoAccordion={handleToggleInfoAccordion}
        />

        <Copyrights
          body="A printable version of this policy is available <a href='pdf/RC_Privacy_Policy.pdf' target='_blank'>here</a>.<br/><br/>
          We reserve the right to modify this Policy as needed, for example, to comply with changes in laws, regulations,
                    or requirements introduced by applicable data protection authorities, changes to our business model,
                    structure, products or services. We encourage you to review this
                    Policy periodically to be informed of how we may use your personal information."
        />

        <ContactUsWrap>
          <ViewAll title={'Contact Us'} link={"/contact"} borderColor="#000" titleColor="#000" hoverBackground="#000" width="300px" height="44px" svgColor="#000" />
        </ContactUsWrap>
      </WideScreenContainer>
      <WideScreenContainer>
        <NextPage pageName="Go to Homepage" pageLink="/" back={true} />
      </WideScreenContainer>
      {/* Contact Modal */}
      <ContactModal
        show={openModal}
        closeContactUsModal={closeContactUsModal}
      />
    </PrivacyPolicyPage>
  );
}

export default LoadingHoc(PrivacyPolicy);

import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import Chips from '../../../components/Chips';

// REACT-SHARE IMPORTS
import {
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';

/************************************************
 *                STYLED COMPONENTS
 ************************************************/

// Wrapper for the entire Blog Hero section
const BlogHeroWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 38px;
  flex-wrap: wrap;
  padding: 120px 30px 40px 30px;
  margin: auto;
  max-width: 1300px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 24px;
  }
`;

// Left Section (Article Details)
const ArticleDetails = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

// Date and Title Container
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

// Date Text
const DateText = styled.div`
  color: #333333;
  font-size: 11px;
  font-family: 'Helvetica Now Display', sans-serif;
  font-weight: 400;
  line-height: 16px;
  word-wrap: break-word;
`;

// Title Text
const TitleText = styled.div`
  color: #333333;
  font-size: 32px;
  font-family: 'Helvetica Now Display', sans-serif;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 36px;
  word-wrap: break-word;
`;

// Author Information
const AuthorInfo = styled.div`
  color: #333333;
  font-size: 16px;
  font-family: 'Helvetica Now Display', sans-serif;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.25px;
  word-wrap: break-word;

  .author-block {
    margin-bottom: 4px;
  }
`;

// Tags Container
const TagsContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

// Overview Section
const OverviewSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

// Overview Container with Icon (shown only if NOT report)
const OverviewContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 16px;
  background: black;
  border-radius: 4px;
`;

// Overview Text
const OverviewText = styled.div`
  flex: 1 1 0;
  color: white;
  font-size: 16px;
  font-family: 'Helvetica Now Display', sans-serif;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.25px;
  word-wrap: break-word;
`;

// Read Time Text (shown only if NOT report)
const ReadTime = styled.div`
  color: #333333;
  font-size: 12px;
  font-family: 'Helvetica Now Display', sans-serif;
  font-weight: 700;
  line-height: 14px;
  word-wrap: break-word;
`;

// Right Section (Image and Summary)
const ImageAndSummary = styled.div`
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 14px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

// Main Image
const MainImage = styled.img`
  width: 100%;
  max-height: 387.33px;
  object-fit: cover;
  border-radius: 5px;
`;

// Mobile Image (visible only on mobile)
const MobileImage = styled.img`
  width: 100%;
  max-height: 387.33px;
  object-fit: cover;
  border-radius: 5px;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

// Summary Text
const SummaryText = styled.div`
  color: #333333;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 25.6px;
  word-wrap: break-word;

  span {
    ${({ bold }) =>
    bold &&
    `
      font-weight: 600;
      text-decoration: underline;
    `}
  }
`;

/************************************************
 *     MODAL + DOWNLOAD/SHARE BUTTONS (REPORT)
 ************************************************/
// Modal Overlay
export const ModalOverlay = styled.div`
  position: fixed;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

// Modal Container
export const ModalContainer = styled.div`
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  width: 90%;
  max-width: 800px;
  text-align: center;
`;

// Close Button
export const ModalCloseButton = styled.button`
  background: #3c00e7;
  color: #fff;
  padding: 8px 16px;
  margin-top: 16px;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

// ButtonsRow
export const ButtonsRow = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const ButtonWrapper = styled.div`
  flex: 1 1 auto;
  height: 48px;
  border-radius: 4px;
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const ButtonPrimary = styled.div`
  width: 100%;
  height: 48px;
  padding: 15px 20px;
  background: #3c00e7;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

export const ButtonPrimaryText = styled.div`
  color: white;
  font-size: 18px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  line-height: 18px;
  word-wrap: break-word;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const ButtonSecondaryWrapper = styled.div`
  width: 100%;
  max-width: 120px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonSecondary = styled.div`
  width: 100%;
  height: 48px;
  padding: 15px 20px;
  border-radius: 4px;
  border: 1px #bdbdbd solid;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

export const ButtonSecondaryText = styled.div`
  color: #212121;
  font-size: 18px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  line-height: 18px;
  word-wrap: break-word;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

/************************************************
 *              BLOG HERO COMPONENT
 ************************************************/
function BlogHero({ article }) {
  // We only need the modal & download/share if article.type is "report".
  const [showModal, setShowModal] = useState(false);

  // Mapping tag types to colors
  const tagColorMap = {
    blogs: '#D33939',
    events: '#22BF61',
    advertising: '#999999',
    // Add more mappings as needed
  };

  // Destructure the props
  const {
    type,
    date,
    title,
    authors,
    chips,
    overview,
    image,
    mobileImage,
    summary,
    sections = [],
    downloadLink,
    gatedDownload,  // <-- New field
  } = article;

  // 1) Gather all text from the article’s sections.
  const extractSectionText = (section) => {
    let textContent = '';
    if (section.text?.nodeType === 'document' && section.text?.content) {
      section.text.content.forEach((node) => {
        // paragraphs, headings, lists, etc.
        if (node.nodeType === 'paragraph' || node.nodeType === 'heading-3') {
          node.content.forEach((child) => {
            if (child.nodeType === 'text') {
              textContent += child.value + ' ';
            }
          });
        }
        if (
          node.nodeType === 'unordered-list' ||
          node.nodeType === 'ordered-list'
        ) {
          node.content.forEach((listItem) => {
            listItem.content.forEach((para) => {
              if (para.nodeType === 'paragraph') {
                para.content.forEach((child) => {
                  if (child.nodeType === 'text') {
                    textContent += child.value + ' ';
                  }
                });
              }
            });
          });
        }
      });
    }
    return textContent;
  };

  // Combine all text into one big string
  const combinedText = useMemo(() => {
    return sections.reduce((acc, section) => acc + extractSectionText(section), '');
  }, [sections]);

  // 2) Count words
  const totalWords = useMemo(() => {
    const trimmed = combinedText.trim();
    if (!trimmed) return 0;
    return trimmed.split(/\s+/).length;
  }, [combinedText]);

  // 3) Calculate reading time (assume ~200 wpm)
  const wordsPerMinute = 200;
  const minutes = totalWords > 0 ? Math.ceil(totalWords / wordsPerMinute) : 1;
  const readTime = `${minutes} min read`;

  // Check location for react-share
  const shareUrl = typeof window !== 'undefined' ? window.location.href : 'https://yourdomain.com/';

  // Updated click handler for the "Download" button
  const handleDownloadClick = () => {
    // If it's gated, pop up the modal instead of direct download
    if (gatedDownload) {
      setShowModal(true);
    } else {
      if (downloadLink) {
        window.open(downloadLink, '_blank');
      }
    }
  };

  console.log(window?.location?.href)
  return (
    <>
      <BlogHeroWrap>
        {/* LEFT SIDE */}
        <ArticleDetails>
          <TitleContainer>
            <DateText>{date}</DateText>
            <TitleText>{title}</TitleText>
          </TitleContainer>

          <AuthorInfo>
            {authors && authors.length > 0 && <strong>Written by: </strong>}
            {authors?.map((authorItem, i) => (
              <span className="author-block" key={authorItem.cfid}>
                {authorItem.name}
                {authorItem.position ? `, ${authorItem.position} ` : ''}
                {i < authors.length - 1 && ' / '}
              </span>
            ))}
          </AuthorInfo>

          <TagsContainer>
            <Chips initialDelay={900} chips={chips} />
          </TagsContainer>

          <OverviewSection>
            {type !== 'Report' ? (
              // IF NOT REPORT => Show the original "overview" + "read time"
              <>
                <ButtonsRow>
                  <ButtonWrapper>
                    {/* LinkedIn */}
                    <LinkedinShareButton
                      url={window?.location?.href}
                      title={title}
                      // summary={`Check out this report: ${title}`}
                      style={{ width: '100%' }}
                    >
                      <ButtonPrimary>
                        <ButtonPrimaryText>Share on LinkedIn</ButtonPrimaryText>
                      </ButtonPrimary>
                    </LinkedinShareButton>
                  </ButtonWrapper>

                  <ButtonWrapper>
                    {/* X (Twitter) */}
                    <TwitterShareButton
                      url={window.location.href}
                      title={title}
                      style={{ width: '100%' }}
                    >
                      <ButtonPrimary>
                        <ButtonPrimaryText>Share on X</ButtonPrimaryText>
                      </ButtonPrimary>
                    </TwitterShareButton>
                  </ButtonWrapper>
                </ButtonsRow>
                {overview && (
                  <OverviewContainer>
                    {/* Example Icon (SVG) */}
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        id="Union"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1.26316 1.26316H11.3684V11.3684H1.26316V1.26316ZM0 12.6316V0H12.6316V11.3684H24V24H11.3684V12.6316H0ZM12.6316 12.6316H22.7369V22.7369H12.6316V12.6316Z"
                        fill="white"
                      />
                    </svg>
                    <OverviewText>Quick Overview: {overview}</OverviewText>
                  </OverviewContainer>
                )}
                <ReadTime>{readTime}</ReadTime>
              </>
            ) : (
              // IF REPORT => Show Download & (new) Share Buttons
              <>
                {/* 1) DOWNLOAD BUTTON */}
                {type === 'Report' && (
                  <>
                    <ButtonsRow>
                      <ButtonWrapper>
                        <ButtonPrimary onClick={handleDownloadClick}>
                          {/* Example download icon */}
                          <svg
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.40625 0.75H8.59375C8.94922 0.75 9.25 1.05078 9.25 1.40625V6H11.6289C12.1211 6 12.3672 6.60156 12.0117 6.95703L7.85547 11.1133C7.66406 11.3047 7.30859 11.3047 7.11719 11.1133L2.96094 6.95703C2.60547 6.60156 2.85156 6 3.34375 6H5.75V1.40625C5.75 1.05078 6.02344 0.75 6.40625 0.75ZM14.5 11.0312V14.0938C14.5 14.4766 14.1992 14.75 13.8438 14.75H1.15625C0.773438 14.75 0.5 14.4766 0.5 14.0938V11.0312C0.5 10.6758 0.773438 10.375 1.15625 10.375H5.14844L6.48828 11.7148C7.03516 12.2891 7.9375 12.2891 8.48438 11.7148L9.82422 10.375H13.8438C14.1992 10.375 14.5 10.6758 14.5 11.0312ZM11.1094 13.4375C11.1094 13.1367 10.8633 12.8906 10.5625 12.8906C10.2617 12.8906 10.0156 13.1367 10.0156 13.4375C10.0156 13.7383 10.2617 13.9844 10.5625 13.9844C10.8633 13.9844 11.1094 13.7383 11.1094 13.4375ZM12.8594 13.4375C12.8594 13.1367 12.6133 12.8906 12.3125 12.8906C12.0117 12.8906 11.7656 13.1367 11.7656 13.4375C11.7656 13.7383 12.0117 13.9844 12.3125 13.9844C12.6133 13.9844 12.8594 13.7383 12.8594 13.4375Z"
                              fill="white"
                            />
                          </svg>
                          <ButtonPrimaryText>Download</ButtonPrimaryText>
                        </ButtonPrimary>
                      </ButtonWrapper>
                    </ButtonsRow>
                    <ButtonsRow>
                      <ButtonWrapper>
                        {/* LinkedIn */}
                        <LinkedinShareButton
                          url={window?.location?.href}
                          title={title}
                          // summary={`Check out this report: ${title}`}
                          style={{ width: '100%' }}
                        >
                          <ButtonPrimary>
                            <ButtonPrimaryText>Share on LinkedIns</ButtonPrimaryText>
                          </ButtonPrimary>
                        </LinkedinShareButton>
                      </ButtonWrapper>

                      <ButtonWrapper>
                        {/* X (Twitter) */}
                        <TwitterShareButton
                          url={window.location.href}
                          title={title}
                          style={{ width: '100%' }}
                        >
                          <ButtonPrimary>
                            <ButtonPrimaryText>Share on X</ButtonPrimaryText>
                          </ButtonPrimary>
                        </TwitterShareButton>
                      </ButtonWrapper>
                    </ButtonsRow>
                  </>
                )}
                {/* 2) SHARE BUTTONS (NEW LINE) */}

              </>
            )}

          </OverviewSection>
        </ArticleDetails>

        {/* RIGHT SIDE */}
        <ImageAndSummary>
          <MainImage src={image} alt={title} />
          {/* 
            If you want a summary with bold/underline parts:
            <SummaryText>
              {summary?.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.bold ? 600 : 400,
                    textDecoration: part.bold ? 'underline' : 'none',
                  }}
                >
                  {part.text}
                </span>
              ))}
            </SummaryText>
          */}
        </ImageAndSummary>
      </BlogHeroWrap>

      {/* MODAL SECTION (renders only if showModal=true) */}
      <ModalOverlay show={showModal} onClick={() => setShowModal(false)}>
        <ModalContainer onClick={(e) => e.stopPropagation()}>
          {/* Embed your Pardot form in an iframe */}
          <iframe
            src={downloadLink}
            width="100%"
            height="500px"
            frameBorder="0"
            title="Pardot Form"
            style={{ border: 'none' }}
          ></iframe>

          <ModalCloseButton onClick={() => setShowModal(false)}>
            Close
          </ModalCloseButton>
        </ModalContainer>
      </ModalOverlay>
    </>
  );
}

export default BlogHero;

import React, { useRef, useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { categoryColors } from '../../constants';

// ---------- Default Colors & Lookup Function ----------
const defaultChipColors = {
  borderColor: '#ccc',
  textColor: '#252525',
  hoverBackground: '#f3f3f3',
  hoverColor: '#b3b3b3',
};

const contentTypeColorMap = {
  BlogPost: 'Blogs',
  Podcast: 'Podcasts',
  PressRelease: 'Press Releases',
  Event: 'Events',
  Report: 'Reports',
  Activation: 'Activation',
  Advertising: 'Advertising',
  'Analytics + Insights': 'AnalyticsInsights',
  'Integrated Communications': 'IntegratedCommunications',
  'Medical Communications': 'MedicalCommunications',
  'Artificial Intelligence': 'ArtificialIntelligence',
};

const getChipColors = (chip) => {
  const colorKey = contentTypeColorMap[chip] || chip;
  return categoryColors[colorKey] || defaultChipColors;
};

// ---------- Debounce Utility ----------
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

// ---------- Styled Components ----------
const ChipsContainer = styled.div`
  position: relative; /* Overall container */
  display: flex;
  align-items: baseline;
  height: 26px;
  flex-wrap: nowrap;
  width: 100%;
  gap: 8px;
`;

// New wrapper for ellipsis chip to anchor popover
const EllipsisWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const Chip = styled.span`
  display: inline-flex;
  height: 26px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border: 1px solid ${({ borderColor }) => borderColor || '#e0e0e0'};
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  color: ${({ textColor }) => textColor || '#333'};
  padding: 4px 12px;
  font-size: 12px;
  font-weight: 700;
  transition: all 0.3s ease;
`;

const MoreChipsPopover = styled.div`
  position: absolute;
  top: 110%;  /* positions the popover right under the ellipsis chip */
  left: 0;
  z-index: 9999;
  background: #fff;
  border: 1px solid #ccc;
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
`;

/**
 * Helper to compare two arrays shallowly
 */
function shallowArrayEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
}

/**
 * ChipsList Component
 *
 * @param {string[]} chips - An array of chip labels (or IDs).
 * @param {number} [extraSpace=40] - Extra space reserved for truncation logic.
 * @param {number} [initialDelay=100] - Milliseconds to wait before measuring on mount.
 * @param {boolean} [test=false] - Testing mode (forces container width=200 on desktop).
 */
const ChipsList = ({
  chips = [],
  extraSpace = 40,
  initialDelay = 100,
  test = false,
}) => {
  const chipsContainerRef = useRef(null);

  const [visibleChips, setVisibleChips] = useState(chips);
  const [showEllipsis, setShowEllipsis] = useState(false);
  const [showMore, setShowMore] = useState(false); // popover toggle

  // We'll store the last measured width, desktop state, and chip array in refs
  const lastMeasuredWidthRef = useRef(null);
  const lastIsDesktopRef = useRef(null);
  const lastChipsRef = useRef(chips);

  // Determine which chips are "hidden" and only appear in the popover
  const hiddenChips = showEllipsis ? chips.slice(visibleChips.length) : [];

  /**
   * Close the popover if we click outside
   */
  const handleClickOutside = useCallback(
    (e) => {
      if (chipsContainerRef.current && !chipsContainerRef.current.contains(e.target)) {
        setShowMore(false);
      }
    },
    [setShowMore]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  /**
   * Determines how many chips can fit in the container before truncation.
   * On mobile (below 700px), we only show the first chip + ellipsis if there's more.
   */
  const calculateVisibleChips = useCallback(() => {
    if (!chipsContainerRef.current) return;

    const isDesktop = window.innerWidth >= 700;
    const containerWidth = test && isDesktop
      ? 200
      : chipsContainerRef.current.offsetWidth;

    // Check if we need to recalc:
    // Only skip if width is unchanged, device is the same, AND chips are the same
    if (
      containerWidth === lastMeasuredWidthRef.current &&
      isDesktop === lastIsDesktopRef.current &&
      shallowArrayEqual(chips, lastChipsRef.current)
    ) {
      return;
    }

    // Store the current values in refs for comparison next time
    lastMeasuredWidthRef.current = containerWidth;
    lastIsDesktopRef.current = isDesktop;
    lastChipsRef.current = chips;

    // ---- Mobile case: show only the first chip + ellipsis if there's more
    if (!isDesktop) {
      setVisibleChips(chips.slice(0, 1));
      setShowEllipsis(chips.length > 1);
      setShowMore(false); // hide any popover if resizing
      return;
    }

    // ---- Desktop logic: width-based truncation ----
    let totalWidth = 0;
    let lastVisibleIndex = chips.length;

    for (let i = 0; i < chips.length; i++) {
      const chipElement = chipsContainerRef.current.children[i];

      // If the chip element is missing, assume a fallback width
      const chipWidth = chipElement
        ? chipElement.offsetWidth + 8 // 8 for the gap
        : 300;

      // If adding this chip plus the extraSpace exceeds containerWidth, we truncate
      if (totalWidth + chipWidth + extraSpace <= containerWidth) {
        totalWidth += chipWidth;
      } else {
        lastVisibleIndex = i;
        break;
      }
    }

    setVisibleChips(chips.slice(0, lastVisibleIndex));
    setShowEllipsis(lastVisibleIndex < chips.length);
    setShowMore(false); // hide popover if resizing
  }, [chips, extraSpace, test]);

  // Debounced resize listener
  useEffect(() => {
    const debouncedCalc = debounce(calculateVisibleChips, 100);
    window.addEventListener('resize', debouncedCalc);
    return () => {
      window.removeEventListener('resize', debouncedCalc);
    };
  }, [calculateVisibleChips]);

  // Delayed measurement on initial mount or when chips change
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      calculateVisibleChips();
    }, initialDelay);
    return () => clearTimeout(timeoutId);
  }, [calculateVisibleChips, initialDelay]);

  return (
    <ChipsContainer ref={chipsContainerRef}>
      {visibleChips.map((chip, index) => {
        const chipStyle = getChipColors(chip);
        return (
          <Chip
            key={index}
            borderColor={chipStyle.borderColor}
            textColor={chipStyle.textColor}
            hoverBackground={chipStyle.hoverBackground}
          >
            {chip}
          </Chip>
        );
      })}

      {showEllipsis && (
        <EllipsisWrapper>
          <div style={{cursor: 'pointer'}}>
            <Chip
              onClick={() => setShowMore(!showMore)}
              borderColor="#ccc"
              textColor="#333"
              hoverBackground="#f0f0f0"
            >
              ...
            </Chip>
          </div>

          {showMore && (
            <MoreChipsPopover>
              {hiddenChips.map((chip, i) => {
                const chipStyle = getChipColors(chip);
                return (
                  <Chip
                    key={`hidden-${i}`}
                    borderColor={chipStyle.borderColor}
                    textColor={chipStyle.textColor}
                    hoverBackground={chipStyle.hoverBackground}
                  >
                    {chip}
                  </Chip>
                );
              })}
            </MoreChipsPopover>
          )}
        </EllipsisWrapper>
      )}
    </ChipsContainer>
  );
};

export default ChipsList;

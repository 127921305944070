import React, { useState, useEffect, useCallback } from 'react';
import ChipsList from '../Chips'; // <-- Import new component
import styled from 'styled-components';
import moment from 'moment';
import { smartTruncate } from '../../generalHelpers';
import MediaType from '../MediaType';

// Import your icons
import ReleaseIcon from '../../assets/icons/ReleaseIcon';
import BlogIcon from '../../assets/icons/BlogIcon';
import PodcastIcon from '../../assets/icons/PodcastIcon';
import EventsIcon from '../../assets/icons/EventsIcon';
import ArrowIcon from '../../assets/icons/ArrowIcon';

const MediaCardWrap = styled.div`
  display: flex;
  height: ${(props) => props.height || 'auto'};
  flex-direction: ${(props) => props.flexDirection || 'row'};
  justify-content: center;
  align-items: ${(props) => props.alignItems || 'flex-start'};
  gap: 32px;
  margin: 12px 0;
  flex: ${(props) => props.flex || 'none'};
  align-self: ${(props) => props.alignSelf || 'auto'};
  /* Remove the pointer cursor at the card level */
  cursor: default; 
  background-color: ${(props) => (props.background ? '#f9f9f9' : 'transparent')};
  padding: ${(props) => props.padding || '0'};
  border: 2px solid var(--Neutral-400, #BDBDBD);

  @media (max-width: 1024px) {
    overflow: visible;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: ${(props) => props.flexDirection || 'row'};
  width: 100%;

  /* Force stacking (column) on mobile screens */
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const MediaCardHeader = styled.div`
  display: flex;
  align-items: center;
  padding: ${(props) => props.headerPad || '0'};
  width: 100%;
`;

const MediaImgWrap = styled.div`
  margin-bottom: ${(props) => (props.variant === 'medium' ? '16px' : '0')};
  width: ${(props) => (props.variant === 'medium' ? '100%' : '50%')};
  flex: ${(props) => props.flex || 'none'};
  padding: ${(props) => props.imgPadding || '0'};
  margin: auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const MediaImg = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  object-fit: ${(props) => (props.variant === 'small' ? 'cover' : 'contain')};
  height: 246px;
  /* Ensure the cursor is a pointer on hover */
  cursor: pointer;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

const MediaCardBody = styled.div`
  padding: ${(props) => props.mediaBodyPad || '0'};
  width: 100%;
`;

const DateText = styled.span`
  font-size: 14px;
  color: #666;
`;

const TitleText = styled.h2`
  font-size: ${({ variant }) =>
    variant === 'medium' || variant === 'large' ? '32px' : '24px'};
  margin: 8px 0;
  color: var(--Text-Black, #333);
  font-feature-settings: 'liga' off, 'clig' off;
  font-weight: 700;
  text-transform: capitalize;
  /* Ensure the cursor is a pointer on hover */
  cursor: pointer;
`;

const SubtitleText = styled.h3`
  color: #333;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ variant }) =>
    variant === 'medium' || variant === 'large' ? '32px' : '24px'};
  margin: 24px 0;
`;

function MediaCard({
  cardType,
  variant = 'default',
  date,
  title,
  subtitle,
  chips = [],
  img,
  link,
  background,
  padding,
  headerPad,
  imgPadding,
  mediaBodyPad,
  height,
}) {
  const [imageLoading, setImageLoading] = useState(true);
  const [mobileView, setMobileView] = useState(false);

  const handleWindowResize = useCallback(() => {
    setMobileView(window.innerWidth < 767);
  }, []);

  useEffect(() => {
    // Check initial size
    handleWindowResize();
    // Listen for resize
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [handleWindowResize]);

  // Click handler for image or title only
  const openLink = () => {
    if (link) {
      window.location.href = link; // or window.open(link, "_blank"); if you want a new tab
    }
  };

  const getVariantStyles = () => {
    switch (variant) {
      case 'large':
        return {
          width: '100%',
          flexDirection: 'row',
          alignItems: 'flex-start',
        };
      case 'medium':
        return {
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          height: '97%',
          gap: '16px',
        };
      case 'small':
        return {
          flexDirection: 'row',
          alignItems: 'center',
          flex: '1 0 0',
        };
      case 'small-no-image':
        return {
          flexDirection: 'row',
          alignItems: 'center',
          alignSelf: 'stretch',
        };
      default:
        return {
          flexDirection: 'column',
          alignItems: 'center',
          flex: '1 0 0',
        };
    }
  };

  const variantStyles = getVariantStyles();

  return (
    <MediaCardWrap
      {...variantStyles}
      background={background}
      padding={padding}
    >
      <Row flexDirection={variantStyles.flexDirection}>
        {variant !== 'small-no-image' && (
          <MediaImgWrap variant={variant} imgPadding={imgPadding}>
            <MediaImg
              variant={variant}
              onLoad={() => setImageLoading(false)}
              src={!imageLoading ? img : '/images/skeleton.gif'}
              alt="Media cover"
              onClick={openLink}
            />
          </MediaImgWrap>
        )}

        <ContentContainer>
          {cardType !== 'News' && (
            <MediaCardHeader headerPad={headerPad}>
              <DateText>{moment(date).format('MMM D, YYYY')}</DateText>
            </MediaCardHeader>
          )}

          <MediaCardBody mediaBodyPad={mediaBodyPad}>
            {title && (
              <TitleText variant={variant} onClick={openLink}>
                {smartTruncate(
                  title,
                  variant === 'large' || variant === 'small-no-image'
                    ? 300
                    : variant === 'medium'
                    ? 300
                    : 80
                )}
              </TitleText>
            )}

              <SubtitleText variant={variant}>
                {false && 
                smartTruncate(
                  subtitle,
                  variant === 'large' || variant === 'small-no-image'
                    ? 300
                    : variant === 'medium'
                    ? 300
                    : 20
                )}
                <div style={{ margin: '20px 0' }}>
                  {!!chips.length && (
                    <ChipsList
                      test={variant === 'small' ? true : false}
                      chips={chips}
                    />
                  )}
                </div>
              </SubtitleText>
          </MediaCardBody>
        </ContentContainer>
      </Row>
    </MediaCardWrap>
  );
}

export default MediaCard;
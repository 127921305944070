import React from "react";
import { AccordionSectionWrap } from "./elements";
import AccordionCard2 from "components/Accordion/accordion2";
import AccordionCard from "components/Accordion/accordion";


function AccordionSection(props) {

  return (
    <AccordionSectionWrap className="acc-cont">
      <AccordionCard
        openOtherAccordion={props.openOtherAccordion.filter(
          (a) => a.id === "updatesPrivacyPolicy"
        )}
        handleOpenAccordion={props.handleOpenAccordion}
        handleToggleOtherAccordion={props.handleToggleOtherAccordion}
        cardId="updatesPrivacyPolicy"
        title="Updates To This Privacy Policy"
        body={[
          {
            paragraphs: [
              "From time to time we may change our privacy policies. We will notify you of any material changes to our Policy as required by law. Any changes to this Privacy Policy will become effective when we make the revised Privacy Policy available on or through a Property. Your use of such Property or your submission of any information in connection with the Properties following such changes means that you accept the revised Privacy Policy.",
            ],
          },
        ]}
        index={1}
      />

      <AccordionCard2
        openInfoAccordion={props.openInfoAccordion}
        handleToggleInfoAccordion={props.handleToggleInfoAccordion}
        title="Information We Collect"
        body={[
          "We and third-party service providers may collect information from you when you interact with the Properties including when you, for example, post on a public space on our Properties, make a request, register for newsletters or other communications through the Properties, participate in surveys or in a blog, submit a job application, as part of employee onboarding or when you contact us. We may also collect information from you if you attend our trade shows or events, or any trade shows or events which we participate in.",
          "Where permitted by law, we and third-party service providers may collect and share information including:",
        ]}
        lowerBody={[
          "<b>De-Identified Data:</b> In circumstances where we use de-identified data, we maintain and use that data only in deidentified form and make no attempts to reidentify that data.",
          "<b>Information about Sub-processors:</b> Information about our affiliated third-party service providers and sub-processors is available <a href='/service-providers'>here</a>. We update the information included when new vendors and sub-processors who process personal information are engaged.",
          "We collect information about users over time when you use the Properties. We may use tracking tools like browser cookies and web beacons. We may do this on our Properties and in emails that we send to you. We may have third parties collect information this way on our behalf, or we may combine information we get from a third party with information we already have. Our business partners, vendors, and clients may also give us information about you.",
        ]}
        index={2}
      />
      <AccordionCard
        openOtherAccordion={props.openOtherAccordion.filter(
          (a) => a.id === "useData"
        )}
        handleOpenAccordion={props.handleOpenAccordion}
        handleToggleOtherAccordion={props.handleToggleOtherAccordion}
        cardId="useData"
        title="How We Use Data"
        body={[
          {
            paragraphs: [
              "Where and when permitted by law, we and third-party service providers may use information:",
            ],
            list: [
              "To provide our services, including any obligations arising from any contracts entered into between you and us and to provide you with the information and services that you request from us.",
              "To process and complete transactions, and send you related information, including purchase confirmations and invoices.",
              "To respond to your inquiries and fulfill your requests, provide customer service and support, and send you technical notices, updates, security alerts, and administrative messages.",
              "To administer sweepstakes, contests and other promotions.",
              "To send you administrative information, including information regarding the Properties.",
              "To send to you, through us or third-party service providers, marketing and other communications that we believe may be of interest to you. You can opt-out of receiving marketing communications from us by contacting us through the Contact Us options provided below or following the unsubscribe instructions included in our marketing communications. In order to provide certain services to you, you cannot opt out of receiving transaction or service messages from us related to those services.",
              "For our business purposes including, without limitation, administering our Properties, data analysis, research, product development, integrated marketing, advertising, medical communications, enhancing the Properties, improving our services, processing applications and determining the effectiveness of promotional campaigns.",
              "As we believe necessary or appropriate: (1) under applicable law; (2) to comply with legal processes; (3) to respond to requests from public or government authorities; (4) to protect our rights, privacy, safety or property, or that of our affiliates, you or others; (5) to allow us to pursue available remedies or limit the damages that we may sustain; or (6) to fulfill contractual obligations with our clients or business partners.",
              "To keep our site and services safe and secure, including to investigate and prevent fraudulent transactions, unauthorized access to the site and services, and other illegal activities.",
              "In providing the Research Services, we analyze the public postings and comments of individuals whose data we process and extract observations and learnings about trends, sentiment, and changes in health practice which are generally used by our clients to assist in their marketing and medical communications, clinical trial recruitment, risk and reputation management and investor relations.",
              "For other reasons we may describe to you.",
            ],
          },
        ]}
        index={3}
      />
      <AccordionCard
        openOtherAccordion={props.openOtherAccordion.filter(
          (a) => a.id === "researchServices"
        )}
        handleOpenAccordion={props.handleOpenAccordion}
        handleToggleOtherAccordion={props.handleToggleOtherAccordion}
        cardId="researchServices"
        title="Research Services"
        body={[
          {
            paragraphs: [
              "As part of our Research Services and where permitted by applicable law, we collect and retain manifestly made public information relating to doctors, other medical professionals, experts and influencers, patients, advocacy organizations and reporters from various sources. This information typically comprises:",
            ],
            list: [
              "In relation to medical professionals, their name, contact detail, hospitals, universities or other institutions they work for or are affiliated with, their area of specialization, social media information including handles or other identifiers, relevant posts or quotes, or any additional relevant information and, where publicly available, their prescribing record and other elements of their professional practice.",
              "In relation to any individual,  the information they decide to make lawfully available to the public online or from widely distributed media, and typically comprising their name, social media handle, self-declared medical condition and/or treatment (where a patient), relevant public posts and views on matters relevant to health, medical treatment, procedures and responses and reactions to them and their digital footprint (the range, subject matter focus and other details of their influence and visibility online). In some circumstances and when permitted by law, we may also link publicly available information with other publicly available or licensed data sets.",
              "Real Chemistry does not collect or use the data of minors without the affirmative authorization of a parent or guardian or as otherwise permitted by law.",
            ],
          },
          {
            paragraphs: [
              "We obtain third-party content through agreements with certain data providers, who either have a direct relationship with individuals or who search and index publicly available information (including data) from the Internet. We do not attempt to gain unauthorized access to any information and do not circumvent any passwords or other technology protections. We only gather information that is made manifestly available to the public by the site or by the individual, and only when allowed by applicable law.",
            ],
          },
        ]}
        index={4}
      />
      <AccordionCard
        openOtherAccordion={props.openOtherAccordion.filter(
          (a) => a.id === "informationDisclosed"
        )}
        handleOpenAccordion={props.handleOpenAccordion}
        handleToggleOtherAccordion={props.handleToggleOtherAccordion}
        cardId="informationDisclosed"
        title="How your Information May Be Disclosed"
        body={[
          {
            paragraphs: ["When and where permitted by applicable law, information may be disclosed:"],
            list: [
              "To third-party service providers who provide services such as data analysis, order fulfillment, IT services, customer service, email delivery, online employment applications and other similar services to enable them to provide such services. For more information on our third-party service providers and vendors, please click <a href='/service-providers'>here</a>.",
              "To our clients and business partners as part of our services and deliverables.",
              "With advertising partners whose technologies interact with our Properties and who support our operation of the Properties and who also collect information about you and use it for their own purposes and those of their other clients (including to support interest-based cross-context advertising). Please review the <a href='#cookiesTrackingTech' class='internal-page-link'>Cookies and Tracking Technology</a> section of this Policy below, to understand more about these relationships and how you can opt-out of this data sharing.",
              "To a third party in the event of any reorganization, merger, sale, joint venture, assignment, transfer or other disposition of all or any portion of our business, assets or stock (including in connection with any bankruptcy or similar proceeding).",
              "As we believe necessary or appropriate: (1) under applicable law; (2) to comply with legal processes; (3) to respond to lawful requests from public or government authorities, including to meet national security, public interest or law enforcement requirements; (4) to protect our rights, privacy, safety or property, or that of our affiliates, you or others; (5) to allow us to pursue available remedies or limit the damages that we may sustain; (6) to investigate potential fraud, including fraud we think has happened during a sweepstakes or promotion; or (7) to share your information if you are the winner of a sweepstakes or other contest with anyone who requests a winner’s list.",
            ],
          },
        ]}
        index={5}
      />
      <AccordionCard
        openOtherAccordion={props.openOtherAccordion.filter(
          (a) => a.id === "retentionPeriod"
        )}
        handleOpenAccordion={props.handleOpenAccordion}
        handleToggleOtherAccordion={props.handleToggleOtherAccordion}
        cardId="retentionPeriod"
        title="Retention period"
        body={[
          {
            paragraphs: [
              "We will retain information for the period necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law. In relation to our Research Services, we may retain data made manifestly public for as long as it is made publicly available where allowed by applicable law.",
            ],
          },
        ]}
        index={6}
      />
      <AccordionCard
        openOtherAccordion={props.openOtherAccordion.filter(
          (a) => a.id === "security"
        )}
        handleOpenAccordion={props.handleOpenAccordion}
        handleToggleOtherAccordion={props.handleToggleOtherAccordion}
        cardId="security"
        title="Security"
        body={[
          {
            paragraphs: [
              "We place great importance on the security of the personal data we process. We have security measures in place to protect against the loss, misuse, unauthorized access, disclosure, alteration, or destruction of personal data under our control. For example, our security and privacy policies are periodically reviewed and enhanced as necessary, and only authorized personnel have access to personal data. While we cannot ensure or guarantee that loss, misuse, unauthorized access, disclosure, alteration, or destruction of information will never occur, we use reasonable efforts to prevent it.",
              "<b>We use standard security measures.</b> The Internet is not 100% secure. We cannot promise that your use of our sites will be completely safe. We encourage you to use caution when using the Internet. This includes not sharing your passwords.",
              "<b>External Links:</b> The Properties may contain links to third-party websites. These links are provided solely as a convenience to you and not as an endorsement by Real Chemistry of the contents on such third-party websites. Real Chemistry is not responsible for the privacy practices or content of linked third-party sites and does not make any warranty or representation regarding the content or accuracy of materials on such sites. If you decide to access linked third-party websites, you do so at your own risk. Please review the privacy policies of these websites before submitting any personal data to these sites.",
            ],
          },
        ]}
        index={6}
      />
      <AccordionCard
        openOtherAccordion={props.openOtherAccordion.filter(
          (a) => a.id === "choiceAndAccess"
        )}
        handleOpenAccordion={props.handleOpenAccordion}
        handleToggleOtherAccordion={props.handleToggleOtherAccordion}
        cardId="choiceAndAccess"
        title="Choice And Access"
        body={[
          {
            paragraphs: [
              "<b>You have choices about how we use your information.</b> You may choose (opt out) whether your personal information is (i) disclosed to a third party (unless the third party is contracted by and acting as an agent on our behalf and under our instructions) or (ii) to be used for a purpose that is materially different from the purpose(s) for which it was originally collected or subsequently authorized by you. You may opt out in the following ways:",
              "<ul>",
              `<li style="margin-left: 40px">Cookies and tracking tools – please click this link: <button id='ot-sdk-btn' class='ot-sdk-show-settings cookie-btn'>Cookie Settings</button>. To learn more about how we use cookies and other tracking tools, please see the <a href='#cookiesTrackingTech'> Cookies and Tracking Technologies</a> section below.</li>`,
              `<li style="margin-left: 40px">Other methods: You may also use the rights requests forms linked in the <a href='#californiaConsumers'>"RESIDENTS OF CALIFORNIA, TEXAS AND OTHER U.S. STATES”</a> section or the <a href='#ukEuUsers'>“UK / EU / CH AND THE GENERAL DATA PROTECTION REGULATION (GDPR)”</a> as applicable to you. You may also opt-out from receiving marketing related emails from us and our affiliates by using the unsubscribe mechanism provided in the email.</li>`,
              "</ul>",
              "We keep information as long as it is necessary or relevant for the practices described in this Policy. We also keep information as otherwise required by law.",
            ],
          },
        ]}
        index={7}
      />
      <AccordionCard
        openOtherAccordion={props.openOtherAccordion.filter(
          (a) => a.id === "cookiesTrackingTech"
        )}
        handleOpenAccordion={props.handleOpenAccordion}
        handleToggleOtherAccordion={props.handleToggleOtherAccordion}
        cardId="cookiesTrackingTech"
        title="Cookies and Tracking Technology"
        body={[
          {
            paragraphs: [
              "Certain types of cookies collect information for analytics, to personalize your experience, to improve our website, and for the purposes of cross-context behavioral advertising. To learn more, visit <a href='https://allaboutcookies.org/' target='_blank'>https://allaboutcookies.org/</a>. You can control cookies on our website by clicking on the “Do Not Sell My Personal Information” link at the bottom of this page and disabling them. Our website does respond to the Do-Not-Track (DNT) signal and the Global Privacy Control (GPC) signal in relation to cookies at the browser level. You can find out more about using the GPC at <a href='https://globalprivacycontrol.org/' target='_blank'>https://globalprivacycontrol.org/</a>.",
              "To access cookie settings directly, please click this link: <button id='ot-sdk-btn' class='ot-sdk-show-settings cookie-btn'>Cookie Settings</button>",
              "Real Chemistry does not store or have access to the personal information collected by cookies and pixel tags, and the online advertising industry provides websites from which you may opt out of interest-based advertising from other companies that participate in industry self-regulatory programs. The US-based opt out pages are <a href='https://www.aboutads.info/choices' target='_blank'>www.aboutads.info/choices</a> and <a href='https://www.networkadvertising.org/choices' target='_blank'>www.networkadvertising.org/choices</a>. The European-based page is <a href='https://www.youronlinechoices.com' target='_blank'>www.youronlinechoices.com</a>."
            ],
          },
        ]}
        index={8}
      />
      <AccordionCard
        openOtherAccordion={props.openOtherAccordion.filter(
          (a) => a.id === "californiaConsumers"
        )}
        handleOpenAccordion={props.handleOpenAccordion}
        handleToggleOtherAccordion={props.handleToggleOtherAccordion}
        cardId="californiaConsumers"
        title="Residents of California, Texas and other US States"
        body={[
          {
            paragraphs: [
              "Please note that as a business <b>Real Chemistry is not a data broker, and does not sell or share personal information for monetary or other valuable consideration </b>as those terms are defined by applicable state privacy laws (and has not done so in the preceding 12 months), other than information collected when you visit our Properties through cookies, pixel tags and other tracking technologies.",
              'Certain types of cookies collect information for analytics, to personalize your experience, and for the purposes of cross-context behavioral advertising. This collection may constitute a "sale", and for these types of cookies, you may exercise your right to opt-out by clicking on the “Do Not Sell My Personal Information” link at the bottom of this page to disable them. Please review the Cookies and Tracking Technology section above for more information.',
              "<b>For residents of most U.S. States:</b> Other than California and Texas, Real Chemistry does not currently meet the thresholds of applicability set by US-state privacy laws.",
              "<b>For residents of California and Texas:</b> If you reside in California or Texas, you may have several statutory privacy rights under applicable state privacy law, which may include:",
            ],
            list: [
              "<b>Right to Know:</b> The right to request that we disclose the categories and specific pieces of personal information about you that we have collected, used and sold within the last 12 months. The categories of personal information we collect is listed on this page in the <a href='#information-we-collect' class='internal-page-link'>Information We Collect</a> section. In addition to complying with COPPA, Real Chemistry does not process or sell the information of minors under 18 without affirmative authorization of a parent or legal guardian.",
              "<b>Right to Delete:</b> The right to request the deletion of your personal information.",
              "<b>Right to Correct Inaccurate Information:</b> The right to request the correction of inaccurate personal information.",
              "<b>Right to Opt Out:</b> The right to opt out of the sale or sharing of your personal information. Please note that as a business Real Chemistry <b>does not sell or share personal information for monetary or other valuable consideration</b> as those terms are defined by applicable state privacy laws, and has not done so in the preceding 12 months.",
              "<b>Right to opt-out of automated decision making:</b> The right to opt-out with respect to the use of automated decision-making technology and profiling.",
              "<b>Right to Limit the Use and Disclosure of Sensitive Personal Information:</b> The right to limit the use of sensitive personal information to that which is necessary for the performance of specific business purposes.",
              "<b>Right to Non-Discrimination / Non-Retaliation:</b> The right not to receive discriminatory treatment for the exercise of these rights. Real Chemistry does not discriminate or change service levels in any way for anyone exercising their privacy rights.",
              "<b>Authorized Agent:</b> The right to designate an authorized agent to make privacy requests under applicable state privacy laws.  When using an authorized agent, we reserve the right to take all necessary steps permitted by applicable law to confirm your identity, including requesting verification directly from you prior to taking any actions related to your request.",
            ],
          },
          {
            paragraphs: [
              "Under applicable state privacy laws, we only process personal information of:",
            ],
          },
          {
            list: [
              "Employees, job applicants, contractors, clients and business partners.",
              "Individuals who have interacted directly with us (such as in the case of market research).",
              "Healthcare professionals in very limited circumstances."
            ],
          },
          {
            paragraphs: [
              "Please note that <b>if you do not belong to one of these categories, Real Chemistry as a business / controller has not and does not collect, store, sell, share or process your personal data</b> (as those terms are defined by applicable law).",
              "California and Texas residents who belong to one of these categories may submit requests <a href='https://privacyportal.onetrust.com/webform/6cdc31d6-1e41-47c4-9fcc-e505fd9cbc62/8eb0adac-402f-4e08-bc76-9c9897b8122d' target='_blank'>using this form</a>, or via 1-800-485-5071. We reserve the right to take all necessary steps permitted by applicable law to confirm your identity prior to taking any actions related to your request.  Real Chemistry is not obligated to respond to privacy rights requests from individuals located in jurisdictions whose laws do not require us to do so.",
              "<b>“Shine the Light” Law</b> - California residents also have the right to ask us one time each year if we have shared personal information with third parties for their direct marketing purposes. However, Real Chemistry does not disclose (and has not disclosed within the preceding calendar year) any personal information to third parties for their direct marketing purposes, so we do not provide a method for such requests.",
            ],
          },
        ]}
        index={9}
      />
      <AccordionCard
        openOtherAccordion={props.openOtherAccordion.filter(
          (a) => a.id === "ukEuUsers"
        )}
        handleOpenAccordion={props.handleOpenAccordion}
        handleToggleOtherAccordion={props.handleToggleOtherAccordion}
        cardId="ukEuUsers"
        title="UK / EU / CH And The General Data Protection Regulation (GDPR)"
        body={[
          {
            paragraphs: [
              "For the purposes of this Privacy Policy, “GDPR” refers to both the United Kingdom General Data Protection Regulation (“UK GDPR”) and the European Union General Data Protection Regulation (“EU GDPR”). “FADP” refers to the Swiss revised Federal Act on Data Protection.",
              "<b>Please note that in the United Kingdom, Europe, and Switzerland, Real Chemistry only processes personal data as a controller (as those terms are defined by GDPR and FADP) when acting as an employer and in the context of business or contractual relationships</b>.",
              "<b>Lawful Basis:</b> Data protection laws in the United Kingdom (“UK”), Europe (“EU”), and Switzerland (“CH”) contain a number of lawful bases – which are legal justifications which mean organizations like us are allowed to have and use personal information. For our UK / EU /CH individuals whose personal data we process, our lawful bases include:",
            ],
            list: [
              "<b>Legitimate Interest:</b> We sometimes use your data to pursue our legitimate interests in a way which might reasonably be expected as part of running our business and which does not materially impact your rights, freedom or interests. For example, we collect information as part of employment application processes.",
              "<b>Consent:</b> In certain circumstances you’ll be asked for your consent for us to process your data for that specific purpose.",
              "<b>Performance of a Contract:</b> In certain circumstances, we process personal data to comply with a contractual obligation. For example, if you want to use our service, we need your name and contact details so we can communicate with you.",
              "<b>Compliance with a Legal Obligation:</b> We may pass on personal information when we are required by applicable law to do so, such as in cases of fraud or other criminal activity to law enforcement authorities and tax laws which require us to retain records of orders and payments.",
            ],
          },
          {
            paragraphs: [
              'In addition, we use various technologies that may include browser cookies and other tracking tools. See the Cookies and Tracking Technology section above for more information. We give you the ability to personalize your web experience by choosing to allow (or to block) the use of certain cookies. Real Chemistry does not store or have access to personal information collected by cookies and pixel tags, and the online advertising industry provides websites from which you may opt out of interest-based advertising from other companies that participate in industry self-regulatory programs. The European-based page is <a href="https://www.youronlinechoices.com" target="_blank">www.youronlinechoices.com</a>.',
              "To access cookie settings, please click this link: <button id='ot-sdk-btn' class='ot-sdk-show-settings cookie-btn'>Cookie Settings</button>",
              "The categories of data we collect are available on this page in the <a href='#information-we-collect' class='internal-page-link'>Information We Collect</a> section.",
              "<b>UK / EU / CH Rights:</b> The GDPR and FADP provides several rights to individuals located in the United Kingdom, European Union, and Switzerland respectively, including:",
            ],
            list: [
              "<b>Right to Be Informed:</b> You have the right to be informed how we use collect, use and share your personal data. We do this through this privacy policy, and you may request more information through the contact options on this page.",
              "<b>Right to Access:</b> You have the right to access the data about you that we hold.",
              "<b>Right to Rectification:</b> If you believe the data we hold on you is incorrect, you can tell us and we’ll correct it.",
              "<b>Right to Object and Withdraw Consent:</b> You have the right to object to the use of your data for direct marketing – in which case you may withdraw your consent by opt out or unsubscribe in the marketing itself. You may also object to our processing in instances where our lawful basis is legitimate interest.",
              "<b>Right to Restrict Processing:</b> There are certain circumstances in which you can request we suspend the processing of your personal data.",
              "<b>Right to Erasure (Right to be forgotten):</b> You have the right to request that we delete your data, and we will do so provided we do not have a legal obligation to keep it.",
              "<b>Right to Data Portability:</b> In cases where we process your information under the legal basis of consent, you can obtain a copy of your data in a commonly used and machine-readable format",
              "<b>Rights Related to Automated Decision Making:</b> Real Chemistry does not use automated decision making that has legal or similarly significant effects on you.",
            ],
          },
          {
            paragraphs: [
              "To exercise these rights, individuals located in the United Kingdom, Europe or Switzerland who are employees, candidates, contractors, vendors or are otherwise in a business or contractual relationship with Real Chemistry may submit a request <a href='https://privacyportal-cdn.onetrust.com/dsarwebform/6cdc31d6-1e41-47c4-9fcc-e505fd9cbc62/68251318-27c7-4a98-86a5-c2971a9bafaa.html' target='_blank'>using this form</a>.",
            ],
          },
          {
            paragraphs: [
              "<b>For all others:</b> If you are not an employee, former employee, job applicant or contractor of Real Chemistry located in the United Kingdom, Europe, or Switzerland, it is unlikely we process your personal data as a controller (as defined by GDPR). If you have additional reason to believe we may have processed your personal data as a controller, please contact us with additional details <a href='https://privacyportal-cdn.onetrust.com/dsarwebform/6cdc31d6-1e41-47c4-9fcc-e505fd9cbc62/68251318-27c7-4a98-86a5-c2971a9bafaa.html' target='_blank'>using this form</a>.",
              "We reserve the right to take all necessary steps permitted by applicable law to confirm your identity prior to taking any actions related to your request.",
              "<b>Cross Border Transfer:</b> Personal data may be transferred to, processed and stored at a destination in the United States of America or elsewhere outside the United Kingdom, Europe or Switzerland that may not be subject to equivalent data protection laws. It may also be processed by staff operating outside the UK, EU or Switzerland who work for us or for one of our suppliers. Where personal data is transferred in relation to providing our services we will take all steps reasonably necessary to ensure that your data is subject to appropriate safeguards and that it is treated securely and in accordance with this privacy policy.",
              "Where Real Chemistry, or our service providers and consultants, process the personal data of EU, Swiss or UK individuals in countries outside of those areas, and in particular in the United States, our policy is to have an appropriate legal mechanism in place to ensure that transfer of personal data is in compliance with UK, EU and Swiss data protection law as applicable.",
              '<b>Standard Contractual Clauses ("SCCs"):</b> Where required, Real Chemistry uses appropriate data processing agreements and standard contractual clauses for the transfer of data, as approved by the European Commission (Art. 46 GDPR), and including the UK International Data Transfer Addendum to the EU Commission Standard Contractual Clauses and as may be adapted for the FADP, as applicable. In some circumstances, Real Chemistry may also use other transfer mechanisms, including the use of other approved certification mechanisms.',
              "<b>Data Privacy Framework (“DPF”):</b> Real Chemistry, LLC and our U.S. subsidiaries Decibel Strategic Group, LLC (d/b/a 21grams) and Starpower, LLC comply with the EU-U.S. Data Privacy Framework (“EU-U.S. DPF”), the UK Extension to the EU-U.S. DPF, and the Swiss-U.S. Data Privacy Framework (“Swiss-U.S. DPF”) as set forth by the U.S. Department of Commerce.  Real Chemistry has certified to the U.S. Department of Commerce that it adheres to the EU-U.S. Data Privacy Framework Principles (“EU-U.S. DPF Principles”) with regard to the processing of personal data received from the European Union in reliance on the EU-U.S. DPF and from the United Kingdom (and Gibraltar) in reliance on the UK Extension to the EU-U.S. DPF.  Real Chemistry has certified to the U.S. Department of Commerce that it adheres to the Swiss-U.S. Data Privacy Framework Principles (“Swiss-U.S. DPF Principles”) with regard to the processing of personal data received from Switzerland in reliance on the Swiss-U.S. DPF.  Real Chemistry, LLC and our U.S. subsidiaries Decibel Strategic Group, LLC (d/b/a 21grams) and Starpower, LLC adhere to the EU-U.S. DPF Principles including as applicable under the UK Extension to the EU-U.S. DPF, and Swiss-U.S. DPF Principles, and are covered by our DPF certification.",
              "If there is any conflict between the terms in this privacy policy and the EU-U.S. DPF Principles and/or the Swiss-U.S. DPF Principles, the Principles shall govern.  To learn more about the Data Privacy Framework (DPF) program, and to view our certification, please visit <a href='https://www.dataprivacyframework.gov/' target='_blank'>https://www.dataprivacyframework.gov/</a>.",
              "Real Chemistry commits to subject to the DPF Principles all personal data received from the EU, Switzerland and the United Kingdom (and Gibraltar) which is transferred to the United States in reliance on the relevant part(s) of the DPF program.",
              "<b>Recourse, Enforcement and Liability:</b> The Federal Trade Commission has jurisdiction over Real Chemistry’s compliance with the EU-U.S. DPF and the UK Extension to the EU-U.S. DPF, and the Swiss-U.S. DPF.",
              "Real Chemistry has mechanisms in place designed to assist in compliance with the DPF Principles. Real Chemistry conducts an annual self-assessment of personal data and information handling practices to verify that the attestations and assertions we make about our privacy practices are true, and that our privacy practices have been implemented as represented and in accordance with the DPF Principles.",
              "Real Chemistry offers a variety of options for individuals to resolve a dispute. You may file a complaint directly with Real Chemistry concerning our processing of your personal data using the contact information below. We will take steps to remedy issues arising out of any alleged failure to comply with the EU-U.S. DPF Principles and the Swiss-U.S. DPF Principles as applicable.",
              "In compliance with the EU-U.S. DPF and the UK Extension to the EU-U.S. DPF, and the Swiss-U.S. DPF, Real Chemistry commits to resolve DPF Principles-related complaints about our collection and use of your personal information.  EU, UK, and Swiss individuals with inquiries or complaints regarding our handling of personal data received in reliance on the EU-U.S. DPF and the UK Extension to the EU-U.S. DPF, and the Swiss-U.S. DPF should first contact us at:",
              "Real Chemistry<br>199 Water Street<br>12th Floor<br>New York, NY 10038<br>Phone: 1-646-876-6328 or 1-800-485-5071",
              "If your complaint cannot be resolved through our internal processes, in compliance with the EU-U.S. DPF and the UK Extension to the EU-U.S. DPF, and the Swiss-U.S. DPF, Real Chemistry commits to cooperate and comply respectively with the advice of the panel established by the EU data protection authorities (“DPAs”) and the UK Information Commissioner’s Office (ICO) and the Gibraltar Regulatory Authority (GRA), and the Swiss Federal Data Protection and Information Commissioner (FDPIC) with regard to unresolved complaints concerning our handling of personal data received in reliance on the EU-U.S. DPF and the UK Extension to the EU-U.S. DPF, and the Swiss-U.S. DPF.",
              "Under certain circumstances, individuals may also be able to invoke binding arbitration to address complaints about our compliance with the DPF Principles.",
              "Real Chemistry cooperates with relevant government data protection supervisory authorities and complies with their advice. Real Chemistry is required to disclose personal information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.",
              "We may receive personal data under the EU-U.S. DPF, the UK Extension to the EU-U.S. DPF, and the Swiss-U.S. DPF, and subsequently transfer that data to a third party acting as an agent on our behalf. In such cases, we remain liable if our agent processes such personal information in a manner inconsistent with the DPF Principles, unless we are able to prove that we are not responsible for the event giving rise to the damage.",
              "If there is any conflict between the terms in this privacy policy and the DPF Principles, the DPF Principles shall govern.",
              "<b>Supervisory Authorities</b>: For individuals in the United Kingdom, the Information Commissioner’s Office is the supervisory authority in the UK and can provide further information about your rights and our obligations in relation to your personal data, as well as deal with any complaints that you have about our processing of your personal data – see <a href='https://ico.org.uk/' target='_blank'> https://ico.org.uk/</a>. For individuals in the EU, you may contact your local data protection authority – see <a href='https://edpb.europa.eu/about-edpb/board/members_en' target='_blank'> https://edpb.europa.eu/about-edpb/board/members_en</a>. Individuals in Switzerland may contact the Swiss Federal Data Protection and Information Commissioner – see <a href='https://www.edoeb.admin.ch/edoeb/en/home.html' target='_blank'> https://www.edoeb.admin.ch/edoeb/en/home.html</a>.",
              "<b>Representative, Controller and Data Protection Officer Contacts</b>",
              "EU Representative:",
              "Individuals and data protection supervisory authorities in the EU and the UK may contact our data protection representatives according to Article 27 GDPR:",
              "EU: DP-Dock GmbH, Attn: Real Chemistry, Ballindamm 39, 20095 Hamburg, Germany",
              "UK: DP Data Protection Services UK Ltd., Attn: Real Chemistry, 16 Great Queen Street, Covent Garden, London, WC2B 5AH, United Kingdom",
              "In circumstances where Real Chemistry acts as a controller: <br/ > Real Chemistry <br /> 199 Water Street <br /> 12th Floor <br /> New York, NY 10038 <br /> Attention: General Counsel <br /><br/> Data Protection Officer <br /> Dan Linton – Global Data Privacy Officer <br />  <br /> Phone: 1-800-485-5071, +1-646-876-6328",
            ],
          },
        ]}
        index={10}
      />

      <AccordionCard
        openOtherAccordion={props.openOtherAccordion.filter(
          (a) => a.id === "candidateContractors"
        )}
        handleOpenAccordion={props.handleOpenAccordion}
        handleToggleOtherAccordion={props.handleToggleOtherAccordion}
        cardId="candidateContractors"
        title="Employees, Candidates and Contractors"
        body={[
          {
            paragraphs: [
              "Real Chemistry values the trust of our current and past employees, applicants and job candidates, partners, interns, consultants and contractors, and we are committing to protecting the privacy and security of your personal information.",
              "<b>We do not sell or share the personal information of current or past employees, applicants and job candidates, partners, interns, consultants or contractors for monetary or other valuable consideration,</b> and have not done so in the preceding 12 months, as those terms are defined by the California Consumer Privacy Act (CCPA) and the California Privacy Rights Act (CPRA). We also do not sell or share staff personal information for the purposes of cross-context advertising.",
              "Current employees can access Privacy Policies via internal HR systems. For others, please see our UK EU CH CA Employee, Candidate and Contractors Privacy Policy <a href='pdf/UK_EU_CH_CA_Privacy_Policy.pdf' target='_blank'>here</a> for further information."
            ],
          },
        ]}
        index={11}
      />
      <AccordionCard
        openInfoAccordion={props.openInfoAccordion}
        openOtherAccordion={props.openOtherAccordion.filter(
          (a) => a.id === "contactUs"
        )}
        handleOpenAccordion={props.handleOpenAccordion}
        handleToggleOtherAccordion={props.handleToggleOtherAccordion}
        title="Contacting Us"
        cardId="contactUs"
        body={[
          {
            paragraphs: [
              "To exercise privacy rights under applicable law, use the forms linked above, or call us toll free at 1-800-485-5071.",
              "For general inquiries only, you may also write to the following address:",
              "Real Chemistry <br/>199 Water Street <br/>12th Floor<br/> New York, NY 10038<br /> Phone: 1-646-876-6328",
              "Please note that communications are not always secure, so please do not include credit card information or other sensitive information in your messages to us.",
            ],
          },
        ]}
        index={12}
      />
      <AccordionCard
        title="Subsidiaries"
        cardId="subsidiaries"
        openOtherAccordion={props.openOtherAccordion.filter(
          (a) => a.id === "subsidiaries"
        )}
        handleOpenAccordion={props.handleOpenAccordion}
        handleToggleOtherAccordion={props.handleToggleOtherAccordion}
        body={[
          {
            paragraphs: [
              "Real Chemistry, LLC (f/k/a The WeissComm Group Ltd. d/b/a Real Chemistry - collectively “Real Chemistry” or “we” in this Privacy Policy) includes our subsidiaries – Decibel Strategic Group, LLC (d/b/a 21Grams); Twist MKTG GmbH; WeissComm Partners, Ltd.; and starpower, LLC."
            ],
          },
        ]}
        index={13}
      />
    </AccordionSectionWrap>
  );
}

export default AccordionSection;

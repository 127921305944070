import React from 'react';
import { withRouter } from 'react-router-dom';
import { TitleXl, BodySm, Row } from 'components/elements';
import { OtherNewsLettersWrapper, TitleXlContainer, BodySmContainer } from './elements';
import Newsletters from 'components/Newsletters';
import styled from 'styled-components';

export const Col6 = styled.div`
  -webkit-box-flex: 0;
  -ms-flex: 0 0 49%;
  flex: 0 0 49%;
  max-width: 49%;
  width: 100%;
  padding: ${({ padding }) => padding || "0"};
  box-sizing: border-box;
  height: ${({ height }) => height || "auto"};
  margin: ${({ margin }) => margin || "0"};

  @media (max-width: 768px) {
    padding-top: 15px;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

function ExploreOtherNewsletters({ cards, header }) {
  const title = header?.elements?.[0]?.text.content[0]?.content[0]?.value || '';
  const subtitle = header?.elements?.[1]?.text.content[0]?.content[0]?.value || '';
  
  return (
    <OtherNewsLettersWrapper>
      <TitleXlContainer>
        <TitleXl color="#000" fontWeight="900">{title}</TitleXl>
      </TitleXlContainer>
      <BodySmContainer>
        <BodySm color="#000">{subtitle}</BodySm>
      </BodySmContainer>
      <Row>
        {cards?.elements?.map((card) => (
          <Col6 key={card.id}>
            <Newsletters
              link={card.ctaLink}
              title={card.title}
              intro={card.summary}
              image={card.image}
            />
          </Col6>
        ))}
      </Row>
    </OtherNewsLettersWrapper>
  );
}

export default withRouter(ExploreOtherNewsletters);

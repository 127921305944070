import React from 'react';
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { Link } from "react-router-dom";

// Import Style Components
import {
    BodyRg,
    TitleXl,
    TitleLg,
    TitleMd,
    TitleSm,
    TitleXs,
    H6,
    Bold,
    UL,
    OL,
    LI,
    Quote,
    BlogLink,
    Image,
    Video
} from "../elements";

export const richTextOptions = (styleAttributes) => {
    return {
        // 1) Use `renderText` to handle any line-break splits:
        renderText: (text) => {
            // If you literally want to detect "\n" characters:
            return text.split('\n').reduce((acc, segment, index) => {
                // If there’s a split after the first segment, insert <br /> before continuing
                if (index > 0) {
                    acc.push(<br key={index} />);
                }
                acc.push(segment);
                return acc;
            }, []);
        },

        // 2) Handle marks (bold, superscript, etc.)
        renderMark: {
            [MARKS.BOLD]: text => <Bold>{text}</Bold>,
            [MARKS.SUPERSCRIPT]: text => <sup>{text}</sup>,
        },

        // 3) Handle block and inline nodes:
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                const codeContent = node.content[0]?.value || '';

                if (codeContent.includes('<iframe')) {
                    // Safely render iframe content as HTML
                    return <div dangerouslySetInnerHTML={{ __html: codeContent }} />;
                }
                return (
                    <BodyRg
                        color={styleAttributes?.color}
                        fontSize={styleAttributes?.fontSize}
                        textTransform={styleAttributes?.textTransform}
                        margin={styleAttributes?.margin}
                    >
                        {children}
                    </BodyRg>
                );
            },
            [BLOCKS.HEADING_1]: (node, children) => {
                return (
                    <TitleXl
                        color={styleAttributes?.color}
                        fontSize={styleAttributes?.fontSize}
                        textTransform={styleAttributes?.textTransform}
                        margin={styleAttributes?.margin}
                    >
                        {children}
                    </TitleXl>
                );
            },
            [BLOCKS.HEADING_2]: (node, children) => {
                return (
                    <TitleLg
                        color={styleAttributes?.color}
                        fontSize={styleAttributes?.fontSize}
                        textTransform={styleAttributes?.textTransform}
                        margin={styleAttributes?.margin}
                    >
                        {children}
                    </TitleLg>
                );
            },
            [BLOCKS.HEADING_3]: (node, children) => {
                return (
                    <TitleMd
                        color={styleAttributes?.color}
                        fontSize={styleAttributes?.fontSize}
                        textTransform={styleAttributes?.textTransform}
                        margin={styleAttributes?.margin}
                    >
                        {children}
                    </TitleMd>
                );
            },
            [BLOCKS.HEADING_4]: (node, children) => {
                return (
                    <TitleSm
                        color={styleAttributes?.color}
                        fontSize={styleAttributes?.fontSize}
                        textTransform={styleAttributes?.textTransform}
                        margin={styleAttributes?.margin}
                    >
                        {children}
                    </TitleSm>
                );
            },
            [BLOCKS.HEADING_5]: (node, children) => {
                return (
                    <TitleXs
                        color={styleAttributes?.color}
                        fontSize={styleAttributes?.fontSize}
                        textTransform={styleAttributes?.textTransform}
                        margin={styleAttributes?.margin}
                    >
                        {children}
                    </TitleXs>
                );
            },
            [BLOCKS.HEADING_6]: (node, children) => {
                return (
                    <H6
                        color={styleAttributes?.color}
                        fontSize={styleAttributes?.fontSize}
                        textTransform={styleAttributes?.textTransform}
                        margin={styleAttributes?.margin}
                    >
                        {children}
                    </H6>
                );
            },
            [BLOCKS.QUOTE]: (node, children) => {
                return (
                    <Quote
                        color={styleAttributes?.color}
                        fontSize={styleAttributes?.fontSize}
                        textTransform={styleAttributes?.textTransform}
                    >
                        {children}
                    </Quote>
                );
            },
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                let img = node?.data?.target?.file || node?.data?.target?.fields?.file;
                let videoURL = node?.data?.target?.fields?.file;
                let contentType = node?.data?.target?.fields?.file?.contentType;

                if (contentType === "video/mp4") {
                    return (
                        <Video
                            objectFit={'contain'}
                            width={'100%'}
                            isSafari={false}
                            playing={true}
                            loop
                            controls
                            autoPlay={false}
                            playsInline={true}
                        >
                            <source src={videoURL?.url} />
                        </Video>
                    );
                } else {
                    return <Image src={img?.url} alt={'Body'} />;
                }
            },
            [INLINES.EMBEDDED_ENTRY]: (node) => {
                let img = node?.data?.target?.imageAsset;
                if (node?.data?.target?.hyperlink) {
                    if (
                        node?.data?.target?.hyperlink?.charAt(0) === '/' ||
                        node?.data?.target?.hyperlink?.charAt(0) === '#'
                    ) {
                        return (
                            <Link to={node?.data?.target?.hyperlink}>
                                <Image src={img} alt={'Body'} />
                            </Link>
                        );
                    } else {
                        return (
                            <a href={`${node?.data?.target?.hyperlink}`} target="_blank" rel="noopener noreferrer">
                                <Image src={img} alt={'Body'} />
                            </a>
                        );
                    }
                } else {
                    return <Image src={img} alt={'Body'} />;
                }
            },
            [INLINES.ENTRY_HYPERLINK]: (node, children) => {
                let url = '';
                if (node.data.target.ctype === 'page') {
                    url = node.data.target.route;
                } else if (node.data.target.ctype === 'articles') {
                    url = `/article${node.data.target.slug}`;
                }
                return url !== '' ? <Link to={url}>{children}</Link> : null;
            },
            [INLINES.ASSET_HYPERLINK]: (node, children) => {
                return (
                    <a href={`${node.data.target.file.url}`} target="_blank" rel="noopener noreferrer">
                        {children}
                    </a>
                );
            },

            // Example of re-defining UL and OL with some dynamic style attributes
            [BLOCKS.UL_LIST]: (node, children) => (
                <UL
                    padding={`0 ${styleAttributes?.paddingRight || 'initial'} ${
                        styleAttributes?.paddingBottom || 'initial'
                    } ${styleAttributes?.paddingLeft || 'initial'}`}
                    margin={styleAttributes?.margin}
                >
                    {children}
                </UL>
            ),
            [BLOCKS.OL_LIST]: (node, children) => (
                <OL
                    padding={`0 ${styleAttributes?.paddingRight || 'initial'} ${
                        styleAttributes?.paddingBottom || 'initial'
                    } ${styleAttributes?.paddingLeft || 'initial'}`}
                    margin={styleAttributes?.margin}
                >
                    {children}
                </OL>
            ),
            [BLOCKS.LIST_ITEM]: (node, children) => (
                <LI
                    padding={`0 ${styleAttributes?.paddingRight || 'initial'} ${
                        styleAttributes?.paddingBottom || 'initial'
                    } ${styleAttributes?.paddingLeft || 'initial'}`}
                    margin={styleAttributes?.margin}
                >
                    {children}
                </LI>
            ),

            [INLINES.HYPERLINK]: (node, children) => {
                return (
                    <BlogLink
                        target={node.data?.uri?.charAt(0) === '#' ? '_self' : '_blank'}
                        href={node.data?.uri}
                    >
                        {children}
                    </BlogLink>
                );
            },
        },
    };
};

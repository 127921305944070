import React, { useState, useEffect, useRef } from 'react'
import { r3cContent } from 'constants/index.js'
import RelatedContent from './RelatedContent'

import {
    BackArrowLink,
    BodyRg, Col3,
    PageWrapper, Row,
    TitleContainer,
    TitleLg, TitleXs,
    WideScreenContainer
} from 'components/elements'

import {
    BlogDetailPageWrap,
    BlogContainer,
    BlogInnerContainer,
    BlogSubscribe,
    SubscibeClose,
    BackButtonContainer,
    BackLink,
    BackButtonWrap,
} from './elements'

import ArticleInfo from './ArticleInfo'
import BlogHero from './BlogHero'
import BlogDetail from './BlogDetail'
import LoadingHoc from '../../hoc/LoadingHOC'
import FooterAnimation from 'components/FooterAnimation'
import PardotForm from 'components/PardotForm';

import SubscribeCard from 'components/SubscribeCard'
import { Helmet } from "react-helmet";
import { getImageDimensions } from "../../generalHelpers";
// import {useHistory} from "react-router-dom";
import useRememberScrollPosition from "../../hooks/useRememberScrollPosition";
import { LinkedinShareButton, TwitterShareButton } from "react-share";
import { AuthorSocial } from "./ArticleInfo/elements";
import CloseIconSvg from "../../assets/icons/CloseIcon";
import BackArrowSvg from "../../assets/icons/BackArrowSvg";
import MediaCard from "../../components/MediaCard";
import BlogIcon from "../../assets/icons/BlogIcon";
import { HeroTitleContainer } from 'components/InnerHero/elements'
import Subscribe from './Subscribe'

function BlogDetails(props) {
    // const history = useHistory();
    const { setLoading } = props
    const marqueRef = useRef(null)

    const [subscribe, setSubscribe] = useState(false)
    const [count, setCount] = useState(0)
    const [marqueHeight, setMarqueHeigth] = useState(0)
    const [article, setArticle] = React.useState({})
    const [height, setHeight] = useState(0)
    const [mobileView, setMobileView] = useState(false)
    const [articleBody, setArticleBody] = useState(null)
    const [articleSections, setArticleSections] = useState(null)
    const [seoData, setSeoData] = React.useState({})
    const [pardotForm, setPardotForm] = useState(null)


    useEffect(() => {
        if (props?.location?.hash) {

        } else {
            window.scrollTo(0, 0)
            setLoading(true)
        }

        let body = document.body
        let html = document.documentElement

        let calHeight = Math.max
            (
                body.scrollHeight,
                body.offsetHeight,
                html.clientHeight,
                html.scrollHeight,
                html.offsetHeight
            )

        setHeight((calHeight * 20) / 100)
        setMobileView((window.innerWidth < 993))

        // this code is for "Preview" only -- the "real" page is set up below
        if (props?.articleData) {
            let aData = props.articleData

            setArticle(aData)
            setArticleBody(aData['body'])
            setArticleSections(aData['sections'])
            setLoading(false)
            setCount(1)
            setLoading(false)
        } else {
            let a = props ? props.location?.pathname.split('/') : []


            if (a && a.length === 3) {
                let slug = '/' + a[2]
                window.dataLayer = window.dataLayer || [];
                fetch(r3cContent + '/article?slug=' + slug).then((response) => response.json()).then((aData) => {
                    let seo = aData?.seo;

                    let image = aData?.seo?.image?.split('.')

                    aData?.seo?.image ? getImageDimensions(aData?.seo?.image, function (width, height) {
                        setSeoData({
                            ...seo,
                            mimeType: image[image.length - 1],
                            seoImageHeight: height,
                            seoImageWidth: width
                        })

                    }) : setSeoData(seo)
                    setArticle(aData)
                    setArticleBody(aData['body'])
                    setArticleSections(aData['sections'])
                    setPardotForm(aData?.pardotForm)
                    setLoading(false)
                })
            }
        }
        // eslint-disable-next-line
    }, [props?.location?.pathname, props?.articleData])

    /**
     * DATA LAYER PUSH
     */
    useEffect(() => {
        if (Object.keys(article).length !== 0) {
            let authors = article?.authors?.map((author) => {
                return author.name;
            })
            window.dataLayer.push({
                'event': 'pagedetail',
                'contentType': 'Insights',
                'contentTitle': article?.title,
                'contentAuthors': `${authors}`,
                'contentTags': article?.tags
            }
            );
        }
    }, [article])

    useEffect(() => {

        window.addEventListener('scroll', handleScroll, true)
        return () => {
            window.removeEventListener('scroll', handleScroll, true)
        }
    })

    useEffect(() => {
        document.onkeydown = function (e) {
            if (e.keyCode === 27 && window.scrollY > height) {
                console.log("useEffect keyCode 27")
                onSubscribeCross()
            }
        }
    })

    useEffect(() => {
        setMarqueHeigth(marqueRef.current.offsetTop + window.innerHeight)
    }, [marqueRef])

    // useEffect(() => {
    //     window.addEventListener('click', function (e) {
    //         const clickedInside = e?.path?.filter((cls) => {
    //             return (cls?.classList && cls?.classList?.value) ? cls?.classList?.value.indexOf('subscribe-card-wrap') > -1 : false
    //         })

    //         if (window.scrollY > height && clickedInside?.length === 0) {
    //             console.log("<<<<<<<<< scroll Y<<<<<<<<")
    //             onSubscribeCross()
    //         }
    //     }, true)
    // }, [height])


    /**
     * Check whether an element is in viewport or not
     * @param element
     * @returns {boolean}
     */

    function isInViewport(element) {
        const rect = element.getBoundingClientRect()
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        )
    }

    const handleScroll = (e) => {
        let x = sessionStorage.getItem('check')
        if (window.scrollY > height && window.scrollY < marqueHeight) {
            if (x !== 'showFalse' && subscribe === false && count === 0)
                setSubscribe(true)
        }
        const target = document.querySelector("#slider")

        let inViewport = isInViewport(target)
        if (inViewport)
            setSubscribe(false)
    }

    const onSubscribeCross = () => {
        console.log(">>>>>>>>>>>>>> blog post >>>>>>>>>")
        setSubscribe(false)
        setCount(1)
        sessionStorage.setItem('check', 'showFalse')
    }
    // useRememberScrollPosition('article');


    return (
        <PageWrapper className="blog-detail pageWrapper">
            <WideScreenContainer
                borderLeft="1px solid #707070"
                borderRight="1px solid #707070"
                borderTop="1px solid #707070"
            >
                <Helmet>
                    <title>{seoData.title}</title>
                    <link rel="canonical" href={window.location.pathname} />
                    <meta name="description" content={seoData.description} />
                    <meta name="og:description" content={seoData.description} />
                    <meta name="og:title" content={seoData.title} />
                    <meta name="og:url" content={window.location.protocol + "//" + window.location.host + window.location.pathname} />
                    <meta name="og:type" content="website" />
                    <meta name="og:site_name" content="Real Chemistry" />
                    <meta name="og:image" content={window.location.protocol + seoData.image} />
                    <meta name="og:image:type" content={"image/" + seoData?.mimeType} />
                    <meta name="og:image:width" content={seoData?.seoImageWidth} />
                    <meta name="og:image:height" content={seoData?.seoImageHeight} />
                </Helmet>

                <BlogDetailPageWrap>
                    <BackButtonContainer>
                    <BackButtonWrap>
                            <svg
                                width="11"
                                height="10"
                                viewBox="0 0 11 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    id="arrow_forward"
                                    d="M1.50955 5.35714H11V4.64286H1.50955L6.06061 0.505536L5.5 0L0 5L5.5 10L6.06061 9.49446L1.50955 5.35714Z"
                                    fill="black"
                                />
                            </svg>
                            <BackLink href="/news-events">Back</BackLink>
                        </BackButtonWrap>
                    </BackButtonContainer>
                    {/* 
                    <ArticleInfo
                        articleType={article.type}
                        articleLabel={article.label}
                        articleTitle={article.title}
                        authors={article.authors}
                        date={article.date}
                        social={[
                            {link: "https://www.linkedin.com", name: "Linkedin"},
                            // { link: 'https://www.facebook.com/', name: "facebook" }
                        ]}
                    /> */}

                    <BlogContainer>
                        <BlogHero article={article} />

                        <BlogInnerContainer>
                                <BlogDetail
                                authors={article.authors}
                                body={articleBody}
                                sections={articleSections}
                            />
                            {pardotForm && (
                                <PardotForm height pardotLink={pardotForm} />

                            )}
                        </BlogInnerContainer>

                        {/* Popup Subscribe */}
                        {/* <BlogSubscribe
                            className={
                                !mobileView ? (subscribe ? "showSubcribe" : "") : "showSubscribe"
                            }
                        >
                            <SubscibeClose onClick={() => onSubscribeCross()}>
                                <CloseIconSvg />
                            </SubscibeClose>
                            <SubscribeCard from={'blog-details'} />
                        </BlogSubscribe> */}
                    </BlogContainer>

                    {/* Share Blog */}
                    {/* <AuthorSocial>
                        <LinkedinShareButton url={window.location.href}>
                            <BodyRg textTransform="uppercase">SHARE ON LINKEDIN</BodyRg>
                        </LinkedinShareButton>
                        <TwitterShareButton url={window.location.href}>
                            <BodyRg textTransform="uppercase">SHARE ON X</BodyRg>
                        </TwitterShareButton>
                    </AuthorSocial> */}

                    {/* Related Content Title */}
                    {/* <TitleContainer borderBottom={"1px solid #707070"}>
                        <TitleLg>Related content</TitleLg>
                    </TitleContainer> */}
                    {/*{article?.relatedContent &&*/}
                    {/*<BlogCardSlider borderLeft="none" borderRight="none" slides={article?.relatedContent}/>}*/}

                    {/*<BlogCardsMobile>*/}
                    <RelatedContent relatedContent={article?.relatedContent} />
                    <Subscribe/>

                    {/*</BlogCardsMobile>*/}
                    <div id="slider" ref={marqueRef}>
                        {/* <Marquee link="/news-events" title="View All Media"/> */}
                    </div>
                </BlogDetailPageWrap>
            </WideScreenContainer>
            {/* <FooterAnimation
                svgDesktop="/images/purple-footer.svg"
                svgMobile="/images/purple-footer-mbl.svg"
                borderTop="none"
            /> */}
        </PageWrapper>
    )
}

export default LoadingHoc(BlogDetails)


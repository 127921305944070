import React from 'react'

import {
    WideScreenContainer,
	TitleXl,
	BodySm
} from 'components/elements'

import PurpleAnimHeader from 'components/PurpleAnimHeader';
import FooterAnimation from 'components/FooterAnimation'
import LearnAboutOtherNewsletters from 'components/LearnAboutOtherNewsletters';
import { NewslettersPageWrap } from '../elements';
import {TitleXlContainer, BodySmContainer} from './elements';
import LoadingHoc from 'hoc/LoadingHOC';

function Subscribe(props) {
	const {setLoading} = props; //receive the loading trigger change function from the HOC

	React.useEffect(() => {
		setLoading(false);
		// eslint-disable-next-line
	}, []);

	return (
		<NewslettersPageWrap className="pageWrapper">
			<PurpleAnimHeader
                 sectionLabel={"Newsletters"}
				 sectionTitle={undefined}
				 heroIntro={undefined}
				 headerSplit={"2"}
				 backgroundImage={"/images/newsletters-header-dsktop.svg"}
				 backgroundImgMbl={"/images/newsletters-header-mbl.svg"}
				 backgroundImgTitle={""}
				 header={`Thanks for subscribing`}
				 introCopy={"We’re excited to connect you with the leaders, insights and ideas that are raising the bar on the modern healthcare experience. Explore our other newsletter offerings below, and we’ll see you in your inbox soon."}
				 titleMdPadding={"15% 0 0"}
				 maxWidth={"30%"}
				 marginRight={"15%"}
				 maxHeight={"700px"}
            />

			<WideScreenContainer>
				<TitleXlContainer>
					<TitleXl color="#000" fontWeight="900">You’re on the list for our Healthcare Connection newsletter and exclusive event invitations.</TitleXl>
				</TitleXlContainer>
				<BodySmContainer>
					<BodySm color="#000">We’ll bring you a wide-ranging look at how AI and ideas are transforming healthcare into what it should be, every month. Plus, we’ll occasionally share info about healthcare’s biggest events and opportunities to connect in person with some of our brightest minds.</BodySm>
					<br />
					<BodySm color="#000">Want pricing & access and public policy news in your inbox every week? Check out our <a href="https://www.realchemistry.com/weekly-value-newsletter" target="_blank" rel="noopener noreferrer">Weekly Value Report newsletter</a>.</BodySm>
				</BodySmContainer>

				<LearnAboutOtherNewsletters
				  monthly={false}
				  healthEquity={true}
				  valueReport={true}
                  rdv={true}
				  displayHeader={false}
				/>
			</WideScreenContainer>

			<FooterAnimation
				svgDesktop="/images/purple-footer.svg"
				svgMobile="/images/purple-footer-mbl.svg"
				borderTop="none"
			/>
		</NewslettersPageWrap>
	)
}

export default LoadingHoc(Subscribe);

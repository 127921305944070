import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { smartTruncate } from 'generalHelpers';
import ChipsList from '../Chips'; // Ensure correct path
import { useAppContext } from 'store';

/* ---------------- CUSTOM HOOK FOR MOBILE DETECTION ---------------- */
function useIsMobile(breakpoint = 768) {
  const [isMobile, setIsMobile] = useState(
    typeof window !== 'undefined' ? window.innerWidth < breakpoint : false
  );

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < breakpoint);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [breakpoint]);

  return isMobile;
}

/* ---------------- HELPER for CTA TEXT ---------------- */
function getCtaText(type) {
  switch (type) {
    case 'BlogPost':
    case 'PressRelease':
      return 'Read Now';
    case 'Podcast':
      return 'Listen Now';
    case 'Event':
      return 'Learn More';
    case 'Report':
      return 'Get Report';
    default:
      return 'Read Now';
  }
}

/* ---------------- HELPER for IMAGE URL ---------------- */
function getItemImage(item, dimension = '80x80') {
  if (item?.image?.file?.url) {
    return item.image.file.url;
  }
  if (typeof item.image === 'string') {
    return item.image;
  }
  // Fallback
  return `https://via.placeholder.com/${dimension}`;
}

/* ---------------- FILTER DISPLAY NAMES ---------------- */
const filterDisplayNames = {
  BlogPost: 'Blogs',
  Podcast: 'Podcasts',
  PressRelease: 'Press Releases',
  Event: 'Events',
  Report: 'Reports',
  ArtificialIntelligence: 'Artificial Intelligence',
  Advertising: 'Advertising',
  AnalyticsInsights: 'Analytics + Insights',
  Activation: 'Activation',
  IntegratedCommunications: 'Integrated Communications',
  MedicalCommunications: 'Medical Communications',
};

/* ---------------- MAIN COMPONENT ---------------- */
function MediaListView({ items = [], filterName }) {
  const { selectedMediaFilters, articleSearchTerm } = useAppContext();
  const [viewMode, setViewMode] = useState('list');
  const [visibleCount, setVisibleCount] = useState(8); // initial count

  // Custom hook to detect mobile
  const isMobile = useIsMobile(768);

  const handleToggleView = (newView) => {
    setViewMode(newView);
  };

  const handleLoadMore = () => {
    setVisibleCount((prev) => prev + 8);
  };

  // Compute heading/title based on filters or search term
  const title = useMemo(() => {
    if (articleSearchTerm && articleSearchTerm.trim() !== '') {
      return `Searching "${articleSearchTerm}"`;
    }

    const activeFilter = selectedMediaFilters[0] || filterName;
    if (!activeFilter || activeFilter === 'all') {
      return 'All News';
    }

    if (['BlogPost', 'Podcast', 'PressRelease', 'Event', 'Report'].includes(activeFilter)) {
      return `All ${filterDisplayNames[activeFilter]}`;
    }
    if (['listAll'].includes(activeFilter)) {
      return `All News`;
    }
    return ``;
  }, [articleSearchTerm, selectedMediaFilters]);

  const memoizedItems = useMemo(() => items, [items]);
  const displayedItems = useMemo(
    () => memoizedItems.slice(0, visibleCount),
    [memoizedItems, visibleCount]
  );

  // Decide truncate length for LIST view based on mobile or desktop
  const truncateListLength = isMobile ? 80 : 100;

  return (
    <Container>
      <Header>
        <Title>{title}</Title>
        <ViewToggleContainer>
          <ToggleButton
            active={viewMode === 'list'}
            onClick={() => handleToggleView('list')}
          >
            <div>List</div>
          </ToggleButton>
          <ToggleButton
            active={viewMode === 'grid'}
            onClick={() => handleToggleView('grid')}
          >
            <div>Grid</div>
          </ToggleButton>
        </ViewToggleContainer>
      </Header>

      {displayedItems.length === 0 ? (
        /* Display a large loading spinner */
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : (
        <>
          {/* =========================================
              LIST VIEW
          ========================================== */}
          {viewMode === 'list' ? (
            <ListContainer>
              {/* 3-column header: Title (40%), Topic(s) (50%), Action (10%) */}
              <ListHeader>
                <HeaderCell width="40%">Title</HeaderCell>
                <HeaderCell width="50%">Topic(s)</HeaderCell>
                <HeaderCell style={{ justifyContent: 'flex-end' }} width="10%">
                  Action
                </HeaderCell>
              </ListHeader>

              {displayedItems.map((item, index) => {
                const ctaText = getCtaText(item.type);
                const imageSrc = getItemImage(item, '80x80');
                const itemUrl = `/article${item.slug}` || '#';

                return (
                  <ListRow key={index}>
                    {/* Column ~40%: Hero image + Title */}
                    <ListItemColumn width="40%">
                      <a href={itemUrl} style={{ textDecoration: 'none' }}>
                        <HeroImage src={imageSrc} alt={item.title} />
                      </a>
                      <ItemText weight="700" size="12px">
                        <a
                          href={itemUrl}
                          style={{ color: '#333', textDecoration: 'none' }}
                        >
                          {smartTruncate(item.title, truncateListLength)}
                        </a>
                      </ItemText>
                    </ListItemColumn>

                    {/* 
                      Column ~50%: Tags - 
                      RENDER ONLY IF NOT MOBILE 
                    */}
                    {!isMobile && (
                      <ListItemColumn width="50%" style={{ flexWrap: 'wrap' }}>
                        <ChipsList autoResize={true} chips={item.chips} />
                      </ListItemColumn>
                    )}

                    {/* Column ~10%: CTA (blue style) */}
                    <ListItemColumn
                      width="10%"
                      style={{ justifyContent: 'flex-end' }}
                    >
                      <CTAButtonList
                        href={itemUrl}
                      >
                        {ctaText}
                      </CTAButtonList>
                    </ListItemColumn>
                  </ListRow>
                );
              })}
            </ListContainer>
          ) : (
            /* =========================================
                GRID VIEW 
            ========================================== */
            <GridContainer>
              {displayedItems.map((item, index) => {
                const ctaText = getCtaText(item.type);
                const imageSrc = getItemImage(item, '250x200'); // or any dimension you like
                const itemUrl = `/article${item.slug}` || '#';

                return (
                  <GridItem key={index}>
                    <a href={itemUrl} style={{ textDecoration: 'none', width: '100%' }}>
                      <GridImage src={imageSrc} alt={item.title} />
                    </a>
                    <ItemText weight="700" size="16px">
                      <a
                        href={itemUrl}
                        style={{ color: '#333', textDecoration: 'none' }}
                      >
                        {smartTruncate(item.title, 100)}
                      </a>
                    </ItemText>

                    <ChipsList autoResize={false} chips={item.chips} />

                    {/* Black/White CTA at bottom-right */}
                    <CTAWrapper>
                      <CTAButtonGrid
                        href={itemUrl}
                      >
                        {ctaText}
                      </CTAButtonGrid>
                    </CTAWrapper>
                  </GridItem>
                );
              })}
            </GridContainer>
          )}

          {/* "Load More" button */}
          {visibleCount < memoizedItems.length && (
            <LoadMoreButton onClick={handleLoadMore}>
              <div
                style={{
                  color: '#212121',
                  fontSize: '15px',
                  fontFamily: 'Helvetica Now Display, sans-serif',
                  fontWeight: '600',
                  lineHeight: '15px',
                  wordWrap: 'break-word',
                }}
              >
                Load More...
              </div>
            </LoadMoreButton>
          )}
        </>
      )}
    </Container>
  );
}

export default MediaListView;

/* --------------------------------------
   STYLED COMPONENTS
--------------------------------------- */
const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 46px 30px;
  background: white;
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 1300px;
  margin: auto;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Title = styled.div`
  font-size: 24px;
  font-family: 'Helvetica Now Display', sans-serif;
  font-weight: 700;
  line-height: 28px;
  color: #333333;
  word-wrap: break-word;
`;

const ViewToggleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ToggleButton = styled.div`
  cursor: pointer;
  padding: 8px 12px;
  background: ${({ active }) => (active ? 'black' : 'transparent')};
  border: ${({ active }) => (active ? 'none' : '1px solid black')};
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 4px;

  div {
    color: ${({ active }) => (active ? 'white' : 'black')};
    font-size: 12px;
    font-family: 'Helvetica Now Display', sans-serif;
    font-weight: 700;
    line-height: 16px;
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const ListHeader = styled.div`
  display: flex;
  padding: 15px 20px;
  background: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 600;
  font-size: 14px;

  @media (max-width: 768px) {
    padding: 12px 15px;
    font-size: 13px;
  }
`;

const HeaderCell = styled.div`
  width: ${({ width }) => width};
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: ${({ width }) => width === '50%' ? 'none' : 'flex'};
    width: ${({ width }) => width === '40%' ? '85%' : '15%'};
  }
`;

const ListRow = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
  gap: 20px;

  @media (max-width: 768px) {
    padding: 15px;
    gap: 15px;
    align-items: flex-start;
  }
`;

const ListItemColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  width: ${({ width }) => width};

  @media (max-width: 768px) {
    display: ${({ width }) => width === '50%' ? 'none' : 'flex'};
    width: ${({ width }) => width === '40%' ? '75%' : '25%'};
    gap: 10px;
  }
`;

const HeroImage = styled.img`
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
  flex-shrink: 0;

  @media (max-width: 768px) {
    height: 60px;
  }
`;

const ItemText = styled.div`
  font-family: 'Helvetica Now Display', sans-serif;
  font-weight: ${({ weight }) => weight || '400'};
  font-size: ${({ size }) => size || '14px'};
  line-height: 1.4;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 1.3;
    -webkit-line-clamp: 3;
  }
`;

const CTAButtonList = styled.a`
  font-size: 12px;
  font-family: 'Helvetica Now Display', sans-serif;
  font-weight: 700;
  line-height: 16px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 6px 8px;
  background-color: #000;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  height: 32px;

  &:hover {
    background-color: #333;
    border-color: #333;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 32px;
    padding: 6px 12px;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  width: 100%;
`;

const GridItem = styled.div`
  position: relative;
  background: white;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  padding: 16px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
`;

const GridImage = styled.img`
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
  margin-right: 15px;
  height: 180px;
`;

const CTAWrapper = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;
`;

const CTAButtonGrid = styled.a`
  font-size: 12px;
  font-family: 'Helvetica Now Display', sans-serif;
  font-weight: 700;
  line-height: 16px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 6px 8px;
  background-color: #000;

  &:hover {
    background-color: #333;
    border-color: #333;
    text-decoration: none;
  }
`;

const LoadMoreButton = styled.div`
  width: 150px;
  margin: 0 auto;
  padding: 13px 18px 12px 18px;
  border-radius: 3px;
  border: 1px solid #212121;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  margin-top: 16px;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const SpinnerContainer = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
`;

const Spinner = styled.div`
  border: 8px solid #f3f3f3;
  border-top: 8px solid blue;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
import React from "react";
import styled from 'styled-components';
import RichText from 'components/RichText';

const QuoteContainer = styled.div`
  width: 100%;
  margin: 4rem 0;
  padding: 2rem 0;
  border-top: 4px solid ${props => props.borderColor};
  border-bottom: 4px solid ${props => props.borderColor};
`;

const QuoteBlock = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 20px;
  text-align: center;
`;

const Blockquote = styled.blockquote`
  margin: 0;
  padding: 0;
  font-size: 2.5rem;
  line-height: 1.2;
  font-weight: bold;
  position: relative;
  color: ${props => props.quoteColor};

  &::before {
    content: "“";
    position: absolute;
    left: -1.5rem;
    top: -0.5rem;
    font-size: 4rem;
    color: ${props => props.quoteColor};
  }

  &::after {
    content: "”";
    position: absolute;
    right: -1.5rem;
    bottom: -1.5rem;
    font-size: 4rem;
    color: ${props => props.quoteColor};
  }

  @media (max-width: 768px) {
    font-size: 1.8rem;
    padding: 0 15px;

    &::before,
    &::after {
      font-size: 3rem;
    }

    &::before {
      left: -0.5rem;
    }

    &::after {
      right: -0.5rem;
    }
  }
`;

const Attribution = styled.div`
  font-size: 1.5rem;
  margin-top: 2rem;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const Quotes = ({ text, attribution, borderColor, quoteColor }) => {
  return (
    <QuoteContainer borderColor={borderColor}>
      <QuoteBlock>
        <Blockquote quoteColor={quoteColor}>
          <RichText body={text} options={{ color: 'black' }} />
        </Blockquote>
        {attribution && <Attribution>{attribution}</Attribution>}
      </QuoteBlock>
    </QuoteContainer>
  );
};

Quotes.defaultProps = {
  borderColor: 'rgb(209, 47, 255)',
  quoteColor: 'rgb(209, 47, 255)'
};

export default Quotes;
import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom'
import {
  NavHeader,
  NavContainer,
  NavBrand,
  NavList,
  LogoDesktop,
  NavItem,
  MenuLink,
  MenuBtnBg,
  MenuButton,
  BtnLine,
  DropdownIcon,
  THMNavHeader
} from "./elements";
import {
  r3cContent,
} from "constants/index";
import { useAppContext } from "store/index";
import { useLocation } from "react-router-dom";

// Submenu Components
import CareersMenu from "./components/CareersMenu";
import AlchemyMenu from "./components/AlchemyMenu";
import CareersMobSubMenu from "./components/mobile/CareersMobSubMenu";
import { TitleSm } from "components/elements";
import AboutUsMenu from "./components/AboutUsMenu";
import AboutUsMobSubMenu from "./components/mobile/AboutUsMobSubMenu";

// NEW (desktop & mobile) for News & Events
import NewsEventsMenu from "./components/NewsEventsMenu";
import NewsEventsMobSubMenu from "./components/mobile/NewsEventsMobSubMenu";

function Header(props) {
  let route = useLocation().pathname;
  const { logoAniComplete, blackHeader } = useAppContext();

  const [isHomePage, setIsHomePage] = useState(true);
  const [isErrorPage, setIsErrorPage] = useState(true);

  // Data from your CMS or API
  const [aboutData, setAboutData] = useState({});
  const [workWithUsData, setWorkWithUsData] = useState({});
  const [insightsSubMenuData, setInsightsSubMenuData] = useState({});
  const [ourAlchemySubmenuData, setOurAlchemySubMenuData] = useState({});
  const [careersSubMenuData, setCareersSubMenuData] = useState({});
  const [contactUsMenuData, setContactUsMenuData] = useState({});

  // State to show/hide top-level desktop dropdown (e.g. About)
  const [showAboutDropdown, setShowAboutDropdown] = useState(false);

  // Desktop sub menus
  const [showAboutMenu, setAboutMenu] = useState(false);
  const [showOurAlchemyMenu, setOurAlchemyMenu] = useState(false);
  const [showCareersMenu, setCareersMenu] = useState(false);

  // NEW Desktop sub menu for News & Events
  const [showNewsEventsMenu, setShowNewsEventsMenu] = useState(false);

  // Mobile sub menus
  const [openMobileSubmenu, setOpenMobileSubmenu] = useState(false);
  const [openAboutSubM, setOpenAboutSubM] = useState(false);
  const [openAlchemySubM, setOpenAlchemySubM] = useState(false);
  const [openCareersSubM, setOpenCareersSubM] = useState(false);

  // NEW Mobile sub menu for News & Events
  const [openNewsEventsSubM, setOpenNewsEventsSubM] = useState(false);

  // Shadow & fixed state
  const [showShadow, setShowShadow] = useState(false);
  const [isFixed, setFixed] = useState(false);

  // Fetch data for header (from your r3cContent + '/header')
  useEffect(() => {
    fetch(r3cContent + '/header')
      .then(response => response.json())
      .then(pgData => {
        setAboutData(pgData.navigationItems[0]);
        setOurAlchemySubMenuData(pgData.navigationItems[1]);
        setInsightsSubMenuData(pgData.navigationItems[2]);
        setWorkWithUsData(pgData.navigationItems[3]);
        setCareersSubMenuData(pgData.navigationItems[4]);
        setContactUsMenuData(pgData.navigationItems[5]);
      });
  }, []);

  // Determine if on homepage or error page
  useEffect(() => {
    if (route === "/") {
      window.scrollTo(0, 0);
      setIsHomePage(true);
    } else {
      setIsHomePage(false);
    }
    if (route === "/404") {
      setIsErrorPage(true);
    } else {
      setIsErrorPage(false);
    }
  }, [route]);

  // Desktop open/close methods
  const openAboutMenu = () => setAboutMenu(true);
  const closeAboutMenu = () => setAboutMenu(false);

  const openAlchemyMenu = () => setOurAlchemyMenu(true);
  const closeAlchemyMenu = () => setOurAlchemyMenu(false);

  const openCareersMenu = () => setCareersMenu(true);
  const closeCareersMenu = () => setCareersMenu(false);

  // NEW: Desktop methods for News & Events
  const openNewsEventsMenu = () => setShowNewsEventsMenu(true);
  const closeNewsEventsMenu = () => setShowNewsEventsMenu(false);

  // Mobile open/close methods
  const handleMobileSubmenu = () => {
    setOpenMobileSubmenu(!openMobileSubmenu);
    if (!openMobileSubmenu === true) {
      document.body.classList.add("bodyFixed");
    } else {
      document.body.className = "";
    }
  };

  const handleAboutSubM = () => {
    setOpenAboutSubM(!openAboutSubM);
    closeAboutMenu(); // close the desktop version if open
  };

  const handleAlchemySubM = () => {
    setOpenAlchemySubM(!openAlchemySubM);
    closeAlchemyMenu();
  };

  const handleCareersSubM = () => {
    setOpenCareersSubM(!openCareersSubM);
    closeCareersMenu();
  };

  // NEW: Mobile open/close for News & Events
  const handleNewsEventsSubM = () => {
    setOpenNewsEventsSubM(!openNewsEventsSubM);
    closeNewsEventsMenu();
  };

  // Close all desktop sub menus
  const closeDesktopSubMenu = () => {
    closeAboutMenu();
    closeAlchemyMenu();
    closeCareersMenu();
    closeNewsEventsMenu();
    setOpenMobileSubmenu(false);
  };

  // Close mobile submenu
  const closeMobileSubMenu = () => {
    document.body.className = "";
    setOpenMobileSubmenu(false);
  };

  // Example for ensuring body is not fixed
  const bodyPosition = () => {
    document.body.className = "";
  };

  // If the route includes '/transformative-healthcare-markets', render a special THM header
  const render = () => {
    if (props.location.pathname.includes('/transformative-healthcare-markets')) {
      return (
        <React.Fragment>
          <NavHeader thm={true}>
            <NavContainer className="main nav thmNavCont">
              <img
                src="./images/small-rc-logo.png"
                alt="Real Chemistry Logo"
                width="19"
                style={{ width: '19px' }}
              />
              <TitleSm>
                <a href="/"> REAL CHEMISTRY</a>{" "}
                <span style={{ fontWeight: '700' }}>
                  / TRANSFORMATIVE HEALTHCARE MARKETS
                </span>
              </TitleSm>
            </NavContainer>
          </NavHeader>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <NavHeader
            showShadow={showShadow}
            open={openMobileSubmenu}
            className={blackHeader || isErrorPage ? "black-header" : ""}
            isFixed={isFixed}
          >
            <NavContainer className="main nav">
              <NavList
                logoAniComplete={logoAniComplete}
                paddingLeft={"0"}
                open={openMobileSubmenu}
                className={openMobileSubmenu ? "navSlide" : ""}
              >
                {/* Logo */}
                <NavItem onClick={() => bodyPosition()}>
                  <NavBrand
                    aria-label="Return to home page"
                    to="/"
                    onClick={closeDesktopSubMenu}
                  >
                    {isErrorPage ? (
                      <LogoDesktop
                        aria-label="Return to home page"
                        onClick={() => props.history.push("/")}
                        src="/images/logo/logo-new-white.svg"
                        alt="Real Chemistry logo"
                      />
                    ) : (
                      <LogoDesktop
                        src="/images/logo/logo-new-black.svg"
                        alt="Real Chemistry logo"
                      />
                    )}
                  </NavBrand>
                </NavItem>

                {/* About Menu Item */}
                <NavItem
                  onClick={handleAboutSubM}
                  onMouseEnter={openAboutMenu}
                  onMouseLeave={closeAboutMenu}
                >
                  <MenuLink
                    activeClassName="active"
                    to={
                      window.innerWidth > 991
                        ? aboutData?.destination || "/about"
                        : "#"
                    }
                  >
                    {aboutData?.label}{" "}
                    {aboutData?.label && (
                      <img
                        src="/images/black-cone.png"
                        width="13px"
                        className="navCone"
                        alt="Dropdown"
                      />
                    )}
                  </MenuLink>
                  <DropdownIcon
                    changeDeg={openAboutSubM}
                    onClick={() => handleAboutSubM()}
                    src="/images/Dropdown.svg"
                    alt="Dropdown to sub menu"
                  />
                  <AboutUsMobSubMenu
                    open={openAboutSubM}
                    data={aboutData}
                    close={closeMobileSubMenu}
                  />
                </NavItem>

                {/* Our Alchemy Menu Item */}
                <NavItem
                  onClick={handleAlchemySubM}
                  onMouseEnter={openAlchemyMenu}
                  onMouseLeave={closeAlchemyMenu}
                >
                  <MenuLink
                    activeClassName="active"
                    to={
                      window.innerWidth > 991
                        ? ourAlchemySubmenuData?.destination || "/what-we-do"
                        : "#"
                    }
                  >
                    {ourAlchemySubmenuData?.label}{" "}
                    {ourAlchemySubmenuData?.label && (
                      <img
                        src="/images/black-cone.png"
                        width="13px"
                        className="navCone"
                        alt="Dropdown"
                      />
                    )}
                  </MenuLink>
                  <DropdownIcon
                    changeDeg={openAlchemySubM}
                    onClick={() => handleAlchemySubM()}
                    src="/images/Dropdown.svg"
                    alt="Dropdown to sub menu"
                  />
                  <CareersMobSubMenu
                    open={openAlchemySubM}
                    data={ourAlchemySubmenuData}
                    close={closeMobileSubMenu}
                  />
                </NavItem>

                {/* News & Events Menu Item (NEW) */}
                <NavItem
                  onClick={handleNewsEventsSubM}
                  onMouseEnter={openNewsEventsMenu}
                  onMouseLeave={closeNewsEventsMenu}
                >
                  <MenuLink
                    activeClassName="active"
                    to={
                      window.innerWidth > 991
                        ? insightsSubMenuData?.destination || "/news-events"
                        : "#"
                    }
                  >
                    {insightsSubMenuData?.label}{" "}
                    {insightsSubMenuData?.label && (
                      <img
                        src="/images/black-cone.png"
                        width="13px"
                        className="navCone"
                        alt="Dropdown"
                      />
                    )}
                  </MenuLink>
                  <DropdownIcon
                    changeDeg={openNewsEventsSubM}
                    onClick={() => handleNewsEventsSubM()}
                    src="/images/Dropdown.svg"
                    alt="Dropdown to sub menu"
                  />
                  <NewsEventsMobSubMenu
                    open={openNewsEventsSubM}
                    data={insightsSubMenuData}
                    close={closeMobileSubMenu}
                  />
                </NavItem>

                {/* Careers Menu Item */}
                <NavItem
                  onClick={handleCareersSubM}
                  onMouseEnter={openCareersMenu}
                  onMouseLeave={closeCareersMenu}
                >
                  <MenuLink
                    activeClassName="active"
                    to={
                      window.innerWidth > 991
                        ? careersSubMenuData?.destination || "/careers"
                        : "#"
                    }
                  >
                    {careersSubMenuData?.label}{" "}
                    {careersSubMenuData?.label && (
                      <img
                        src="/images/black-cone.png"
                        width="13px"
                        className="navCone"
                        alt="Dropdown"
                      />
                    )}
                  </MenuLink>
                  <DropdownIcon
                    changeDeg={openCareersSubM}
                    onClick={() => handleCareersSubM()}
                    src="/images/Dropdown.svg"
                    alt="Dropdown to sub menu"
                  />
                  <CareersMobSubMenu
                    open={openCareersSubM}
                    data={careersSubMenuData}
                    close={closeMobileSubMenu}
                  />
                </NavItem>

                {/* Contact Menu Item */}
                <NavItem onClick={closeDesktopSubMenu}>
                  <MenuLink activeClassName="active" to="/contact">
                    {contactUsMenuData?.label}
                  </MenuLink>
                </NavItem>
              </NavList>

              {/* Desktop Submenus */}
              <CareersMenu open={showCareersMenu} data={careersSubMenuData} />
              <AboutUsMenu open={showAboutMenu} data={aboutData} />
              <AlchemyMenu open={showOurAlchemyMenu} data={ourAlchemySubmenuData} />

              {/* NEW: Desktop Submenu for News & Events */}
              <NewsEventsMenu open={showNewsEventsMenu} data={insightsSubMenuData} />

              {/* Mobile Menu Button */}
              <MenuBtnBg>
                <a href="/">
                  <img
                    src="/images/logo/logo.png"
                    className="mobile-logo"
                    alt="Real Chemistry logo"
                  />
                </a>
                <MenuButton
                  className={
                    openMobileSubmenu
                      ? "btnToggle mobile menu open"
                      : "mobile menu open"
                  }
                  onClick={handleMobileSubmenu}
                >
                  <BtnLine />
                  <BtnLine />
                </MenuButton>
              </MenuBtnBg>
            </NavContainer>
          </NavHeader>
        </React.Fragment>
      );
    }
  };

  // Prevent render if /content-preview/ is in path
  if (!props.location.pathname.includes('/content-preview/')) {
    return render();
  }

  return <React.Fragment />;
}

export default withRouter(Header);

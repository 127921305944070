import styled from "styled-components";

import {
    sizeDesktopSm

} from "components/devices";


export const PardotFormComp = styled.div`
display: -webkit-box;
display: -ms-flexbox;
display: flex;
flex-flow: column;
flex-direction: row;
align-items: center;
max-width: 100%;
margin: 0 auto;
width: 100%;
`

export const PardotFormWrap = styled.div`
 width: 100%;
 height: 100%;
 padding: 2% 0;
 display: flex;
 flex-direction: row;
 align-items: flex-start;
 justify-content: space-between;
 position: relative;
`
import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import Home from "./pages/home";
import About from "./pages/about";
import Alchemy from "./pages/alchemy";
import Insights from "./pages/insights";
import Solutions from "./pages/solutions";
import Careers from "./pages/careers";
import BlogDetails from "./pages/blog-details";
import ReportDetails from "./pages/report-details";
import PrivacyPolicy from "./pages/privacy-policy";
import PrivacyPolicyOneTrust from "./pages/privacy-policy/privacy-onetrust";
import Subscribe from 'pages/newsletters/subscribe-thank-you';
import SubscribeBlog from 'pages/newsletters/subscribe-thank-you-blog';
import newsletters from 'pages/newsletters';
import weeklyValueSu from 'pages/newsletters/weekly-value-su';
import HealthEquitySignup from 'pages/newsletters/health-equity-su';
import MonthlyDigestSignup from 'pages/newsletters/monthy-digest-su';
import TermsOneTrust from "./pages/cookie-policy/CookieDetail/terms-onetrust";
import ContactUsPage from 'pages/contact-us';
import JobCategory from "./pages/job-category";
import JobDetail from "./pages/job-detail";
import ServiceProviderPage from "./pages/service-providers";
import ContentPreview from "./cf/preview";
import RssFeed from "./components/RssFeed";
import ErrorPage from "./pages/404";
import GlobalDpaPage from 'pages/global-dpa';
import { isScrolledIntoView } from "./generalHelpers";
import GlobalVendorDpaPage from 'pages/global-vendor-dpa';
import SwoopIpmPage from 'pages/swoop-ipm';
import ScamAlert from 'pages/scam-alert';
import Sxsw from 'pages/sxsw-official-panel-toolkit'
import MediaLounge from 'pages/healthcare-media-lounge'
import integratedComm from 'pages/alchemy/integrated-communications';
import Advertising from 'pages/alchemy/advertising';
import AnalyticsInsights from 'pages/alchemy/analytics-and-insights';
import CaseStudies from './pages/case-studies';

import THM from 'pages/transformative-healthcare-markets';
import activation from 'pages/alchemy/activation';
import medical from 'pages/alchemy/medical';
import avant from 'pages/avant';
import targetingEngagement from 'pages/alchemy/targeting-engagement';

//  DE&I Page
import DEI from "./pages/DEI";
import allArticles from 'pages/all-articles';
import EventsPage from "./pages/events";
import MediaResources from 'pages/insights/MediaResources';
import MediaResourcesPage from 'pages/media-resouces';


function Routes(props) {
  const history = useHistory();
  const { pathname, hash } = useLocation();

  let retries = 0;
  function startTimer(hash) {
    if (hash.length > 0) {
      let timer = setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
          if (isScrolledIntoView(element)) {
            history.replace(window.location.pathname)
            clearTimeout(timer)
          } else {

            setTimeout(() => {
              startTimer(hash)
            }, 1000)
          }
        } else {
          retries++;

          if (retries === 20) {

            clearTimeout(timer)
          } else
            setTimeout(() => {
              startTimer(hash)
            }, 1000)
        }
      }, 100);
    }
  }

  useEffect(() => {
    startTimer(hash)
    // eslint-disable-next-line
  }, [pathname, hash]); //


  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/about" component={About} />
      <Route path="/what-we-do" component={Alchemy} />
      <Route path="/integrated-communications" component={integratedComm} />
      <Route path={"/advertising"} component={Advertising} />
      <Route path={"/activation"} component={activation} />
      <Route path="/analytics-and-insights" component={AnalyticsInsights} />
      <Route path={"/medical/lp/avant-paid"} component={avant} />
      <Route path={"/medical"} component={medical} />
      <Route path={"/targeting-and-engagement"} component={targetingEngagement} />
      <Route path="/news-events" component={Insights} />
      <Route path="/all-articles" component={allArticles} />
      <Route path="/solutions" component={Solutions} />
      <Route path="/careers" component={Careers} />
      <Route path="/article" component={BlogDetails} />
      <Route path="/privacy-policy/" component={PrivacyPolicy} />
      <Route path="/privacy-policy-onetrust" component={PrivacyPolicyOneTrust} />
      <Route path="/subscribe" component={Subscribe} />
      <Route path="/subscribe-blog" component={SubscribeBlog} />
      <Route path="/terms-of-services" component={TermsOneTrust} />
      <Route path="/job-category" component={JobCategory} />
      <Route path="/job-detail" component={JobDetail} />
      <Route path="/swoop-ipm-ai" component={SwoopIpmPage} />
      <Route path="/service-providers" component={ServiceProviderPage} />
      <Route path="/global-dpa" component={GlobalDpaPage} />
      <Route path="/global-vendor-dpa" component={GlobalVendorDpaPage} />
      <Route path="/content-preview" component={ContentPreview} />
      <Route path="/scam-alert" component={ScamAlert} />
      <Route path="/sxsw-official-panel-toolkit" component={Sxsw} />
      <Route path="/healthcare-media-lounge-toolkit" component={MediaLounge} />
      <Route path="/media-resources" component={MediaResourcesPage} />
      <Route path="/dei" component={DEI} />
      <Route path="/feed" component={RssFeed} />
      <Route path="/events-SXSW" component={EventsPage} />
      <Route path="/newsletters" component={newsletters} />
      <Route path="/weekly-value-newsletter" component={weeklyValueSu} />
      {/* <Route path="/real-health-equity-check-up" component={HealthEquitySignup} /> */}
      <Route path="/healthcare-connection" component={MonthlyDigestSignup} />
      <Route path="/contact" component={ContactUsPage} />
      <Route path="/podcasts" component={Insights} />
      <Route path="/blog" component={Insights} />
      <Route path="/reports" component={Insights} />
      <Route path="/financial" component={Insights} />
      <Route path="/events" component={Insights} />
      <Route path="/all-news" component={Insights} />
      <Route path="/press-releases" component={Insights} />
      <Route path="/case-study/:slug" component={CaseStudies} />
      {/* <Route path="/transformative-healthcare-markets" component={THM} /> */}

      <Route path="/404" component={ErrorPage} />

      <Redirect exact from="/transformative-healthcare-markets" to="/analytics-and-insights" />
      <Redirect exact from="/our-alchemy" to="/what-we-do" />
      <Redirect exact from="/data-and-technology" to="/what-we-do" />
      <Redirect exact from="/services" to="/what-we-do" />
      <Redirect exact from="/clinical-solutions" to="/what-we-do" />
      <Redirect exact from="/commercial-solutions" to="/what-we-do" />
      <Redirect exact from="/corporate-solutions" to="/what-we-do" />
      <Redirect exact from="/monthly-digest" to="/healthcare-connection" />
      <Redirect exact from="/advertising-medical-solutions" to="/commercial-medical-solutions" />
      <Redirect exact from="/our-views" to="/news-events" />
      <Redirect exact from="/daily-value-newsletter" to="/weekly-value-newsletter" />
      <Redirect exact from="/integrated-marketing-communications" to="/integrated-communications" />
      <Redirect exact from="/data-ai-solutions" to="/analytics-and-insights" />
      <Redirect exact from="/commercial-medical-solutions" to="/advertising" />
      {/* <Redirect exact from="/health-equity-digest" to="/real-health-equity-check-up" /> */}
      <Redirect exact from="/real-health-equity-check-up" to="/newsletters" />

      {/* <Redirect exact from="/blog" to="/news-events?media=blog" /> */}
      {/* <Redirect exact from="/events" to="/news-events?media=events" /> */}
      {/* <Redirect exact from="/podcasts" to="/news-events?media=podcasts" /> */}
      {/* <Redirect exact from="/press-releases" to="/news-events?media=press-releases" /> */}
      <Redirect exact from="/signup" to="newsletters" />
      <Redirect exact from="/work-with-us" to="/" />
      <Redirect exact from="/our-clients" to="/" />
      <Redirect exact from="*" to="/404" />
    </Switch>
  );
}

export default Routes;

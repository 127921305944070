// Subscribe.jsx
"use client"; // If you're in Next.js or need a "use client" directive
import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
// If you still need react-router for history, location, Link, etc.
import { useLocation, useHistory } from "react-router-dom";

// If your app uses a context or form manager (as in SubscribeCard)
import { useAppContext } from "store"; 
import useFormManager from "hooks/useFormManager";
import { recaptchaHelper, findContent } from "../../../generalHelpers";
import { r3cPageContent } from "../../../constants";

/************************************************
 *               STYLED COMPONENTS
 ************************************************/
const WidthWrapper = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: auto;
`;

const SubscribeContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #e2e2e2;
  padding: 25px 25px 50px 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: ${({ marginBottom }) => marginBottom || "120px"};
  gap: 96px;

  @media (max-width: 768px) {
    padding: 40px 20px;
    gap: 48px;
  }
`;

const InnerRow = styled.div`
  max-width: 500px;
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 139px;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 60px;
  }
  @media (max-width: 768px) {
    gap: 24px;
  }
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;

  @media (max-width: 768px) {
    width: 100%;
    gap: 24px;
  }
`;

const HeaderContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
`;

const Subtitle = styled.div`
  color: #212121;
  font-size: 12px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.6px;
  word-wrap: break-word;
`;

const Title = styled.div`
  color: #212121;
  font-size: 36px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  line-height: 45px;
  word-wrap: break-word;

  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 36px;
  }
`;

const Description = styled.div`
  color: #424242;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 28.8px;
  word-wrap: break-word;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const FormContainer = styled.div`
  align-self: stretch;
  height: 134px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;

  @media (max-width: 768px) {
    height: auto;
    gap: 24px;
  }
`;

const InputWrapper = styled.div`
  align-self: stretch;
  height: 48px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: 768px) {
    height: auto;
  }
`;

const EmailFieldContainer = styled.div`
  align-self: stretch;
  background: white;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden; /* ensures content stays within the radius */
`;

// Optional: If you want an icon on the left
const IconBox = styled.div`
  width: 28px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #757575;
  font-size: 12px;
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 400;
  line-height: 1.3;
`;

// We'll switch to an actual <input> for the email
const EmailInput = styled.input`
  flex: 1 1 0;
  height: 48px;
  padding: 12px 16px;
  border: none;
  outline: none;
  color: #9e9e9e;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 23.58px;
  background-color: transparent;

  &::placeholder {
    color: #bdbdbd;
  }
`;

const ButtonWrapper = styled.div`
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const SignUpButton = styled.button`
  padding: 14px 20px;
  background: #3c00e7;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
  border: none;

  &:hover {
    background: #3100bf;
  }
`;

const ButtonText = styled.div`
  color: white;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  line-height: 18px;
  word-wrap: break-word;
`;

const ErrorLabel = styled.div`
  color: #d54639;
  text-transform: inherit;
  margin-top: 7px;
  display: block;
  font-size: 14px;
  text-align: left;
  max-width: 300px;
`;

/************************************************
 *            MERGED SUBSCRIBE COMPONENT
 ************************************************/
const Subscribe = ({ from = "", marginBottom }) => {
  // If your code uses react-router
  const history = useHistory();
  const { pathname } = useLocation();

  // Pull in your context
  const { setSubscriptionEmail, updateFormSubmitted } = useAppContext();

  // State for input and validations
  const [subscription, setSubscription] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [validRecaptcha, setValidRecaptcha] = useState(true);

  // Content from your server
  const [subscribeContent, setSubscribeContent] = useState({ headline: "", description: "" });

  // optional form manager
  const OPTIONAL = [];
  const {
    handleSubmit,
    subscriptionFormData
  } = useFormManager(
    {
      data: { email: "" },
      errors: { email: "" }
    },
    OPTIONAL
  );

  // BASIC EMAIL VALIDATION
  function validateEmail(emailVal) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(emailVal).toLowerCase());
  }

  // On change
  const handleOnChangeSubscription = (e) => {
    setSubscription(e.target.value);
    setIsValid(true);
  };

  // Actually submit the form
  const helperSubscriptionStateManager = useCallback(() => {
    // Save to context
    setSubscriptionEmail(subscription);

    // Sync with form manager
    subscriptionFormData.data.email = subscription;
    subscriptionFormData.data.from = 'blog-details';

    // Clear input
    setSubscription("");
    updateFormSubmitted(false);

    // Actually do final submission
    handleSubmit();

    // For this sample, let's just push to /subscribe
    history.push("/subscribe-blog");
  }, [history, subscription, setSubscriptionEmail, updateFormSubmitted, subscriptionFormData, from, handleSubmit]);

  const handleSubscribeClick = useCallback(
    async (e) => {
      e.preventDefault();
      let validate = validateEmail(subscription);

      // If invalid email
      if (!validate) {
        setIsValid(false);
        return;
      }

      // If valid email, call recaptcha
      let proceed = await recaptchaHelper();
      if (proceed) {
        setValidRecaptcha(true);
      } else {
        setValidRecaptcha(false);
      }

      if (validate && proceed) {
        // gtag or dataLayer can be pushed here
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: "e_form_submit02" });

        helperSubscriptionStateManager();
      }
    },
    [subscription, helperSubscriptionStateManager]
  );

  // Press Enter -> also submit
  const handleOnKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter" && subscription.length > 0) {
        handleSubscribeClick(e);
      }
    },
    [subscription, handleSubscribeClick]
  );

  // Reset input whenever path changes
  useEffect(() => {
    setSubscription("");
    setIsValid(true);
  }, [pathname]);

  // Fetch content from your endpoint
  useEffect(() => {
    fetch(r3cPageContent + "?route=/subscribe-popup")
      .then((response) => response.json())
      .then((page) => {
        // 'subscribeContent' is a key in your content
        const found = findContent(page.contents, "subscribeContent");
        if (found) {
          setSubscribeContent(found);
        }
      });
  }, []);

  return (
    <SubscribeContainer marginBottom={marginBottom}>
      <WidthWrapper>
        <InnerRow>
          <ContentBox>
            {/* HEADERS */}
            <HeaderContainer>
              <Subtitle></Subtitle>
              <Title>Subscribe to Our Blog</Title>
            </HeaderContainer>

            {/* DESCRIPTION TEXT */}
            <Description>Sign up to be notified via email whenever we publish something new.</Description>

            {/* FORM */}
            <FormContainer>
              <InputWrapper>
                <EmailFieldContainer>
                  {/* 
                    If you want your envelope icon, you can uncomment:
                    <IconBox>envelope</IconBox> 
                  */}
                  <EmailInput
                    placeholder="Email Address"
                    value={subscription}
                    onChange={handleOnChangeSubscription}
                    onKeyDown={handleOnKeyDown}
                  />
                </EmailFieldContainer>
              </InputWrapper>

              {/* SIGN UP BUTTON */}
              <ButtonWrapper>
                <SignUpButton onClick={handleSubscribeClick}>
                  <ButtonText>Sign Up</ButtonText>
                </SignUpButton>
              </ButtonWrapper>

              {/* ERRORS */}
              {!isValid && subscription.length === 0 && (
                <ErrorLabel>Email Required*</ErrorLabel>
              )}
              {!isValid && subscription.length > 0 && (
                <ErrorLabel>Valid Email Required*</ErrorLabel>
              )}
              {!validRecaptcha && (
                <ErrorLabel style={{ fontSize: "11px" }}>
                  Sorry, Google reCAPTCHA has denied your submission. 
                  If you believe this is an error, please contact 
                  marketingteam@realchemistry.com for assistance.
                </ErrorLabel>
              )}
            </FormContainer>
          </ContentBox>
        </InnerRow>
      </WidthWrapper>
    </SubscribeContainer>
  );
};

export default Subscribe;

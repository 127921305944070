import React, { useState } from 'react';
import styled from 'styled-components';
// REACT-SHARE imports:
import {
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';

import {
  ButtonsRow,
  ButtonWrapper,
  ButtonPrimary,
  ButtonPrimaryText,
} from '../../pages/blog-details/BlogHero'; // Adjust path if needed

// ~~~ Modal Styles (reuse or tweak to match your project) ~~~
const ModalOverlay = styled.div`
  position: fixed;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const ModalContainer = styled.div`
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  width: 90%;
  max-width: 800px;
  text-align: center;
`;

const ModalCloseButton = styled.button`
  background: #3c00e7;
  color: #fff;
  padding: 8px 16px;
  margin-top: 16px;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

// ~~~ Existing DownloadSection styles ~~~
const DownloadSectionContainer = styled.div`
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 96px;

  @media (max-width: 768px) {
    gap: 48px;
  }
`;

const DownloadSectionRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const DownloadSectionImage = styled.img`
  width: 50%;
  max-width: 582px;
  height: auto;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%);
  border-radius: 5px;
  object-fit: cover;

  @media (max-width: 768px) {
    width: 100%
  }
`;

const DownloadSectionTextColumn = styled.div`
  width: 479px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const DownloadSectionTitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const DownloadSectionTitle = styled.div`
  color: #212121;
  font-size: 36px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  line-height: 45px;
  word-wrap: break-word;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

const DownloadSectionParagraph = styled.div`
  color: #424242;
  font-size: 18px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  line-height: 28.8px;
  word-wrap: break-word;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

// ~~~ The component ~~~
/**
 * @param {string} title         - Title to display
 * @param {string} body          - Paragraph text for the section
 * @param {string} image         - URL of the image to display
 * @param {string} downloadLink  - PDF or external link
 * @param {boolean} gatedDownload - If true, show a modal instead of direct download
 */
const DownloadSection = ({ title, body, image, downloadLink, gatedDownload = false }) => {
  // 1) Track modal visibility
  const [showModal, setShowModal] = useState(false);

  // 2) For react-share, define a shareUrl based on window.location or a fallback.
  const shareUrl =
    typeof window !== 'undefined' ? window.location.href : 'https://yourdomain.com';

  // 3) Download button logic with gating
  const handleDownloadClick = () => {
    if (gatedDownload) {
      // Show the modal with an iframe instead of downloading
      setShowModal(true);
    } else {
      // Normal direct download
      if (downloadLink) {
        window.open(downloadLink, '_blank');
      }
    }
  };

  return (
    <>
      <DownloadSectionContainer>
        <DownloadSectionRow>
          {/* Left side image */}
          <DownloadSectionImage
            src={image}
            alt={title || 'Download Section Image'}
          />

          {/* Right side text + buttons */}
          <DownloadSectionTextColumn>
            <DownloadSectionTitleBlock>
              <DownloadSectionTitle>{title}</DownloadSectionTitle>
            </DownloadSectionTitleBlock>

            <DownloadSectionParagraph>{body}</DownloadSectionParagraph>

            {/* First row: Download Button */}
            <ButtonsRow>
              <ButtonWrapper>
                <ButtonPrimary onClick={handleDownloadClick}>
                  {/* Example download icon */}
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.40625 0.75H8.59375C8.94922 0.75 9.25 1.05078 9.25 1.40625V6H11.6289C12.1211 6 12.3672 6.60156 12.0117 6.95703L7.85547 11.1133C7.66406 11.3047 7.30859 11.3047 7.11719 11.1133L2.96094 6.95703C2.60547 6.60156 2.85156 6 3.34375 6H5.75V1.40625C5.75 1.05078 6.02344 0.75 6.40625 0.75ZM14.5 11.0312V14.0938C14.5 14.4766 14.1992 14.75 13.8438 14.75H1.15625C0.773438 14.75 0.5 14.4766 0.5 14.0938V11.0312C0.5 10.6758 0.773438 10.375 1.15625 10.375H5.14844L6.48828 11.7148C7.03516 12.2891 7.9375 12.2891 8.48438 11.7148L9.82422 10.375H13.8438C14.1992 10.375 14.5 10.6758 14.5 11.0312ZM11.1094 13.4375C11.1094 13.1367 10.8633 12.8906 10.5625 12.8906C10.2617 12.8906 10.0156 13.1367 10.0156 13.4375C10.0156 13.7383 10.2617 13.9844 10.5625 13.9844C10.8633 13.9844 11.1094 13.7383 11.1094 13.4375ZM12.8594 13.4375C12.8594 13.1367 12.6133 12.8906 12.3125 12.8906C12.0117 12.8906 11.7656 13.1367 11.7656 13.4375C11.7656 13.7383 12.0117 13.9844 12.3125 13.9844C12.6133 13.9844 12.8594 13.7383 12.8594 13.4375Z"
                      fill="white"
                    />
                  </svg>
                  <ButtonPrimaryText>Download</ButtonPrimaryText>
                </ButtonPrimary>
              </ButtonWrapper>
            </ButtonsRow>

            {/* Second row: Share Buttons */}
            <ButtonsRow>
              <ButtonWrapper>
                <LinkedinShareButton
                  url={shareUrl}
                  title="Check out this report!"
                  style={{ width: '100%' }}
                >
                  <ButtonPrimary>
                    <ButtonPrimaryText>Share on LinkedIn</ButtonPrimaryText>
                  </ButtonPrimary>
                </LinkedinShareButton>
              </ButtonWrapper>

              <ButtonWrapper>
                <TwitterShareButton
                  url={shareUrl}
                  title="Check out this report!"
                  style={{ width: '100%' }}
                >
                  <ButtonPrimary>
                    <ButtonPrimaryText>Share on X</ButtonPrimaryText>
                  </ButtonPrimary>
                </TwitterShareButton>
              </ButtonWrapper>
            </ButtonsRow>
          </DownloadSectionTextColumn>
        </DownloadSectionRow>
      </DownloadSectionContainer>

      {/* Modal for Gated Download */}
      <ModalOverlay show={showModal} onClick={() => setShowModal(false)}>
        <ModalContainer onClick={(e) => e.stopPropagation()}>
          {/* Embed your Pardot form or gated content in an iframe */}
          <iframe
            src={downloadLink} 
            width="100%"
            height="500px"
            frameBorder="0"
            title="Gated Download Form"
            style={{ border: 'none' }}
          ></iframe>

          <ModalCloseButton onClick={() => setShowModal(false)}>
            Close
          </ModalCloseButton>
        </ModalContainer>
      </ModalOverlay>
    </>
  );
};

export default DownloadSection;

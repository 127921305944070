import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { smartTruncate } from 'generalHelpers';
import { flushSync } from 'react-dom'; // <-- IMPORTANT!
import { useAppContext } from '../../store';

import { Row } from 'components/elements';
import Button from 'components/MediaGrid/MediaButton';

function FeaturedEvents({ events }) {
  const { toggleMediaFilter } = useAppContext();

  // Handler for "View All Events" button click.
  // This will toggle/select the events filter.
  const handleViewAllEvents = () => {
    toggleMediaFilter('Event');
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <div>
      <BlueHeader>
      </BlueHeader>

      <FeaturedEventsContainer>
        <TitleContainer>
          <Title>Featured Events</Title>
        </TitleContainer>

        <Timeline events={events} />

        <Row style={{ flexDirection: 'row-reverse', width: '100%', margin: '20px 0' }}>
          <Button
            variant="secondary"
            url="/events"
            // onClick={handleViewAllEvents}  // Use onClick instead of link
            title="View All Events"
            width="245px"
            height="44px"
          />
        </Row>
      </FeaturedEventsContainer>
    </div>
  );
}

export default FeaturedEvents;

function Timeline({ events }) {
  // Detect if mobile
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 768);
    // Optionally add a window resize listener if you want dynamic responsiveness.
  }, []);

  // Sort events by date
  const sortedEvents = [...events].sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  );

  // Initialize the month counter starting from 0
  const monthCounter = useRef(0);

  // State to track current months displayed on the timeline
  const [timelineMonths, setTimelineMonths] = useState(() => {
    // Initialize with twelve months starting from the previous month
    const today = new Date();
    const startMonth = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1
    );
    return generateTimelineMonths(sortedEvents, startMonth, monthCounter);
  });

  // When sortedEvents (and optionally monthCounter) change, update timelineMonths
  useEffect(() => {
    if (sortedEvents && sortedEvents.length) {
      const today = new Date();
      const startMonth = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        1
      );
      const newTimelineMonths = generateTimelineMonths(sortedEvents, startMonth, monthCounter);
      setTimelineMonths(newTimelineMonths);
    }
  }, [events]);
  // Reference to the MonthsContainer
  const monthsContainerRef = useRef(null);

  // Dimension of a single month container (width on desktop, height on mobile)
  const dimensionRef = useRef(0);

  useEffect(() => {
    if (
      monthsContainerRef.current &&
      monthsContainerRef.current.children.length > 0
    ) {
      if (isMobile) {
        // On mobile: use vertical dimension
        dimensionRef.current =
          monthsContainerRef.current.children[0].offsetHeight + 40;
      } else {
        // On desktop: use horizontal dimension
        dimensionRef.current =
          monthsContainerRef.current.children[0].offsetWidth;
      }
    }
  }, [isMobile]);

  /* -----------------------------------------------------------------------
     HANDLE PREV (move back 3 months)
  ------------------------------------------------------------------------ */
  const handlePrev = () => {
    const offset = dimensionRef.current * 3;
    const directionProp = isMobile ? { y: -offset } : { x: -offset };

    // 1. Synchronously add 3 new months to the front so they're in the DOM
    flushSync(() => {
      setTimelineMonths((prevMonths) => {
        let newMonths = [...prevMonths];
        for (let i = 0; i < 3; i++) {
          const firstMonthDate = newMonths[0].date;
          const newStartDate = new Date(
            firstMonthDate.getFullYear(),
            firstMonthDate.getMonth() - 1,
            1
          );
          monthCounter.current -= 1;
          const newMonth = generateSingleMonth(
            sortedEvents,
            newStartDate,
            true,
            monthCounter.current
          );
          newMonths.unshift(newMonth); // add to front
        }
        return newMonths;
      });
    });

    // 2. Immediately set the container so the new months are offscreen to the left/up
    gsap.set(monthsContainerRef.current, directionProp);

    // 3. Animate from offset back to 0
    gsap.to(monthsContainerRef.current, {
      x: 0,
      y: 0,
      duration: 0.5,
      ease: 'power2.out',
      onComplete: () => {
        // 4. Remove the last 3 months we no longer need
        setTimelineMonths((prevMonths) => prevMonths.slice(0, -3));
      },
    });
  };

  /* -----------------------------------------------------------------------
     HANDLE NEXT (move forward 3 months)
  ------------------------------------------------------------------------ */
  const handleNext = () => {
    const offset = dimensionRef.current * 3;
    const animateProp = isMobile ? { y: -offset } : { x: -offset };

    // 1. Synchronously add 3 new months to the end (they appear in DOM first)
    flushSync(() => {
      setTimelineMonths((prevMonths) => {
        let newMonths = [...prevMonths];
        for (let i = 0; i < 3; i++) {
          const lastMonthDate = newMonths[newMonths.length - 1].date;
          const newEndDate = new Date(
            lastMonthDate.getFullYear(),
            lastMonthDate.getMonth() + 1,
            1
          );
          monthCounter.current += 1;
          const newMonth = generateSingleMonth(
            sortedEvents,
            newEndDate,
            false,
            monthCounter.current
          );
          newMonths.push(newMonth); // add to end
        }
        return newMonths;
      });
    });

    // 2. Now animate from 0 to the negative offset
    gsap.to(monthsContainerRef.current, {
      ...animateProp,
      duration: 0.5,
      ease: 'power2.out',
      onComplete: () => {
        // 3. Remove the first 3 months
        setTimelineMonths((prevMonths) => prevMonths.slice(3));
        // 4. Reset the container to 0
        gsap.set(monthsContainerRef.current, isMobile ? { y: 0 } : { x: 0 });
      },
    });
  };

  /* -----------------------------------------------------------------------
     RENDER
  ------------------------------------------------------------------------ */

  return (
    <TimelineWrapper>
      <NavigationButton direction={isMobile ? 'up' : 'left'} onClick={handlePrev}>
        {isMobile ? '↑' : '←'}
      </NavigationButton>

      <TimelineContainer>
        <HorizontalLine />

        <MonthsContainer isMobile={isMobile} ref={monthsContainerRef}>
          {timelineMonths.map((month, index) => (
            <MonthContainer key={`${month.label}-${month.year}-${index}`} isMobile={isMobile}>
              <MonthLabel isToday={month.isToday}>
                {month.isToday ? 'TODAY' : month.label}
              </MonthLabel>

              <MonthYear>{month.year}</MonthYear>
              <ConnectorLine black={!month.event} isTop={month.isTop} />

              {month.event && (
                <EventCard href={month.event?.detailLink} isTop={month.isTop}>
                  <EventDate></EventDate>
                  <EventTitle>{smartTruncate(month.event.title, 70)}</EventTitle>
                  <EventDescription>{smartTruncate(month.event.overview, 100)}</EventDescription>
                </EventCard>
              )}
            </MonthContainer>
          ))}
        </MonthsContainer>
      </TimelineContainer>

      <NavigationButton direction={isMobile ? 'down' : 'right'} onClick={handleNext}>
        {isMobile ? '↓' : '→'}
      </NavigationButton>
    </TimelineWrapper>
  );
}

/* -----------------------------------------------------------------------
   HELPERS
------------------------------------------------------------------------ */

function generateTimelineMonths(events, startDate, monthCounter) {
  const months = [];
  for (let i = 0; i < 12; i++) {
    const monthDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth() + i,
      1
    );
    const month = generateSingleMonth(
      events,
      monthDate,
      true,
      monthCounter.current
    );
    months.push(month);
    monthCounter.current += 1;
  }
  return months;
}

function generateSingleMonth(
  events,
  monthDate,
  isFirstMonth = false,
  index = 0
) {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const isCurrentMonth =
    monthDate.getMonth() === today.getMonth() &&
    monthDate.getFullYear() === today.getFullYear();

  const eventForMonth = events.find((event) => {
    const eventDate = new Date(event.date);
    return (
      eventDate.getMonth() === monthDate.getMonth() &&
      eventDate.getFullYear() === monthDate.getFullYear()
    );
  });

  const isTop = index % 2 === 0;

  return {
    date: isCurrentMonth ? today : monthDate,
    label: isCurrentMonth
      ? 'TODAY'
      : monthDate.toLocaleString('default', { month: 'short' }).toUpperCase(),
    year: monthDate.getFullYear(),
    isToday: isCurrentMonth,
    isTop,
    event: eventForMonth || null,
  };
}

function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleDateString('default', {
    month: 'long',
    year: 'numeric',
  });
}

/* -----------------------------------------------------------------------
   STYLED COMPONENTS
------------------------------------------------------------------------ */

const FeaturedEventsContainer = styled.div`
  border-right: 2px solid #3C00E7;
  border-left: 2px solid #3C00E7;
  padding: 120px;
  background: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;

  @media (max-width: 768px) {
    padding: 40px 20px;
    gap: 20px;
  }
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

const Title = styled.h1`
  color: #F0F0F0;
  font-size: 80px;
  font-family: 'Helvetica Now Display', sans-serif;
  font-weight: 700;
  line-height: 76px;
  word-wrap: break-word;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 40px;
    line-height: 38px;
  }
`;

const TimelineWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const TimelineContainer = styled.div`
  position: relative;
  width: 100%;
  background: black;
  height: 450px;
  padding: 50px 0;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 450px; /* Adjust as needed for vertical space */
    position: relative;
    top: 25px;
    padding: 50px 0;
  }
`;

const HorizontalLine = styled.div`
  position: absolute;
  width: 100%;
  top: 50%;
  border-top: 1px solid white;
  transform: translateY(-0.5px);

  @media (max-width: 768px) {
    width: 1px;
    height: 100%;
    left: 30px;
    top: 0;
    border-top: none;
    border-left: 1px solid white;
    transform: none;
  }
`;

const MonthsContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 157px;
  width: max-content;
  ${(props) =>
    props.isMobile &&
    `
      flex-direction: column;
      align-items: center;
      margin-top: 0;
  `}

  @media (max-width: 768px) {
    padding-left: 0;
  }
`;

const MonthContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 150px;

  @media (max-width: 768px) {
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    height: 100px;
    margin-bottom: 40px;
  }
`;

const MonthLabel = styled.div`
  padding: 8px 16px;
  z-index: 1;
  background: ${(props) =>
    props.isToday ? '#3C00E7' : 'black'};
  border: 1px solid white;
  color: white;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 4px;

  @media (max-width: 768px) {
    align-self: flex-start;
  }
`;

const MonthYear = styled.div`
  color: white;
  font-size: 10px;
  font-weight: 400;
  margin-top: 3px;

  @media (max-width: 768px) {
    margin-bottom: 0px;
    margin-left: 5px;
    margin-top: 12px;
  }
`;

const ConnectorLine = styled.div`
  width: 1px;
  height: 40px;
  background: ${(props) => (props.black ? 'black' : 'white')};
  position: relative;
  top: ${(props) => (props.isTop ? '-85px' : '-9px')};

  @media (max-width: 768px) {
    width: 40px;
    height: 1px;
    top: 9px;
    margin-top: 8px;
  }
`;

const EventCard = styled.a`
  height: 200px;
  width: 200px;
  padding: 16px;
  background: white;
  border: 2px solid white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;

  &:hover {
    transform: translateX(-50%) scale(1.02);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }

  top: ${(props) => (props.isTop ? '-220px' : '60px')};
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease-in-out;

  @media (max-width: 768px) {
    &:hover {
      transform: scale(1.02);
      box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    }
    position: relative;
    top: -61px;
    left: 0;
    transform: none;
    margin-top: 16px;
    width: 215px;
    height: auto;
  }
`;

const EventDate = styled.div`
  color: #333333;
  font-size: 11px;
  font-family: 'Helvetica Now Display', sans-serif;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 8px;
`;

const EventTitle = styled.div`
  color: #333333;
  font-size: 20px;
  font-family: 'Helvetica Now Display', sans-serif;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 16px;
`;

const EventDescription = styled.div`
  color: #333333;
  font-size: 14px;
  font-family: 'Helvetica Now Display', sans-serif;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.5px;
`;

const NavigationButton = styled.button`
  position: absolute;
  z-index: 1000;
  background: black;
  border: 2px solid white;
  color: white;
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;
  
  &:hover {
    background: #3C00E7;
    border-color: #3C00E7;
  }

  /* Desktop (horizontal) layout */
  ${(props) =>
    props.direction === 'left' &&
    `
      top: 50%;
      left: -30px;
      transform: translateY(-50%);
    `}
  ${(props) =>
    props.direction === 'right' &&
    `
      top: 50%;
      right: -30px;
      transform: translateY(-50%);
    `}

  /* Mobile (vertical) layout */
  ${(props) =>
    props.direction === 'up' &&
    `
      top: -10px;
      left: 30px;
      transform: translateX(-50%);
    `}
  ${(props) =>
    props.direction === 'down' &&
    `
      bottom: -40px;
      left: 30px;
      transform: translateX(-50%);
    `}

  @media (max-width: 768px) {
    display: flex; /* On mobile, show up/down buttons and hide left/right buttons */
    ${(props) =>
    props.direction === 'left' || props.direction === 'right'
      ? 'display: none;'
      : ''}
  }
`;

const BlueHeader = styled.div`
  color: white;
  font-size: 16px;
  font-weight: 600;
  padding: 16px 120px;
  background-color: #3C00E7;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding: 16px 20px;
  }
`;

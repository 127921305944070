import React, { useEffect, useRef } from 'react';
import Slider from "react-slick";
import { Divider } from 'components/MediaCarousel/elements';
import {
  SectionTitle,
  BodySm,
  BodyRg, Row, Col, TitleMd, TitleXl,
} from 'components/elements';
import {
  JobsSectionWrap,
  DividerLg,
  JobsGrid,
  JobsGridContent,
  JobsSectionBottom,
  CtaContainer
} from './elements';
import JobDarkCard from "../../../components/JobDarkCard";

// Slick Slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LocationCardDark from "../../../components/LocationDarkCard";
import ViewAll from "../../../components/ViewAll";
import { Link, useLocation } from 'react-router-dom';

function JobsSection(props) {
  const data = props.jobsSectionData ? props.jobsSectionData : {};
  const sliderRef = useRef(null);
  const location = useLocation();

  let departments = [];
  let departmentPriority = ["Integrated Communications", "Advertising,", "Activation", "Advanced Analytics, Targeting & Engagement", "Medical", "Creative", "Corporate"];

  let sortedDepartments = data?.departments.slice().sort((a, b) => {
    return departmentPriority.indexOf(a.name) - departmentPriority.indexOf(b.name);
  });

  let topThree = sortedDepartments.slice(0, 3).sort((a, b) => b.count - a.count);
  let bottomThree = sortedDepartments.slice(3).sort((a, b) => b.count - a.count);
  departments = [...topThree, ...bottomThree];


  // useEffect added to manage if #browseLocation is added to URL
  useEffect(() => {
    // Handle location hash change when component mounts or location changes
    const hash = location.hash.toLowerCase();

    if (hash === "#browselocation") {
      sliderRef.current.slickGoTo(1);
    }

    if (hash === "#careerfocus") {
      sliderRef.current.slickGoTo(0);
    }
  }, [location]);

  const settings = {
    infinite: false,
    centerSpacing: "0",
    accessibility: true,
    margin: "20px",
    slidesToShow: 1,
    draggable: false,
    autoplay: false,
    speed: 1000,
    slidesToScroll: 1,
    initialSlide: 0,
    dots: true,
    customPaging: (slider, i) => {
      return <button aria-label={slider === 0 ? 'CAREER FOCUS' : 'LOCATION'} />;
    },
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 0,
        settings: { speed: 600 }
      },
      {
        breakpoint: 991,
        settings: { speed: 1000 }
      },
    ]
  };

  return (
    <JobsSectionWrap id={props.id} className="job openings section">
      <JobsGrid>
        <Divider />
        <SectionTitle padding="0 30px 60px">
          <BodySm textTransform="uppercase">{data.label}</BodySm>
          <TitleXl textTransform="none">We’re gonna need a bigger office.</TitleXl>
          <TitleMd> Luckily, we’re flexible.</TitleMd>
          <BodyRg>
            At Real Chemistry, we approach our work with Real HART – that is, our Real Hybrid and Regional Teams approach. We believe we are best together, and our workplace strategy fosters connection and collaboration in person while also supporting flexibility for our people. We’re a unique mix of talent and grit with a desire to make healthcare better sprinkled on top. Yet there isn’t one list of characteristics that describes all of us, because we each bring something different to this ever-growing table. That’s what makes our jobs more fun, and why we do them so well.
          </BodyRg>
          <BodyRg>
            So, how does this translate for our colleagues working across our global locations? For those who are within an hour of one of our offices, we expect attendance in the office two days per week, either at a Real Chemistry office or onsite with clients.  We are not looking for attendance for the sake of attendance but believe that the opportunity to coordinate in-office team meetings, 1:1 meetings with managers, taking advantage of on-site learning, and connecting with client partners is critical to delivering on our purpose of making healthcare what it should be.  And whether you’re working from one of our hubs and coming into the office two days per week, or part of our Regional teams and reconnecting once a quarter for collaboration, culture, and learning opportunities, our teams are here to provide support for each colleague with their own #LifeatRealChem experience.
          </BodyRg>
          <CtaContainer>
            <ViewAll link={'/job-category'} title={"SEE OPEN ROLES"} borderColorHover="#fff" borderColor="#000" titleColor="#000" hoverBackground="#000" svgColor="#000" width="290px" height="44px" />
          </CtaContainer>
        </SectionTitle>
        <DividerLg />
        <JobsGridContent id="careerfocus">
          <Slider {...settings} ref={sliderRef}>
            <Row>
              {
                departments ? departments.map((dept, index) => {
                  let lnk = '/job-category/c:' + dept.name;
                  return dept.name !== "undefined" && (
                    <Col key={index}>
                      <JobDarkCard
                        jobTitle={dept.name}
                        count={dept.count}
                        link={lnk}
                        isLocation={false}
                        jobDesc={dept.description}
                      />
                    </Col>
                  );
                }) : null
              }
            </Row>
            <Row className="locationRow">
              {
                data.locations ? data.locations.map((loc, index) => {
                  let lnk = '/job-category/l:' + loc.name;
                  return (
                    <Col key={index}>
                      <LocationCardDark
                        jobType={loc.name}
                        count={loc.count}
                        link={lnk}
                        isLocation={false}
                        jobDesc={loc.description}
                        locImg={loc.image}
                      />
                    </Col>
                  );
                }) : null
              }
            </Row>
          </Slider>
          <JobsSectionBottom>
            <ViewAll link={'/job-category'} title={"SEE ALL OPEN ROLES"} borderColorHover="#fff" borderColor="#000" titleColor="#000" hoverBackground="#000" width="300px" height="44px" svgColor="#000" />
            <BodyRg>
              <Link to={"/scam-alert"}><span className={'magenta'}>Read more</span></Link>about our commitment to safe online recruiting.
            </BodyRg>
          </JobsSectionBottom>
        </JobsGridContent>
      </JobsGrid>
    </JobsSectionWrap>
  );
}

export default JobsSection;

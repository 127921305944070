import React, { useState } from 'react';
import styled from 'styled-components';
import { useAppContext } from '../../store';
import { categoryColors } from '../../constants';
import MediaSearch from 'components/MediaSearch';

// =========== Icons ===========
const PlusIcon = ({ fill }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Add Filter"
    role="img"
  >
    <path
      d="M6.86561 13V3H9.13439V13H6.86561ZM3 9.13439V6.86561H13V9.13439H3Z"
      fill={fill}
    />
  </svg>
);

const MinusIcon = ({ fill }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Remove Filter"
    role="img"
  >
    <path d="M3 9.13451V6.86572H13V9.13451H3Z" fill={fill} />
  </svg>
);

// =========== Data Structures ===========

// Replace contentTypes with your list of capabilities
const capabilities = [
  { id: 'Activation', label: 'Activation' },
  { id: 'Advertising', label: 'Advertising' },
  { id: 'AnalyticsInsights', label: 'Analytics + Insights' },
  { id: 'IntegratedCommunications', label: 'Integrated Communications' },
  { id: 'MedicalCommunications', label: 'Medical Communications' },
  { id: 'ArtificialIntelligence', label: 'Artificial Intelligence' },
];

const defaultColors = {
  borderColor: '#ccc',
  textColor: '#252525',
  hoverBackground: '#f3f3f3',
  hoverColor: '#b3b3b3',
  darkerFill: '#b3b3b3',
};

function MediaButtons() {
  const { selectedMediaFilters, toggleMediaFilter } = useAppContext();

  // Helper to fetch color styles from categoryColors or default
  const getColors = (key) => categoryColors[key] || defaultColors;

  // Sort capabilities so that selected items appear first
  const sortedCapabilities = [...capabilities].sort((a, b) => {
    const aSelected = selectedMediaFilters?.includes(a.id);
    const bSelected = selectedMediaFilters?.includes(b.id);
    return bSelected - aSelected;
  });

  return (
    <Wrapper>
      <Container>
        <FilterLabel>Filter by:</FilterLabel>

        <FiltersContainer>
          <FiltersWrapper>
            {sortedCapabilities.map((cap) => {
              const isSelected = selectedMediaFilters?.includes(cap.id);
              const colors = getColors(cap.id);

              return (
                <FilterItem
                  key={cap.id}
                  selected={isSelected}
                  colors={colors}
                  onClick={() => toggleMediaFilter(cap.id)}
                >
                  <FilterText selected={isSelected} colors={colors}>
                    {cap.label}
                  </FilterText>
                  <ToggleBox selected={isSelected} colors={colors}>
                    {isSelected ? (
                      <MinusIcon fill="#fff" />
                    ) : (
                      <PlusIcon fill={colors.darkerFill} />
                    )}
                  </ToggleBox>
                </FilterItem>
              );
            })}

            <DesktopSearch>
              <MediaSearch text="Search Content" mobileText="Search Content" />
            </DesktopSearch>
          </FiltersWrapper>

          <MobileSearch>
            <MediaSearch text="Search Content" mobileText="Content" />
          </MobileSearch>
        </FiltersContainer>
      </Container>

      <FullWidthLine />
    </Wrapper>
  );
}

export default MediaButtons;

// =========== Styled Components ===========
const Wrapper = styled.div`
  width: 100%;
  background: #fff;

  /* Sticky positioning */
  position: sticky;
  top: 50px;
  z-index: 1001; 

  @media (min-width: 2550px) {
    top: 100px;
  }
`;

const Container = styled.div`
  width: 100%;
  padding: 30px 30px;
  max-width: 1300px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 768px) {
    padding: 18px;
    max-width: 716px;
  }
`;

const FiltersContainer = styled.div`
  width: 100%;
  position: relative;
`;

const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-bottom: 8px;
  flex-wrap: wrap;
`;

const DesktopSearch = styled.div`
  padding-left: 16px;

  @media (max-width: 1114px) {
    display: none;
  }
`;

const MobileSearch = styled.div`
  display: none;
  width: 100%;
  margin-top: 16px;

  @media (max-width: 1114px) {
    display: block;
  }
`;

const FullWidthLine = styled.div`
  width: 100%;
  height: 1px;
  background: #999999;
  margin-top: 16px;
`;

const FilterLabel = styled.div`
  color: #333333;
  font-size: 14px;
  font-family: 'Helvetica Now Display', sans-serif;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.5px;
`;

const FilterItem = styled.div`
  cursor: pointer;
  border: 1px solid ${(props) => props.colors.borderColor};
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  transition: background 0.3s ease;
  background: ${(props) =>
    props.selected ? props.colors.hoverBackground : '#fff'};

  &:hover {
    background: ${(props) => props.colors.hoverBackground};
  }
`;

const FilterText = styled.div`
  color: ${(props) =>
    props.selected ? props.colors.hoverColor : props.colors.textColor};
  font-size: 12px;
  font-family: 'Helvetica Now Display', sans-serif;
  font-weight: 700;
  padding: 5px 8px;
  line-height: 16px;
  white-space: nowrap;
`;

const ToggleBox = styled.div`
  width: 28px;
  height: 28px;
  border-left: 1px solid ${(props) => props.colors.borderColor};
  background: ${(props) => (props.selected ? props.colors.darkerFill : 'unset')};
  display: flex;
  align-items: center;
  justify-content: center;
`;


import React from 'react'

import AwardCard from 'components/AwardCard'
import QuoteCard from 'components/QuoteCard'

import FullWidthArticleCard from 'components/ArticleCards/FullWidth'
import InsetImg from 'components/ArticleCards/InsetImg'
import SideBySide from 'components/ArticleCards/SideBySide'
import SmallImg from 'components/ArticleCards/SmallImg'
import Plain from 'components/ArticleCards/Plain'
import { r3cVerifyRecaptcha, RecaptchaBotScore, RecaptchaSiteKey} from "./constants";
import {callApi} from "./config/callApi";

export const renderCard = ({ bgColor, hoverBg, height, imgType, item, leftBorder, reverse, tall }) => {
	const borderLeft = leftBorder ? '1px solid #707070' : 'none'
	const image = (imgType !== 'none') ? item.image : ''
	const isArticle = (item.ctype === 'articles')
	const isAward = (item.ctype === 'awards')
	const isQuote = (item.ctype === 'quotes')

	const defaultHeight = '640px'
	const shortHeight = '320px'

	const openInNewTab = (url) => {
		if (!url) return;
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
		if (newWindow) newWindow.opener = null
	}
	
	if (isArticle) {
		const opl = (item.type === 'News') ? true : false
		const lnk = (item.type === 'News') ? item.externalUrl : '/article' + item.slug
		const ibl = `/images/${item.type}-icon-black.svg`
		const iwh = `/images/${item.type}-icon-white.svg`

		if (image) {
			if (imgType === 'small') {
				return (
					<SmallImg
						image={item.image}
						mobileImage={item.mobileImage}
						link={lnk}
						label={item.label}
						ctaText={item.ctaText}
						description={item.title}
						icon={item.icon}
						iconWhite={iwh}
						height={height ? height : tall ? defaultHeight : shortHeight}
						borderTop="none"
						borderLeft="none"
						type={item.type}
					/>
				)
			}

			else if (imgType === 'wide') {
				return (
					<FullWidthArticleCard
						height={height ? height : tall ? defaultHeight : shortHeight}
						title={item.title}
						ctaText={item.ctaText}
						label={item.label}
						icon={item.icon}
						iconWhite={iwh}
						link={lnk}
						image={item.image}
						mobileImage={item.mobileImage}
						type={item.type}
					/>
				)
			}

			else if (imgType === 'inset') {
				return (
					<InsetImg
						height={height ? height : tall ? defaultHeight : shortHeight}
						borderTop="none"
						icon={item.icon}
						iconWhite={iwh}
						image={item.image}
						mobileImage={item.mobileImage}
						label={item.label}
						link={lnk}
						description={item.title}
						type={item.type}
						imgType={imgType}
					/>
				)
			}

			else {
				return (
					<SideBySide
						image={image}
						mobileImage={image}
						label={item.label}
						description={item.title}
						icon={item.icon}
						iconWhite={iwh}
						link={lnk}
						offPageLink={opl}
						hoverBg={hoverBg}
						backgroundColor={bgColor}
						borderLeft={borderLeft}
						borderTop="1px solid #707070"
						height={height ? height : tall ? defaultHeight : shortHeight}
						reverse={reverse}
						type={item.type}
					/>
				)
			}
		}
		else {
			return (
				<Plain
					image={image}
					mobileImage={image}
					label={item.label}
					description={item.title}
					icon={ibl}
					iconWhite={iwh}
					link={lnk}
					offPageLink={opl}
					hoverBg={hoverBg}
					backgroundColor={bgColor}
					borderLeft={borderLeft}
					borderTop="none"
					height={height ? height : tall ? defaultHeight : shortHeight}
					reverse={reverse}
					type={item.type}
				/>
			)
		}
	}

	if (isAward) {
		return (
			<AwardCard
				awardLogo={item.icon}
				title={item.title}
				date={item.date}
				borderLeft={borderLeft}
				borderTop="none"
				height={height ? height : shortHeight}
				type={item.type}
				url={item.url}
				onClick={() => openInNewTab(item.url)}
			/>
		)
	}

	if (isQuote) {
		return (
			<QuoteCard
				height={height ? height : defaultHeight}
				description={item.text}
				label={item.attribution}
				type={item.type}
			/>
		)
	}

	return <div></div>
}

export const findContent = (contents, ctype, iNum = 1) => {
	let mcount = 0

	for (let i = 0; i < contents.length; i++) {
		if (contents[i].ctype === ctype) {
			if (++mcount === iNum)
				return contents[i]
		}
	}

	return {}
}

const SCROLL_UP = "UP";
const SCROLL_DOWN = "DOWN";
var lastScrollTop = 0;

export const getLastParallaxItemOffset = () => {
	let infoCardOuterEles = document.querySelectorAll(".parallexItem");
	return infoCardOuterEles[infoCardOuterEles.length - 1].getBoundingClientRect().top;
}
export const getElementPos = (className, attrName) => {
	return getElementBound(document.querySelector(className), attrName);
}
export const getElementBound = (ele, attrName) => {
	return ele.getBoundingClientRect()[attrName];
}
export const getAllElements = (targetCls) => {
	return document.querySelectorAll(targetCls);
}
export const getElement = (targetCls) => {
	return document.querySelector(targetCls);
}
export const isElementInMiddleOfScreen = (targetELeCls, addOffset = null) => {
	let teleTop = typeof targetELeCls === "string" ? getElementPos(targetELeCls, "top") : getElementBound(targetELeCls, "top");
	teleTop = addOffset ? teleTop + addOffset : teleTop;
	return teleTop <= (window.innerHeight / 2);
}
export const handleDirection = () => {
	var st = window.pageYOffset || document.documentElement.scrollTop;
	let dir = st > lastScrollTop ? SCROLL_DOWN : SCROLL_UP;
	lastScrollTop = st <= 0 ? 0 : st;
	return dir;
}
/*export const isScrolledIntoView = (el, visibility = "complete") => {
	var rect = el.getBoundingClientRect();
	var elemTop = rect.top;
	var elemBottom = rect.bottom + rect.height;

	return visibility === "complete" ? ((elemTop >= 0) && (elemBottom < window.innerHeight)) : (elemTop < window.innerHeight && elemBottom >= 0);
}*/
export const handleOnMousewheel = (event) => {
	let scrollSpeed = 0;
	if (event.wheelDelta) scrollSpeed = event.wheelDelta;
	else if (event.detail) scrollSpeed = -event.detail;
	// scrollSpeed = scrollSpeed < 0 ? scrollSpeed * -1 : scrollSpeed;
	return scrollSpeed;
}
export const onScrollingDown = (
	windowsScrolY,
	scrollSpeed,
	targetOffet
) => {

	const parallaxLeftDiv = document.querySelector(".parallaxLeftDiv");
	// let scrolling = parallaxLeftDiv.scrollHeight - parallaxLeftDiv.offsetHeight;
	let scrollStoperTop = getElementPos(".lastReadMore", "top");
	let nextSectionTop = getElementPos(".nextSection", "top");
	if (windowsScrolY >= targetOffet && scrollStoperTop > (window.innerHeight / 3)) {
		if (parallaxLeftDiv && parallaxLeftDiv.style.overflowY !== "auto")
			parallaxLeftDiv.style.overflowY = "auto";

		// } else {
		if (nextSectionTop < (window.innerHeight)) {
			const parallaxLeftDiv = document.querySelector(".parallaxLeftDiv");
			parallaxLeftDiv.style.overflowY = "auto";
			parallaxLeftDiv.scroll({
				top: parallaxLeftDiv.scrollHeight,
				left: 0,
				// behavior: 'smooth'
			});
			return
		}
		parallaxLeftDiv.scroll({
			top: ((windowsScrolY + parallaxLeftDiv.scrollTop - document.querySelector(".parallaxRow").offsetTop) / 5),
			// top: ((-scrollSpeed)),
			left: 0,
		})

		// }
	}
	else {
		if (parallaxLeftDiv && parallaxLeftDiv.style.overflowY === "auto")
			parallaxLeftDiv.style.overflowY = "hidden";
	}
	// breakPoints++;
	// let scrollStoperInView = isScrolledIntoView(document.querySelector(".lastReadMore"));
	// if (windowsScrolY >= targetOffet && isParallexSectionNotInViewPort && breakPoints === 2) {
	//     if (dir === "DOWN" && !scrollStoperInView) {
	//         setScrollLeftDiv(lastTransformVal + scrollSpeed);
	// setLastTransformVal(lastTransformVal + scrollSpeed / 4)
	//     }
	//     if (scrollStoperInView && !isParallexingStopped) {
	//         isParallexingStopped = true;
	//     }
	// }
	// if (breakPoints >= 2)
	//     breakPoints = 0
}
export const onScrollingUp = (
	dir,
	scrollSpeed,
	isParallexSectionNotInViewPort,
	lastTransformVal,
	setScrollLeftDiv,
	setLastTransformVal
) => {
	// const parallaxLeftDiv = document.querySelector(".parallaxLeftDiv");
	// if (dir === "UP") {
	//     if (parallaxLeftDiv && parallaxLeftDiv.style.overflowY !== "auto")
	//         parallaxLeftDiv.style.overflowY = "auto";
	//     parallaxLeftDiv.scroll({
	//         top: ((window.scrollY - (document.querySelector(".parallaxRow").offsetTop + getElementPos(".parallaxRow", "top")) / 2)),
	//         left: 0
	//     })
	// }
	// if (restToDefaultInterval != null) {
	//     clearInterval(restToDefaultInterval);
	//     restToDefaultInterval = null;
	// }
	// if (dir === "UP" && !isScrolledIntoView(document.querySelector(".nextSection"), "partial") && isParallexSectionNotInViewPort) {
	//     if (lastTransformVal >= 0) {
	//         setScrollLeftDiv(lastTransformVal - scrollSpeed);
	//         setLastTransformVal(lastTransformVal - scrollSpeed)
	//     }
	// }
}
// export const handleParallexEnding = (
//     dir,
//     containerRef,
//     lastTransformVal,
//     setScrollLeftDiv,
//     setLastTransformVal
// ) => {
//     // let nextSectionTop = getElementPos(".nextSection", "top");
//     // let lastReadMoreTop = getElementPos(".lastReadMore", "top");

//     // if ((nextSectionTop - lastReadMoreTop) > 100 && isScrolledIntoView(document.querySelector(".scrollStoper"), "partial")) {
//     //     if (dir === "DOWN" && lastTransformVal > 0) {
//     //         setScrollLeftDiv(lastTransformVal - 10);
//     //         setLastTransformVal(lastTransformVal - 10)
//     //     }
//     // }
//     // else if (dir === "DOWN" && (nextSectionTop - lastReadMoreTop) < 100) {
//     //     // let newV = (nextSectionTop - lastReadMoreTop);
//     //     // let res = newV < 0 ? (newV * 0 + 100) : (100 - newV);
//     //     // setScrollLeftDiv(lastTransformVal + res);
//     //     // setLastTransformVal(lastTransformVal + res)
//     // }
// }
var restToDefaultInterval = null;
export const restPositionToDefault = (
	dir,
	scrollSpeed,
	lastTransformVal,
	setScrollLeftDiv,
	setLastTransformVal
) => {
	let lastElementTop = getElementPos(".lastReadMore", "top");
	let windowsHeight = window.innerHeight;
	let endingDifference = windowsHeight - lastElementTop;
	if (dir === "DOWN" && restToDefaultInterval == null && lastElementTop < windowsHeight && (endingDifference > 101 || lastElementTop > 0)) {
		let newLastTransformVal = lastTransformVal;
		restToDefaultInterval = setInterval(() => {
			lastElementTop = getElementPos(".lastReadMore", "top");
			endingDifference = window.innerHeight - lastElementTop;
			if ((endingDifference) <= 100 || newLastTransformVal <= 0) {
				clearInterval(restToDefaultInterval);
				restToDefaultInterval = null;
				return;
			}
			newLastTransformVal = newLastTransformVal - (scrollSpeed > 11 ? newLastTransformVal : scrollSpeed)
			// newLastTransformVal = isElementInMiddleOfScreen() ?  0 : newLastTransformVal;
			// setScrollLeftDiv(newLastTransformVal);
			// setLastTransformVal(newLastTransformVal);
		}, 1, newLastTransformVal, setScrollLeftDiv, setLastTransformVal);
	}

}

export const sortByDate = (data,order)=>{
	return data?.sort(function (a,b){
		let c = new Date(a.date)
		let d = new Date(b.date)
		if(order === 'ascending') return c-d;
		else return d-c;
	})
}

export function getImageDimensions(url, callback) {
	var img = new Image();
	img.src = url;
	img.onload = function() { callback(this.width, this.height); }
}
export function isEmptyObject(obj) {
	return Object.keys(obj).length === 0;
}
//detect single click vs click and hold
export  function useLongPress() {
	return function (callback) {
		let timeout;
		let preventClick = false;
		let leaveClick = false;

		function start() {
			leaveClick = false;
			timeout = setTimeout(() => {
				leaveClick = true
				preventClick = true;
				callback();
			}, 100);
		}

		function clear() {
			timeout && clearTimeout(timeout);
			if(!leaveClick){
				preventClick = false;
			}


		}

		function clickCaptureHandler(e) {
			if (preventClick) {
				e.stopPropagation();
				preventClick = false;
			}
		}

		return {
			onMouseDown: start,
			onTouchStart: start,
			onMouseUp: clear,
			onMouseLeave: clear,
			onTouchMove: clear,
			onTouchEnd: clear,
			onClickCapture: clickCaptureHandler
		};
	}
}

//check if element is visible in viewport
export  function isScrolledIntoView(el) {
	var rect = el.getBoundingClientRect();
	var elemTop = rect.top;
	var elemBottom = rect.bottom;

	// Only completely visible elements return true:
	//var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
	// Partially visible elements return true:
	return elemTop < window.innerHeight && elemBottom >= 0;
}

export function truncate(str, n, useWordBoundary) {
	let original = str;
	var singular, tooLong = str.length > n;
	useWordBoundary = useWordBoundary || true;

	// Edge case where someone enters a ridiculously long string.
	str = tooLong ? str.substr(0, n-1) : str;

	singular = (str.search(/\s/) === -1);
	if(!singular) {
		str = useWordBoundary && tooLong ? str.substr(0, str.lastIndexOf(' ')) : str;
	}
	if(window.innerWidth <1850){
		return  tooLong ? str + '...' : str;
	}else{
		return original;
	}


}


export const recaptchaHelper = async ()=>{
	return new Promise((resolve,reject)=>{
		window.grecaptcha.ready(() => {
			window.grecaptcha.execute(RecaptchaSiteKey, { action: 'submit' }).then(async token => {
				let {data} = await callApi(r3cVerifyRecaptcha,'POST', token);
				if(data?.success && data?.score >= RecaptchaBotScore){
					resolve(true)
				}else{
					resolve(false)
				}
			});
		});
	})
}


export const smartTruncate = (string,length)=>{
	if (!string) return ''

	if(window.innerWidth > 2100){
		length = length - 10
	}
	if (window.innerWidth > 1360 && window.innerWidth  < 1600){
		length = length - 20
	}else if (window.innerWidth > 992 && window.innerWidth  < 1360){
		length = length - 40
	}else if (window.innerWidth < 992){
		length = length - 50
	}
	 const index = string.indexOf(" ", length);
	return index === -1 ? string : string.substring(0, index)+'...'

}

export function scrollToTargetAdjusted(id,offset){
	var element = document.getElementById(id);
	var headerOffset = offset;
	var elementPosition = element.getBoundingClientRect().top;
	var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

	window.scrollTo({
		top: offsetPosition,
		behavior: "smooth"
	});
}
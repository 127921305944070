// AppProvider.js

import React, { createContext } from 'react';

export const AppContext = createContext({
  // ... existing context values
  selectedMediaFilters: [],
  toggleMediaFilter: () => {},
  addMediaFilter: () => {},
  removeMediaFilter: () => {},
  clearMediaFilters: () => {},
});

export class AppProvider extends React.Component {
  constructor(props) {
    super(props);

    // Parse media filters from URL (supports multiple filters, e.g., ?media=blog,podcast)
    const queryParams = new URLSearchParams(window.location.search);
    let mediaFilter = 'all';
    let selectedMediaFilters = [];

    if (queryParams.get("media")) {
      const mediaParam = queryParams.get("media");
      const mediatypes = mediaParam.split(','); // Split by comma for multiple filters

      const mappedFilters = mediatypes
        .map((mediatype) => {
          switch (mediatype.toLowerCase()) {
            case 'blog':
              return 'BlogPost';
            case 'press-releases':
              return 'PressRelease';
            case 'podcasts':
              return 'Podcast';
            case 'events':
              return 'Event';
            default:
              return null;
          }
        })
        .filter((filter) => filter !== null); // Remove any null values

      mediaFilter = mappedFilters.length > 0 ? mappedFilters[0] : 'all';
      selectedMediaFilters = mappedFilters.length > 1 ? mappedFilters.slice(1) : [];
      
      setTimeout(() => {
        if (mappedFilters[0] !== 'all') {
          const element = document.getElementById(mappedFilters[0]);
          if (element) {
            window.scrollTo({ top: element.offsetTop, behavior: "smooth" });
          }
        }
      }, 1000);
    }

    this.state = {
      logoAniComplete: false,
      blackHeader: false,
      subscriptionEmail: "",
      isFormSubmitted: false,
      openedCard: -1,
      locationFilterValue: '',
      capabilityFilterValue: '',
      activeMediaFilter: mediaFilter,
      selectedMediaFilters: selectedMediaFilters, // Initialize with parsed filters
      articleSearchTerm: ''
    };
  }

  // Existing State Update Functions
  updateLogoState = (value) => {
    this.setState({ logoAniComplete: value });
  };

  updateBlackHeaderState = (value) => {
    this.setState({ blackHeader: value });
  };

  updateActiveMedialFilter = (value) => {
    this.setState({ activeMediaFilter: value });
  };

  updateArticlesSearchTerm = (value) => {
    this.setState({ articleSearchTerm: value });
  };

  setSubscriptionEmail = (value) => {
    this.setState({
      subscriptionEmail: value
    });
  };

  serviceOpenedCard = (index) => {
    this.setState({
      openedCard: index
    });
  };

  updateFormSubmitted = (value) => {
    this.setState({
      isFormSubmitted: value
    });
  };

  setLocationFilterValue = (value) => {
    this.setState({
      locationFilterValue: value
    });
  };

  setCapabilityFilterValue = (value) => {
    this.setState({
      capabilityFilterValue: value
    });
  };

// In AppProvider.js - Update these three methods
toggleMediaFilter = (filterId) => {
  this.setState((prevState) => {
    const { selectedMediaFilters } = prevState;
    if (selectedMediaFilters.includes(filterId)) {
      return { 
        selectedMediaFilters: [],
        activeMediaFilter: 'all' // Reset to 'all' when deselecting
      };
    }
    return { 
      selectedMediaFilters: [filterId],
      activeMediaFilter: filterId // Set active filter to new selection
    };
  });
};

  addMediaFilter = (filterId) => {
    this.setState((prevState) => {
      const { selectedMediaFilters } = prevState;
      if (!selectedMediaFilters.includes(filterId)) {
        return {
          selectedMediaFilters: [...selectedMediaFilters, filterId],
        };
      }
      return null; // No state change needed
    });
  };

  removeMediaFilter = (filterId) => {
    this.setState((prevState) => {
      const newFilters = prevState.selectedMediaFilters.filter(
        (id) => id !== filterId
      );
      return {
        selectedMediaFilters: newFilters,
        activeMediaFilter: newFilters.length === 0 ? 'all' : prevState.activeMediaFilter
      };
    });
  };
  

  clearMediaFilters = () => {
    this.setState({ 
      selectedMediaFilters: [],
      activeMediaFilter: 'all' // Reset to 'all' when clearing
    });
  };

  render() {
    const {
      logoAniComplete,
      blackHeader,
      subscriptionEmail,
      isFormSubmitted,
      openedCard,
      locationFilterValue,
      capabilityFilterValue,
      activeMediaFilter,
      selectedMediaFilters,
      articleSearchTerm
    } = this.state;

    return (
      <AppContext.Provider
        value={{
          logoAniComplete,
          blackHeader,
          subscriptionEmail,
          isFormSubmitted,
          openedCard,
          locationFilterValue,
          capabilityFilterValue,
          activeMediaFilter,
          selectedMediaFilters, 
          articleSearchTerm,
          updateFormSubmitted: this.updateFormSubmitted,
          updateLogoState: this.updateLogoState,
          updateBlackHeaderState: this.updateBlackHeaderState,
          setSubscriptionEmail: this.setSubscriptionEmail,
          serviceOpenedCard: this.serviceOpenedCard,
          setCapabilityFilterValue: this.setCapabilityFilterValue,
          setLocationFilterValue: this.setLocationFilterValue,
          setActiveMediaFilter: this.updateActiveMedialFilter,
          setArticleSearchTerm: this.updateArticlesSearchTerm,
          toggleMediaFilter: this.toggleMediaFilter,   // Provide the new functions
          addMediaFilter: this.addMediaFilter,
          removeMediaFilter: this.removeMediaFilter,
          clearMediaFilters: this.clearMediaFilters,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

import React from "react";
import MediaSection from "../MediaSection";
import { MediaHeader, MediaHeaderRight } from "../MediaSection/elements";
import MediaButtons from "../../../components/MediaButtons";
import MediaSearch from "../../../components/MediaSearch";
import CalendarSection from "../CalendarSection";
import TrendingNewsBanner from "components/TrendingBanner";


function MediaAndEventsSection({ searchTerm, id, isLoading, featuredEvents, allArticles, filteredItem, trendingObject }) {
    const [view, setView] = React.useState('standard')

    return (
        <>
            <MediaHeader>

                {/* <MediaHeaderRight>
                    <MediaSearch text={'Search content across all media types'} mobileText={'Search all content'}/>
                </MediaHeaderRight> */}
            </MediaHeader>
            {/* {location.pathname === '/events' ?
                <EventsSectionHeader view={view}
                                     setView={setView}
                                     collapsed={collapsed}
                                     setCollapsed={setCollapsed}/>
                : null} */}
            {/* Media Header End */}
            {view === 'standard' ?
                <MediaSection searchTerm={searchTerm}
                    filteredItem={filteredItem}
                    id={id}
                    isLoading={isLoading}
                    featuredEvents={featuredEvents}
                    allArticles={allArticles} />
                :
                <CalendarSection featuredEvents={featuredEvents} />}
        </>

    );
}

export default MediaAndEventsSection;
import React from 'react';
import styled from 'styled-components';
import { smartTruncate } from '../../generalHelpers';
import Button from 'components/MediaGrid/MediaButton';

const CardWrap = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
  margin: 12px 0;
  padding: 16px;
  background-color: #f9f9f9;
  border: 2px solid var(--Neutral-400, #BDBDBD);

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: row;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

const TitleText = styled.h2`
  font-size: 24px;
  margin: 8px 0;
  color: var(--Text-Black, #333);
  font-weight: 700;
  text-transform: capitalize;
`;

const SubtitleText = styled.h3`
  color: #333;
  font-weight: 400;
  font-size: 24px;
  margin: 16px 0;
`;


function MediaResourcesCard({ title = '', subtitle = '', buttonText = 'Learn More', url = '#', onClick }) {
  const handleButtonClick = (e) => {
    e.preventDefault();

    // If a parent onClick was provided, let it handle everything:
    if (onClick) {
      onClick(e);
      return;
    }

    if (!url) return;

    // If the URL starts with http, assume external -> open new tab
    if (url.startsWith('http')) {
      window.open(url, '_blank');
    } else {
      // Otherwise, navigate in the same tab
      window.location.href = url;
    }
  };

  console.log({ url })
  return (
    <CardWrap>
      <Row>
        <ContentContainer>
          {title && <TitleText>{smartTruncate(title, 75)}</TitleText>}
          {subtitle && <SubtitleText>{smartTruncate(subtitle, 75)}</SubtitleText>}
          {url && (
              <Button
                url={url}
                href={url} // Pass the URL here
                onClick={handleButtonClick}
                variant="primary"
                title="Read More"
                width="245px"
                height="44px"
              >
                {buttonText}
              </Button>
          )}
        </ContentContainer>
      </Row>
    </CardWrap>
  );
}

export default MediaResourcesCard

import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useAppContext } from '../store';
import { useHistory } from 'react-router-dom';

const SearchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 40px; /* Fixed height container */
  gap: 8px;
`;

const SearchIcon = styled.div`
  cursor: pointer;
  padding: 8px;
  z-index: 2;
`;

const MediaSearchInput = styled.input`
  box-sizing: border-box;
  border-radius: 0;
  width: 400px; /* Fixed width */
  border: none;
  border-bottom: 1px solid #252525;
  font-size: 16px;
  line-height: 140%;
  color: #000000;
  padding: 8px 30px 8px 35px;
  background: white;
  transition: border-bottom 0.3s ease;

  &:focus {
    outline: none;
    border-bottom: 2px solid #252525;
  }
`;

const CloseIcon = styled.div`
  cursor: pointer;
  z-index: 2;
  padding: 8px;
`;

function MediaSearch({ text, mobileText }) {
  const { setArticleSearchTerm, setActiveMediaFilter } = useAppContext();
  const [term, setTerm] = useState('');
  const inputRef = useRef(null);
  const history = useHistory();

  const handleSearchKeyDown = (e) => {
    if (e.key === 'Enter' && term.length > 2) {
      runSearch();
    }
  };

  const runSearch = () => {
    if (term.length > 2) {
      setArticleSearchTerm(term);
      setActiveMediaFilter('all');
      history.push('/news-events');
    }
  };

  const handleClose = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    setArticleSearchTerm('');
    setTerm('');
  };

  // If input is empty on blur, reset search to show all items again
  const handleBlur = () => {
    if (term.trim().length === 0) {
      setArticleSearchTerm('');
      setActiveMediaFilter('all');
      setTerm('');
    }
  };

  return (
    <SearchContainer>
      {/* The search icon is always displayed */}
      <SearchIcon onClick={runSearch}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M14.7949 13.3535C15.0586 13.6465 15.0586 14.0859 14.7656 14.3496L13.9453 15.1699C13.6816 15.4629 13.2422 15.4629 12.9492 15.1699L10.0488 12.2695C9.90234 12.123 9.84375 11.9473 9.84375 11.7715V11.2734C8.78906 12.0938 7.5 12.5625 6.09375 12.5625C2.72461 12.5625 0 9.83789 0 6.46875C0 3.12891 2.72461 0.375 6.09375 0.375C9.43359 0.375 12.1875 3.12891 12.1875 6.46875C12.1875 7.9043 11.6895 9.19336 10.8984 10.2188H11.3672C11.543 10.2188 11.7188 10.3066 11.8652 10.4238L14.7949 13.3535ZM6.09375 10.2188C8.14453 10.2188 9.84375 8.54883 9.84375 6.46875C9.84375 4.41797 8.14453 2.71875 6.09375 2.71875C4.01367 2.71875 2.34375 4.41797 2.34375 6.46875C2.34375 8.54883 4.01367 10.2188 6.09375 10.2188Z"
            fill="#252525"
          />
        </svg>
      </SearchIcon>

      {/* Always-visible input */}
      <MediaSearchInput
        ref={inputRef}
        value={term}
        onChange={(e) => setTerm(e.target.value)}
        onKeyDown={handleSearchKeyDown}
        onBlur={handleBlur}
        placeholder={window.innerWidth > 480 ? text : mobileText}
      />

      {/* Close icon only shows if there's something to clear */}
      {term.length > 0 && (
        <CloseIcon onClick={handleClose}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
              d="M15.7071 1.70711L14.2929 0.292893L8 6.58579L1.70711 0.292893L0.292893 1.70711L6.58579 8L0.292893 14.2929L1.70711 15.7071L8 9.41421L14.2929 15.7071L15.7071 14.2929L9.41421 8L15.7071 1.70711Z"
              fill="#252525"
            />
          </svg>
        </CloseIcon>
      )}
    </SearchContainer>
  );
}

export default MediaSearch;

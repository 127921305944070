import React, { useState } from 'react';
import styled from 'styled-components';
import ChipsList from '../../components/Chips'; // Adjust path as needed

import {
  BackButtonContainer,
  BackButtonWrap,
  BackLink
} from '../blog-details/elements';

// --- STYLED COMPONENTS ---

const PageWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #fff;
`;

// Modal Overlay to darken the background
const ModalOverlay = styled.div`
  position: fixed;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

// Modal Container
const ModalContainer = styled.div`
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  text-align: center;
`;

// Close Button
const ModalCloseButton = styled.button`
  background: #3c00e7;
  color: #fff;
  padding: 8px 16px;
  margin-top: 16px;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

/* 
  To make it responsive, we use flex-direction: row by default 
  and switch to column on smaller screens.
*/
const HeroContainer = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* Mobile-friendly */
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
    gap: 40px;
  }
`;

const HeroLeft = styled.div`
  flex: 1 1 0;
  padding: 0 120px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;

  /* Mobile-friendly */
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

const TitleBlock = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const TitleBlockRow = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ReportName = styled.div`
  color: #212121;
  font-size: 48px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  line-height: 60px;
  word-wrap: break-word;

  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 40px;
  }
`;

const AuthorDateBlock = styled.div`
  color: #212121;
  font-size: 15px;
  font-family: Inter, sans-serif;
  line-height: 18.75px;
  word-wrap: break-word;

  span {
    display: block;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const Overview = styled.div`
  align-self: stretch;
  color: #424242;
  font-size: 18px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  line-height: 28.8px;
  word-wrap: break-word;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const ButtonsRow = styled.div`
  width: 100%;
  align-self: stretch;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  flex: 1 1 auto;
  height: 48px;
  border-radius: 4px;
  display: flex;
  max-width: 400px;
  justify-content: start;
  align-items: center;
`;

const ButtonPrimary = styled.div`
  width: 100%;
  max-width: 400px;
  height: 48px;
  padding: 15px 20px;
  background: #3c00e7;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

const ButtonPrimaryText = styled.div`
  color: white;
  font-size: 18px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  line-height: 18px;
  word-wrap: break-word;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const ButtonPrimaryIcon = styled.div`
  color: white;
  font-size: 14px;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  line-height: 14px;
  text-align: center;
`;

const ButtonSecondaryWrapper = styled.div`
  width: 100%;
  max-width: 120px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonSecondary = styled.div`
  width: 100%;
  height: 48px;
  padding: 15px 20px;
  border-radius: 4px;
  border: 1px #bdbdbd solid;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

const ButtonSecondaryText = styled.div`
  color: #212121;
  font-size: 18px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  line-height: 18px;
  word-wrap: break-word;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

/* 
   Make the image responsive by setting `width: 100%`, `height: auto`, and 
   limit the max-width so it doesn't grow beyond its native size. 
*/
const HeroRightImage = styled.img`
  width: 100%;
  max-width: 698px;
  height: auto;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%);
  border-radius: 5px;
  object-fit: cover;

  @media (max-width: 768px) {
    max-width: 100%;
    margin-top: 20px;
  }
`;

// ----- Body -----

const BodyContainer = styled.div`
  width: 100%;
  padding: 120px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 96px;

  @media (max-width: 768px) {
    padding: 40px;
    gap: 48px;
  }
`;

const BodyRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 139px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 40px;
  }
`;

const BodyImage = styled.img`
  width: 100%;
  max-width: 582px;
  height: auto;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%);
  border-radius: 5px;
  object-fit: cover;
`;

const BodyTextColumn = styled.div`
  width: 479px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const BodyTitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const BodyTitle = styled.div`
  color: #212121;
  font-size: 36px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  line-height: 45px;
  word-wrap: break-word;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

const BodyParagraph = styled.div`
  color: #424242;
  font-size: 18px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  line-height: 28.8px;
  word-wrap: break-word;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const BodyButtonsRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  width: 100%;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

// --- MAIN COMPONENT ---
const ReportPage = () => {
  const [showModal, setShowModal] = useState(false);

  // Example chips to replace the single "Medical" pill
  const heroChips = ['Medical', 'Artificial Intelligence', 'Advertising'];

  // When user clicks any "Download" button
  const handleDownloadClick = () => {
    setShowModal(true);
  };

  // Close modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <PageWrapper>
      {/* Modal */}
      <ModalOverlay show={showModal} onClick={handleCloseModal}>
        {/* If you want to close the modal only by clicking "Close" button, 
            remove the onClick above and add onClick to the button alone. */}
        <ModalContainer onClick={(e) => e.stopPropagation()}>
          <p>You have to be signed in to access</p>
          <ModalCloseButton onClick={handleCloseModal}>Close</ModalCloseButton>
        </ModalContainer>
      </ModalOverlay>

      <BackButtonContainer>
        <BackButtonWrap>
          <svg
            width="11"
            height="10"
            viewBox="0 0 11 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="arrow_forward"
              d="M1.50955 5.35714H11V4.64286H1.50955L6.06061 0.505536L5.5 0L0 5L5.5 10L6.06061 9.49446L1.50955 5.35714Z"
              fill="black"
            />
          </svg>
          <BackLink href="/news-events">Back</BackLink>
        </BackButtonWrap>
      </BackButtonContainer>

      {/* Hero Section */}
      <HeroContainer>
        <HeroLeft>
          <TitleBlock>
            <TitleBlockRow>
              <ReportName>Report Name</ReportName>
            </TitleBlockRow>

            {/* Replace "medical pill" with your ChipsList */}
            <ChipsList chips={heroChips} extraSpace={40} />

            <AuthorDateBlock>
              <span style={{ fontWeight: 600 }}>
                Written by Tonyka McKinney, DrPH, MPH / Vice President, Health Equity &nbsp;and&nbsp;
                Jari Melnick Rouas / Real Trial Solutions Portfolio Leader
              </span>
              <span style={{ fontWeight: 400 }}>August 8, 2024</span>
            </AuthorDateBlock>
          </TitleBlock>

          <Overview>
            Quick overview: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
            placerat, ipsum ac auctor ornare, nunc ligula scelerisque eros. Lorem ipsum
            dolor sit amet, consectetur adipiscing elit.
          </Overview>

          <ButtonsRow>
            <ButtonWrapper>
              <ButtonPrimary onClick={handleDownloadClick}>
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.40625 0.75H8.59375C8.94922 0.75 9.25 1.05078 9.25 1.40625V6H11.6289C12.1211 6 12.3672 6.60156 12.0117 6.95703L7.85547 11.1133C7.66406 11.3047 7.30859 11.3047 7.11719 11.1133L2.96094 6.95703C2.60547 6.60156 2.85156 6 3.34375 6H5.75V1.40625C5.75 1.05078 6.02344 0.75 6.40625 0.75ZM14.5 11.0312V14.0938C14.5 14.4766 14.1992 14.75 13.8438 14.75H1.15625C0.773438 14.75 0.5 14.4766 0.5 14.0938V11.0312C0.5 10.6758 0.773438 10.375 1.15625 10.375H5.14844L6.48828 11.7148C7.03516 12.2891 7.9375 12.2891 8.48438 11.7148L9.82422 10.375H13.8438C14.1992 10.375 14.5 10.6758 14.5 11.0312ZM11.1094 13.4375C11.1094 13.1367 10.8633 12.8906 10.5625 12.8906C10.2617 12.8906 10.0156 13.1367 10.0156 13.4375C10.0156 13.7383 10.2617 13.9844 10.5625 13.9844C10.8633 13.9844 11.1094 13.7383 11.1094 13.4375ZM12.8594 13.4375C12.8594 13.1367 12.6133 12.8906 12.3125 12.8906C12.0117 12.8906 11.7656 13.1367 11.7656 13.4375C11.7656 13.7383 12.0117 13.9844 12.3125 13.9844C12.6133 13.9844 12.8594 13.7383 12.8594 13.4375Z"
                    fill="white"
                  />
                </svg>
                <ButtonPrimaryText>Download</ButtonPrimaryText>
              </ButtonPrimary>
            </ButtonWrapper>

            <ButtonSecondaryWrapper>
              <ButtonSecondary>
                <ButtonSecondaryText>Share</ButtonSecondaryText>
              </ButtonSecondary>
            </ButtonSecondaryWrapper>
          </ButtonsRow>
        </HeroLeft>

        <HeroRightImage
          src="https://via.placeholder.com/698x582"
          alt="Hero"
        />
      </HeroContainer>

      {/* Body Section */}
      <BodyContainer>
        <BodyRow>
          <BodyImage
            src="https://via.placeholder.com/582x582"
            alt="Body Content"
          />
          <BodyTextColumn>
            <BodyTitleBlock>
              <BodyTitle>Content Section Headline Goes Here</BodyTitle>
            </BodyTitleBlock>

            <BodyParagraph>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec placerat, ipsum
              ac auctor ornare, nunc ligula scelerisque eros.
            </BodyParagraph>

            <BodyButtonsRow>
              <ButtonWrapper>
                {/* Second "Download" button also triggers the modal */}
                <ButtonPrimary onClick={handleDownloadClick}>
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.40625 0.75H8.59375C8.94922 0.75 9.25 1.05078 9.25 1.40625V6H11.6289C12.1211 6 12.3672 6.60156 12.0117 6.95703L7.85547 11.1133C7.66406 11.3047 7.30859 11.3047 7.11719 11.1133L2.96094 6.95703C2.60547 6.60156 2.85156 6 3.34375 6H5.75V1.40625C5.75 1.05078 6.02344 0.75 6.40625 0.75ZM14.5 11.0312V14.0938C14.5 14.4766 14.1992 14.75 13.8438 14.75H1.15625C0.773438 14.75 0.5 14.4766 0.5 14.0938V11.0312C0.5 10.6758 0.773438 10.375 1.15625 10.375H5.14844L6.48828 11.7148C7.03516 12.2891 7.9375 12.2891 8.48438 11.7148L9.82422 10.375H13.8438C14.1992 10.375 14.5 10.6758 14.5 11.0312ZM11.1094 13.4375C11.1094 13.1367 10.8633 12.8906 10.5625 12.8906C10.2617 12.8906 10.0156 13.1367 10.0156 13.4375C10.0156 13.7383 10.2617 13.9844 10.5625 13.9844C10.8633 13.9844 11.1094 13.7383 11.1094 13.4375ZM12.8594 13.4375C12.8594 13.1367 12.6133 12.8906 12.3125 12.8906C12.0117 12.8906 11.7656 13.1367 11.7656 13.4375C11.7656 13.7383 12.0117 13.9844 12.3125 13.9844C12.6133 13.9844 12.8594 13.7383 12.8594 13.4375Z"
                      fill="white"
                    />
                  </svg>
                  <ButtonPrimaryText>Download</ButtonPrimaryText>
                </ButtonPrimary>
              </ButtonWrapper>

              <ButtonSecondaryWrapper>
                <ButtonSecondary>
                  <ButtonSecondaryText>Share</ButtonSecondaryText>
                </ButtonSecondary>
              </ButtonSecondaryWrapper>
            </BodyButtonsRow>
          </BodyTextColumn>
        </BodyRow>
      </BodyContainer>
    </PageWrapper>
  );
};

export default ReportPage;

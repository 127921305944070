// Insights.js
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PagedList from 'components/PagedList';

// Components
import FooterAnimation from 'components/FooterAnimation';
import MediaAndEventsSection from './MediaAndEventsSection';
import EventsTimelineSection from 'components/EventsTimeline';
import MediaResources from './MediaResources';
import MediaButtons from 'components/MediaButtons';
import MediaListView from 'components/MediaListGrid';
import TrendingNewsBanner from 'components/TrendingBanner';
import TopNewsListView from 'components/TopNewsList';
import Subscribe from 'pages/blog-details/Subscribe';

import { BackButtonContainer, BackButtonWrap, BackLink } from 'pages/blog-details/elements';
// HOC and Hooks
import LoadingHoc from '../../hoc/LoadingHOC';
import useRememberScrollPosition from '../../hooks/useRememberScrollPosition';
import { useAppContext } from '../../store';

// Helpers
import { findContent, getImageDimensions } from 'generalHelpers';

// Constants
import {
  r3cBase,
  r3cPageContent,
  newsEventsPages,
} from 'constants/index';
import { WideScreenContainer } from 'components/elements';

// Styled
import { InsightPageWrap } from './elements';

const KNOWN_CONTENT_TYPES = ['BlogPost', 'Podcast', 'PressRelease', 'Event', 'Report'];

const filterDisplayNames = {
  BlogPost: 'Blogs',
  Podcast: 'Podcasts',
  PressRelease: 'Press Releases',
  Event: 'Events',
  Report: 'Reports',
  ArtificialIntelligence: 'Artificial Intelligence',
  Advertising: 'Advertising',
  AnalyticsInsights: 'Analytics + Insights',
  Activation: 'Activation',
  IntegratedCommunications: 'Integrated Communications',
  MedicalCommunications: 'Medical Communications',
};

const addDisplayNameToChips = (items) => {
  return items.map((item) => {
    if (!item.chips || !Array.isArray(item.chips)) {
      item.chips = [];
    }
    const displayName = filterDisplayNames[item.type] || item.type;
    if (!item.chips.includes(displayName)) {
      item.chips.unshift(displayName);
    }
    return item;
  });
};

const sortByDate = (arr) => {
  return arr.sort((a, b) => new Date(b.date) - new Date(a.date));
};

function Insights(props) {
  useRememberScrollPosition('our-views');
  const { setLoading } = props;

  const {
    activeMediaFilter,
    setActiveMediaFilter,
    articleSearchTerm,
    selectedMediaFilters,
    toggleMediaFilter
  } = useAppContext();

  const [mediaResouresContent, setMediaResouresContent] = useState({});
  const [trendingObject, setTrendingObject] = useState(null);
  const [featuredEvents, setFeaturedEvents] = useState({});
  const [allArticles, setAllArticles] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [topNewsData, setTopNewsData] = useState([]);
  const [seoData, setSeoData] = useState({});
  const [articleLoad, setArticleLoad] = useState(true);

  const location = useLocation();

  // 1. On route change, set the active filter
  useEffect(() => {
    setActiveMediaFilter(newsEventsPages[location.pathname]);
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [location.pathname]);

  // Handle hash change
  useEffect(() => {
    if (location.hash === '#all') {
      toggleMediaFilter('listAll');
      window.scrollTo(0, 0);
    }
  }, [location.hash, toggleMediaFilter]);

  // 2. Fetch /news-events page content
  useEffect(() => {
    fetch(`${r3cPageContent}?route=/news-events`)
      .then((resp) => resp.json())
      .then((page) => {
        // SEO
        const seo = page.seo;
        if (seo?.image) {
          const imageSplit = seo.image.split('.');
          getImageDimensions(seo.image, (width, height) => {
            setSeoData({
              ...seo,
              mimeType: imageSplit[imageSplit.length - 1],
              seoImageWidth: width,
              seoImageHeight: height,
            });
          });
        } else {
          setSeoData(seo);
        }

        // twoColumnCopyImage
        setMediaResouresContent(findContent(page.contents, 'twoColumnCopyImage'));

        // Trending banner
        const trendingObj = findContent(page.contents, 'trendingBanner');
        if (trendingObj) {
          setTrendingObject(trendingObj);
        }

        // Featured events
        const featured = findContent(page.contents, 'featuredEvents');
        let obj = {};
        obj.BlogPost = featured.featuredPosts;
        obj.PressRelease = featured.featuredPressReleases;
        obj.Podcast = featured.featuredPodcasts;
        obj.Event = featured.featuredEvents;
        obj.Report = featured.featuredReports;
        obj.all = featured.featuredContent;

        // Insert display names in chips
        for (const [type, items] of Object.entries(obj)) {
          obj[type] = addDisplayNameToChips(items);
        }
        setFeaturedEvents(obj);

        setLoading(false);
        setArticleLoad(false);
      });
    // eslint-disable-next-line
  }, []);

  // 3. Fetch top news data
  useEffect(() => {
    fetch(`${r3cBase}/news`)
      .then((resp) => resp.json())
      .then((data) => {
        const obj = { items: sortByDate(data) };
        setTopNewsData(obj);
      });
    // eslint-disable-next-line
  }, []);

  // 4. Fetch all articles
  useEffect(() => {
    fetch(`${r3cBase}/all-articles?search=${articleSearchTerm}`)
      .then((resp) => resp.json())
      .then((data) => {
        sortByDate(data);
        setAllArticles(data);

        if (!articleSearchTerm) {
          const tempEvents = data.filter((item) => item.type === 'Event');
          setAllEvents(tempEvents);
        }
      });
    // eslint-disable-next-line
  }, [articleSearchTerm]);

  // 5. Filter logic
  useEffect(() => {
    setArticleLoad(true);

    let filtered = [...allArticles];

    // Filter by search term
    if (articleSearchTerm && articleSearchTerm.trim() !== '') {
      const term = articleSearchTerm.toLowerCase();
      filtered = filtered.filter((item) => {
        return (
          item.title?.toLowerCase().includes(term) ||
          item.author?.toLowerCase().includes(term) ||
          item.topics?.some((topic) => topic.toLowerCase().includes(term))
        );
      });
    }

    // Filter by selectedMediaFilters
    if (
      selectedMediaFilters &&
      selectedMediaFilters.length > 0 &&
      !selectedMediaFilters.includes('all')
    ) {
      if (KNOWN_CONTENT_TYPES.includes(selectedMediaFilters[0])) {
        setActiveMediaFilter(selectedMediaFilters[0]);
      }

      if (activeMediaFilter !== 'listAll') {
        filtered = filtered.filter((item) =>
          selectedMediaFilters.some((filter) => {
            return (
              item.type === filter ||
              (item.chips && item.chips.includes(filterDisplayNames[filter]))
            );
          })
        );
      }
    } else if (activeMediaFilter && activeMediaFilter !== 'all') {
      filtered = filtered.filter((item) => item.type === activeMediaFilter);
    }

    const processed = addDisplayNameToChips(filtered);
    setFilteredArticles(processed);
    setArticleLoad(false);
  }, [
    selectedMediaFilters,
    activeMediaFilter,
    articleSearchTerm,
    allArticles,
    setActiveMediaFilter,
  ]);

  // 6. Media grid items for the top 4
  const getMediaGridItems = () => {
    if (articleSearchTerm) {
      return filteredArticles.slice(0, 4);
    }
    if (selectedMediaFilters.length >= 1) {
      return filteredArticles.slice(0, 4);
    }
    if (activeMediaFilter === 'all' && selectedMediaFilters.length < 1) {
      return featuredEvents.all || [];
    } else if (KNOWN_CONTENT_TYPES.includes(activeMediaFilter)) {
      return featuredEvents[activeMediaFilter] || [];
    }
    return filteredArticles.slice(0, 4);
  };

  const mediaGridItems = getMediaGridItems();

  // The remainder that goes into the large list
  let mediaListItems = filteredArticles
  if (articleSearchTerm) {
    mediaListItems = filteredArticles;
  }

  // 7. Single-filter routes
  const isSingleFilterRoute = [
    '/blog',
    '/all-news',
    '/podcasts',
    '/press-releases',
    '/events',
    '/reports',
    '/financial',
  ].includes(location.pathname);

  if (isSingleFilterRoute) {
    // figure out which route we are on
    const routeFilter = newsEventsPages[location.pathname];

    // gather the articles specifically for this route
    let routeSpecificItems = [];
    if (routeFilter === 'listAll') {
      routeSpecificItems = addDisplayNameToChips(allArticles);
    } else if (routeFilter === 'Event') {
      routeSpecificItems = addDisplayNameToChips(allEvents);
    } else {
      const displayName = filterDisplayNames[routeFilter] || routeFilter;
      const routeFilterLower = routeFilter.toLowerCase();
      const displayNameLower = displayName.toLowerCase();

      const filteredByType = allArticles.filter((item) => {
        const itemTypeLower = item.type?.toLowerCase();
        const itemChipsLower = item.chips?.map((chip) => chip.toLowerCase()) || [];
        return (
          itemTypeLower === routeFilterLower ||
          itemChipsLower.includes(displayNameLower)
        );
      });

      routeSpecificItems = addDisplayNameToChips(filteredByType);
    }


    if (activeMediaFilter !== 'listAll' && activeMediaFilter !== 'financial') {
      routeSpecificItems = filteredArticles
    }
    // figure out which "featured" array to use
    const singlePageFeatured =
      routeFilter === 'listAll'
        ? featuredEvents.all || []
        : featuredEvents[routeFilter] || [];

    return (
      <InsightPageWrap className="pageWrapper">
        <Helmet>
          <title>{seoData.title}</title>
          <link rel="canonical" href={window.location.pathname} />
          <meta name="description" content={seoData.description} />
          <meta name="og:description" content={seoData.description} />
          <meta name="og:title" content={seoData.title} />
          <meta
            name="og:url"
            content={
              window.location.protocol +
              '//' +
              window.location.host +
              window.location.pathname
            }
          />
          <meta name="og:type" content="website" />
          <meta name="og:site_name" content="Real Chemistry" />
          <meta name="og:image" content={window.location.protocol + seoData.image} />
          <meta name="og:image:type" content={'image/' + seoData?.mimeType} />
          <meta name="og:image:width" content={seoData?.seoImageWidth} />
          <meta name="og:image:height" content={seoData?.seoImageHeight} />
        </Helmet>

        <WideScreenContainer>
          {/* 1) The main list of items for this route */}

          <BackButtonContainer>
            <BackButtonWrap>
              <svg
                width="11"
                height="10"
                viewBox="0 0 11 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  id="arrow_forward"
                  d="M1.50955 5.35714H11V4.64286H1.50955L6.06061 0.505536L5.5 0L0 5L5.5 10L6.06061 9.49446L1.50955 5.35714Z"
                  fill="black"
                />
              </svg>
              <BackLink href="/news-events">Back</BackLink>
            </BackButtonWrap>
          </BackButtonContainer>
          <MediaButtons />
          <MediaListView items={routeSpecificItems} filterName={routeFilter} />

          {/* 2) Subscribe */}
          {/* <Subscribe marginBottom="0px" /> */}
          <div id="events">
            <EventsTimelineSection events={allEvents} id="events" />
          </div>

          {/* 4) CMS-based "twoColumnCopyImage" block */}
          <div id="resources">
            <MediaResources
              imageSrc={mediaResouresContent.columnMedia}
              title={mediaResouresContent.title}
              description={
                // Or supply a custom text if you prefer
                mediaResouresContent.columnCopy?.content?.[0]?.content?.[0]?.value
              }
            />
          </div>

          {/* 5) In the news */}
          <div id="news">
            <TopNewsListView items={topNewsData.items} />
          </div>
        </WideScreenContainer>
      </InsightPageWrap>
    );
  }

  // 8. Full layout for '/news-events'
  return (
    <InsightPageWrap className="pageWrapper">
      <Helmet>
        <title>{seoData.title}</title>
        <link rel="canonical" href={window.location.pathname} />
        <meta name="description" content={seoData.description} />
        <meta name="og:description" content={seoData.description} />
        <meta name="og:title" content={seoData.title} />
        <meta
          name="og:url"
          content={
            window.location.protocol +
            '//' +
            window.location.host +
            window.location.pathname
          }
        />
        <meta name="og:type" content="website" />
        <meta name="og:site_name" content="Real Chemistry" />
        <meta name="og:image" content={window.location.protocol + seoData.image} />
        <meta name="og:image:type" content={'image/' + seoData?.mimeType} />
        <meta name="og:image:width" content={seoData?.seoImageWidth} />
        <meta name="og:image:height" content={seoData?.seoImageHeight} />
      </Helmet>

      <WideScreenContainer>
        {trendingObject?.body && (
          <div style={{ scrollMarginTop: '250px' }} id="featured">
            <TrendingNewsBanner
              copy={trendingObject.body}
              linkText={trendingObject.ctaText}
              linkURL={trendingObject.ctaLink}
            />
          </div>
        )}
        {activeMediaFilter === 'listAll' &&
          <BackButtonContainer>
            <BackButtonWrap>
              <svg
                width="11"
                height="10"
                viewBox="0 0 11 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  id="arrow_forward"
                  d="M1.50955 5.35714H11V4.64286H1.50955L6.06061 0.505536L5.5 0L0 5L5.5 10L6.06061 9.49446L1.50955 5.35714Z"
                  fill="black"
                />
              </svg>
              <BackLink href="/news-events">Back</BackLink>
            </BackButtonWrap>
          </BackButtonContainer>
        }
        <MediaButtons />

        {/* (A) Top grid (first 4 items) */}
        {/* (C) Show remainder if not 'all' or if there's a search */}
        {(activeMediaFilter === 'all') && (
          <MediaAndEventsSection
            trendingObject={trendingObject}
            searchTerm={articleSearchTerm}
            filteredItem={activeMediaFilter}
            id="insights"
            isLoading={articleLoad}
            featuredEvents={mediaGridItems}
            allArticles={filteredArticles}
          />
        )}

        {/* (C) Show remainder if not 'all' or if there's a search */}
        {(activeMediaFilter !== 'all' ||
          selectedMediaFilters.length > 0 ||
          articleSearchTerm) && (
            <MediaListView items={mediaListItems} filterName={activeMediaFilter} />
          )}

        {
          activeMediaFilter === 'all' && <Subscribe marginBottom="0px" />
        }

        {/* (B) Timeline only shows if 'all' filter & no search term */}
        {activeMediaFilter === 'all' && !articleSearchTerm && (
          <div id="events">
            <EventsTimelineSection events={allEvents} id="events" />
          </div>
        )}



        {/* (D1) Hard-coded media resources by filter */}
        {activeMediaFilter === 'Activation' && (
          <MediaResources
            link={'/activation'}
            invertLayout
            imageSrc="/images/activation_header_m.svg"
            title="Learn More About Activation"
            description="Our Activation team harnesses the power of data, precise media targeting technology, influence and culture insights to understand your audience so we can activate in the right places and develop content that drives personalized experiences and engagement."
          />
        )}

        {activeMediaFilter === 'Advertising' && (
          <MediaResources
            link={'/advertising'}
            invertLayout
            imageSrc="/images/advertising-med-header-mbl.svg"
            title="Learn More About Advertising"
            description={`Our teams put the heart and soul into all we do across advertising, medical education and more. Including the Cannes Lion-awarded 21GRAMS team, our goal is to make advertising less of an interruption—and more worth people's time, attention and action—because it actually "gets" them.`}
          />
        )}

        {activeMediaFilter === 'AnalyticsInsights' && (
          <MediaResources
            link={'/analytics-and-insights'}
            invertLayout
            imageSrc="/images/analytics-insights-mbl.png"
            title="Learn More About Analytics & Insights"
            description="We find the stories in the data. Together, our team of multi-disciplinary experts deploy patented AI technology on market-leading real-world data sets to piece together the narratives that drive insight and action. We see what others miss — or frequently misunderstand. Our powerful pairing of cutting-edge data science with deep healthcare experience propels our AI models to detect hidden patterns and predict likely behaviors. Whether looking through the microscope to find hard-to-find patients, providing a 360° view on customer behavior, or connecting the dots to help clients navigate complex healthcare ecosystems, our Analytics & Insights teams bring forth the best of Real Chemistry to accelerate clinical development, improve customer engagement and drive commercial performance."
          />
        )}

        {activeMediaFilter === 'IntegratedCommunications' && (
          <MediaResources
            link={'/integrated-communications'}
            invertLayout
            imageSrc="/images/integrated-marketing-header-dsktop.svg"
            title="Learn More About Integrated Communications"
            description="Our Integrated Communications teams are driven by their desire to make an impact for patients, one story at a time. We work in a truly integrated fashion, leveraging our experience and expertise while also collaborating with a best-in-class team and dedicated digital and omnichannel partners."
          />
        )}

        {activeMediaFilter === 'MedicalCommunications' && (
          <MediaResources
            link={'/medical'}
            invertLayout
            imageSrc="/images/medical_header_m.svg"
            title="Learn More About Medical Communications"
            description="Like you, we're in the medical breakthrough business. We can help your story break through to HCPs across the globe, by delivering a precise mix of diverse scientific expertise, laser-focused strategy, customer-centric creative ideas, and the industry's most advanced AI-powered insights."
          />
        )}

        {activeMediaFilter === 'ArtificialIntelligence' && (
          <MediaResources
            invertLayout
            imageSrc="https://images.ctfassets.net/fzkxe4nzcokr/22QPyZJGu73nZQLoRmePg/3abff80cf94507b8bf85ec89e77def22/RCM2024-COMM-001_RCCorpMarComm_RealAIBlogWeb_SocialGraphics_Press_Release_1620_x_1080.png"
            title="Learn More About Artificial Intelligence"
            description="Explore cutting-edge AI technologies and insights that are transforming the healthcare industry."
            link={'/article/real-ai-at-real-chemistry'}
          />
        )}

        {/* (D2) Existing CMS-based twoColumnCopyImage block */}
        <div style={{ scrollMarginTop: '250px' }} id="resources">
          <MediaResources
            imageSrc={mediaResouresContent.columnMedia}
            title={mediaResouresContent.title}
            description={
              mediaResouresContent.columnCopy?.content?.[0]?.content?.[0]?.value
            }
          />
        </div>

        {/* In the news */}
        <div style={{ scrollMarginTop: '250px' }} id="news">
          <TopNewsListView items={topNewsData.items} />
        </div>
      </WideScreenContainer>
    </InsightPageWrap>
  );
}

export default LoadingHoc(Insights);

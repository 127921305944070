import styled from 'styled-components';
import { Col4, Row } from 'components/elements';
import {sizeLaptopLg, sizeTablet, sizeLaptopMd, sizeTabletLg, sizeMobileLg, sizeMobileMd} from "components/devices";

export const MediaWrap = styled.section`
  padding: 30px 30px 60px 30px;
  max-width: 1300px;
  margin: auto;
  ${sizeTablet(`
    padding: 50px 50px;
  `)}
  ${sizeMobileLg(`
    padding: 50px 0px 0;
  `)}
  .mediaCol {
    ${sizeLaptopMd(`
      flex: 0 0 100%;
      max-width: 100%;
    `)}
    .mediaCol {
      ${sizeLaptopMd(`
          flex: 0 0 100%;
          max-width: 100%;
      `)}
    }
  }
  ${Row}{
    ${Col4}{
      flex: 0 0 30%;
      ${sizeTabletLg(`
         flex: 0 0 48%;
         padding: 0 0;
         
      `)}
      ${sizeMobileMd (`
          flex: 0 0 100%;
      `)}
   }
  }

`;
export const MediaHeader = styled.div`
  align-content: center;
  justify-content: space-between;
  ${sizeLaptopMd(`
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        width: 100%;
  `)}
`;
export const MediaHeaderRight = styled.div`
  max-width: 500px;
  width: 100%;
  ${sizeLaptopLg(`   
        max-width: 350px;
  `)}
  ${sizeLaptopMd(`
        margin-top: 30px;
        max-width: 100%;
  `)}
`;
import React, { useCallback, useEffect, useState } from "react";
import {
  FooterWrap,
  FooterRow,
  GetTouch,
  InputWrap,
  InputMail,
  SubmitBtn,
  ArrowSubmit,
  FooterCol1,
  FooterCol2,
  FooterCol3,
  FooterCopy,
  FooterLogo,
  FooterMenu,
  PageName,
  PageLink,
  InfolList,
  InfoItem,
  InfoLink,
  SocialItem,
  SocialIcons,
  SocialIcon,
  FooterLogoLink,
  FooterDol,
  DolTopText,
  InfoA,
} from "./elements";
import { TitleXs, BodySm, TitleMd, Label, WideScreenContainer, Col8, Col4 } from "components/elements";
import { useAppContext } from "store/index"
import { useHistory, useLocation } from "react-router-dom";
import { withRouter } from "react-router-dom"
import useFormManager from 'hooks/useFormManager'
import {
  DynamicContent,
  r3cContent, RecaptchaSiteKey,
} from 'constants/index';
import { useExternalScript } from "../../hooks/useExternalScript";
import { recaptchaHelper } from "../../generalHelpers";

const OPTIONAL = [];

function Footer(props) {
  const recaptchaScript = `https://www.google.com/recaptcha/api.js?render=${RecaptchaSiteKey}`;
  const state = useExternalScript(recaptchaScript);
  const [footerData, setFooterData] = useState({});
  let history = useHistory();
  const { pathname } = useLocation();

  const thmPageLinks = [
    { destination: '/transformative-healthcare-markets#whoweare', label: 'WHO WE ARE' },
    { destination: '/transformative-healthcare-markets#howwehelp', label: 'HOW WE HELP' },
    { destination: '/transformative-healthcare-markets#ourproducts', label: 'OUR PRODUCTS' }
  ];

  useEffect(() => {
    if (DynamicContent) {
      fetch(r3cContent + '/footer').then((response) => response.json()).then((pgData) => {
        setFooterData(pgData);
      })
    } else {
      setFooterData({
        copyrightText: 'All Rights Reserved © Real Chemistry 2022',
        pageLinks: [
          { destination: '/about', label: 'About Us' },
          { destination: '/news-events', label: 'Insights' },
          { destination: '/services', label: 'Services' },
          { destination: '/solutions', label: 'Solutions' },
          { destination: '/careers', label: 'Careers' },
          { destination: '/contact', label: 'Contact Us' },
          { destination: '/newsletters', label: 'Newsletters' }
        ],
        socialLinks: [
          { label: 'Facebook', destination: '#' },
          { label: 'Instagram', destination: '#' },
          { label: 'Twitter', destination: '#' },
          { label: 'Linkedin', destination: '#' },
          { label: 'YouTube', destination: '#' },
        ]
      })
    }
  }, []);


  useEffect(() => {
    if (DynamicContent) {
      fetch(r3cContent + '/footer').then((response) => response.json()).then((pgData) => {
        setFooterData(pgData);
      })
    } else {
      setFooterData({
        copyrightText: 'All Rights Reserved © Real Chemistry 2022',
        pageLinks: [
          {
            destination: '/about',
            label: 'About Us'
          },
          {
            destination: '/news-events',
            label: 'Insights'
          },
          {
            destination: '/services',
            label: 'Services'
          },
          {
            destination: '/solutions',
            label: 'Solutions'
          },
          {
            destination: '/careers',
            label: 'Careers'
          },
          {
            destination: '/contact',
            label: 'Contact Us'
          },
          {
            destination: '/newsletters',
            label: 'Newsletters'
          }
        ],
        socialLinks: [
          {
            label: 'Facebook',
            destination: '#'
          },
          {
            label: 'Instagram',
            destination: '#'
          },
          {
            label: 'Twitter',
            destination: '#'
          },
          {
            label: 'Linkedin',
            destination: '#'
          },
          {
            label: 'YouTube',
            destination: '#'
          },
        ]
      })
    }
  }, [])
  
  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


  useEffect(()=>{
    setSubscription('');
    setValid(true)
  },[pathname])

  const [subscription, setSubscription] = useState("");
  let [isValid, setValid] = useState(true);
  const [validRecaptcha,setValidRecaptcha] = useState(true)
  const { setSubscriptionEmail, updateFormSubmitted } = useAppContext();


  const {
    handleSubmit,
    subscriptionFormData
  } = useFormManager({
    data: {
      email: '',
      from:'footer-form'
    },
    errors: {
      email: ""
    }
  }, OPTIONAL);

  
  const handleOnChangeSubsriptionBtn = useCallback(
    (e) => {
      setSubscription(e.target.value);
      setValid(true)
      if (e.target.value === '') {
        setValid(true)
      }
    },
    [setSubscription]
  );

  const helperSubscriptionStateManager = useCallback(
     () => {
      setSubscriptionEmail(subscription);
      subscriptionFormData.data.email = subscription;
      setSubscription("");
      updateFormSubmitted(false);
      handleSubmit('footer');
      history.push("/subscribe");
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [history, subscription, setSubscriptionEmail, setSubscription, isValid]
  );


  const handelOnSubscriptionBtnClick = useCallback(async (e) => {
      e.preventDefault();

      let validate = validateEmail(subscription);
      let proceed = null

      if (validate) {
        setValid(true)
        proceed = await recaptchaHelper();
        if(proceed){
          setValidRecaptcha(true)
        }else(
            setValidRecaptcha(false)
        )
      } else {
        setValid(false)
      };

      if (validate && proceed) {
        e.preventDefault();
        if (subscription.length <= 0) return;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'e_form_submit01'
        }
        );
        helperSubscriptionStateManager();
      }
    },[subscription, helperSubscriptionStateManager, isValid]);

  const handelOnkeyDown = useCallback(
    (e) => {
      if (e.keyCode === 13 && subscription.length > 0) {
        handelOnSubscriptionBtnClick(e);
      }
    },
    [subscription, helperSubscriptionStateManager]
  );


  const render = () => {
    if (props.location.pathname.includes('/transformative-healthcare-markets')) {
      return (
        <FooterWrap>
          <WideScreenContainer>
            <FooterRow className={"thmFootTopRow"}>
              <Col8>
                <TitleMd color="#fff" fontWeight="900" fontFamily="HelveticaNowDisplay Black">
                  Transformative <br /> Healthcare <br /> Markets
                </TitleMd>
              </Col8>
              <Col4>
                <FooterMenu>
                  {thmPageLinks.map((item, index) => (
                    <PageName key={index} className={"thmFootLinks"}>
                      <PageLink to={item.destination}>
                        {item.label}
                      </PageLink>
                    </PageName>
                  ))}
                </FooterMenu>
              </Col4>
            </FooterRow>
            <FooterRow className={"thmFootBotRow"}>
              <FooterCol3 className={"thmFootCol3"}>
                <FooterCopy className={"thmFootCopy"}>
                  <FooterLogoLink to="/">
                    <FooterLogo
                      src="/images/logo/footer-logo.svg"
                      alt="RC white logo"
                      width="82"
                      height="42"
                    />
                  </FooterLogoLink>
                  <InfolList className="footer-links-row">
                    <InfoItem>
                      <InfoLink to="/privacy-policy">Privacy Policy</InfoLink>
                    </InfoItem>
                    <InfoItem>
                      <InfoA href="/pdf/vendorcodeofconduct.pdf" target="_blank">Vendor Code of Conduct</InfoA>
                    </InfoItem>
                    <InfoItem>
                      <button id="ot-sdk-btn" className="ot-sdk-show-settings">Cookie Settings</button>
                    </InfoItem>
                  </InfolList>
                  <BodySm color="#fff" style={{ marginTop: '1rem' }}>
                    © Real Chemistry 2024. All Rights Reserved
                  </BodySm>
                </FooterCopy>
              </FooterCol3>
            </FooterRow>
          </WideScreenContainer>
        </FooterWrap>
      )
    } else {
      return (
        <FooterWrap id={"subscribe"}>
          <WideScreenContainer>
            <FooterRow>
              <FooterCol1>
                <GetTouch>
                  <TitleXs color="#fff">Join Our Mailing List</TitleXs>
                  <InputWrap>
                    <InputMail
                      id="emailAddress"
                      placeholder="Email Address"
                      value={subscription}
                      onChange={handleOnChangeSubsriptionBtn}
                      onKeyDown={handelOnkeyDown}
                    />
                    <SubmitBtn onClick={handelOnSubscriptionBtnClick}>
                      <ArrowSubmit src="/images/arrow_submit_white.svg" alt="Submit arrow"/>
                    </SubmitBtn>
                  </InputWrap>
                  <Label color={"#7f7f7f !important"}>
                    <p className="recaptcha-copy">
                      This site is protected by reCAPTCHA, and the Google&nbsp;
                      <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>&nbsp;and&nbsp;
                      <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a>&nbsp;apply.
                    </p>
                  </Label>
                  {/* Keep validation messages the same */}
                </GetTouch>
                <SocialIcons>
                  <SocialItem>
                    {/* Keep social icons the same */}
                  </SocialItem>
                </SocialIcons>
              </FooterCol1>
              <FooterCol2 className="footer nav">
                <FooterMenu>
                  {footerData?.pageLinks?.map((item, index) => (
                    <PageName key={index}>
                      <PageLink to={item.destination}>
                        {item.label}
                      </PageLink>
                    </PageName>
                  ))}
                  {/* Keep careers section conditional the same */}
                </FooterMenu>
              </FooterCol2>
              <FooterCol3>
                <FooterCopy>
                  <div style={{display:'flex'}}>

                    <FooterLogoLink to="/">
                      <FooterLogo
                        src="/images/logo/footer-logo.svg"
                        alt="RC white logo"
                        width="82"
                        height="42"
                      />
                    </FooterLogoLink>
                    <BodySm color="#fff" style={{ marginTop: '2rem' }}>
                      {footerData?.copyrightText}
                    </BodySm>
                  </div>
                  <InfolList className="footer-links-row">
                    <InfoItem>
                      <InfoLink to="/privacy-policy">Privacy Policy</InfoLink>
                    </InfoItem>
                    <InfoItem>
                      <InfoA href="/pdf/vendorcodeofconduct.pdf" target="_blank">Vendor Code of Conduct</InfoA>
                    </InfoItem>
                    <InfoItem>
                      <button id="ot-sdk-btn" className="ot-sdk-show-settings">Cookie Settings</button>
                    </InfoItem>
                  </InfolList>
                  <InfolList className="footer-links-row">
                    <InfoItem>
                      <InfoLink to="/privacy-policy">To report an incident anonymously and confidentially please call 1-800-495-1756</InfoLink>
                    </InfoItem>
                  </InfolList>
                </FooterCopy>
              </FooterCol3>
            </FooterRow>
          </WideScreenContainer>
        </FooterWrap>
      )
    }
  }

  if (!props.location.pathname.includes('/content-preview/'))
    return render()

  return null;
}

export default withRouter(Footer);
import React from "react";
import styled from "styled-components";

// Container for the entire component
const TranscriptWrapper = styled.div`
  width: 100%;
  margin: 20px 0;
`;

// Optional title (e.g., "Chairman outline")
const TranscriptTitle = styled.h2`
  color: #424242;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 16px;
`;

// Shared heading style for "Transcript" and "Content Outline"
const SubHeading = styled.span`
  color: #424242;
  font-size: 18px;
  font-weight: 700;
  line-height: 28.8px;
  display: block;
  margin-bottom: 8px;
`;

// Body text for the main transcript
const TranscriptBody = styled.span`
  color: #424242;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  white-space: pre-line;
`;

// Wrapper for each outline item
const OutlineItem = styled.div`
  margin-bottom: 6px;
`;

// Timestamp (underlined)
const Timestamp = styled.span`
  color: #424242;
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
  line-height: 24px;
  margin-right: 8px;
`;

// Text following the timestamp
const OutlineText = styled.span`
  color: #424242;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

function TranscriptAndContentOutline(props) {
  const { title, transcript, transcriptAndContent } = props;

  return (
    <TranscriptWrapper>

      {/* Transcript Section */}
      <div style={{ width: "100%", marginBottom: "20px",maxHeight:'234px',overflow:'scroll' }}>
        <SubHeading>Transcript</SubHeading>
        <TranscriptBody>{transcript}</TranscriptBody>
      </div>

      {/* Content Outline Section */}
      <div style={{ width: "100%" }}>
        <SubHeading>Content Outline</SubHeading>
        {transcriptAndContent.map((item, index) => (
          <OutlineItem key={item.cfid || index}>
            <Timestamp>{item.timestamp}</Timestamp>
            <OutlineText>{item.text}</OutlineText>
          </OutlineItem>
        ))}
      </div>
    </TranscriptWrapper>
  );
}

export default TranscriptAndContentOutline;

import { Link } from "react-router-dom";
import styled, { css } from 'styled-components';
import {
    TitleXs,
    BodySm,
    Label,
    Col8,
    TitleMd,
    Col4
} from "components/elements";

import {
    sizeLaptopMd,
    sizeMobileLg,
    sizeTabletLg,
    sizeMobileMd,
    sizeMobileSm,
    sizeWideScreen,
    sizeTablet
} from "components/devices";

//Footer Style Start
export const FooterWrap = styled.footer`
    background-color: #000;
    padding: 76px 104px;
    postion: relative;
    ${sizeLaptopMd(css`
        padding: 60px 35px;
    `)}
    ${sizeTabletLg(css`
        padding: 60px 20px;
    `)}

    button.ot-sdk-show-settings,
    #ot-sdk-btn.ot-sdk-show-settings {
        font-size: 16px !important;
        line-height: 22px !important;
        font-weight: 400 !important;
        color: #fff !important;
        -webkit-transition: 0.4s all ease !important;
        transition: 0.4s all ease !important;
        background-color: transparent !important;
        border: none !important;
        outline: none !important;
        padding: 0 !important;
        cursor: pointer !important;
        text-transform: capitalize !important;
        text-align: left !important;
        text-decoration: underline !important;
        ${sizeTabletLg(`
            text-align: left !important;
        `)}
        &:focus {
            outline: none;
            box-shadow: none;
        }
        &:hover {
            color: #C3BFC2 !important;
            text-decoration: none !important;
        }

        /* Wide Screen CSS */
        ${sizeWideScreen(`
            font-size: 0.75vw !important;
        `)}
    }
  ul {
    list-style-type: none;
  }
`;
export const FooterRow = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    ${Col8}{
        ${TitleMd}{
            font-size: 64px; 
            line-height: 1.1;
            ${sizeTablet(css`
                font-size: 32px;
            `)}
        }
    }
    ${Col4}{
        margin-top: 15px;
    }
    &.thmFootTopRow {
        align-items: flex-start;
        ${sizeTablet (`
            ${Col4}{
                flex: 0 0 100%;
                max-width: 100%;
                margin-top: 40px;

            }
        `)}
    }
    &.thmFootBotRow{
        padding: 8% 0 0;
        ${sizeTablet (`
            padding: 100px 0 0;
        `)}
    }
    ${sizeMobileLg(css`
        flex-direction: column;
        
    `)}
`;
export const FooterCol1 = styled.div`
    width: 33%;
    flex: 0 0 33%;
    display: flex;
    flex-direction: column;
    ${sizeMobileLg(css`
        width: 100%;
        flex: 0 0 100%;
        margin-bottom: 60px;
    `)}
`;
export const FooterCol2 = styled.div`
    width: 33%;
    flex: 0 0 33%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ${sizeTabletLg(css`
        width: 40%;
        flex: 0 0 40%;
    `)}
    ${sizeMobileLg(css`
        width: 100%;
        flex: 0 0 100%;
        margin-bottom: 60px;
    `)}
`;
export const FooterCol3 = styled.div`
    width: 100%;
    flex: 0 0 100%;
    display: flex;
    ${sizeMobileLg(css`
        justify-content: center;
    `)}
  
    &.thmFootCol3 {
        ${sizeTablet (`
            justify-content: flex-start;

    `)}
    }
`;

export const GetTouch = styled.div`
    margin-bottom: 60px;
    ${TitleXs} {
        margin-bottom: 20px;

        /* Wide Screen CSS */
        ${sizeWideScreen(`
            font-size: 1.1vw;
        `)}
    }
    
    ${Label} {
        color: #D54639;
        text-transform: inherit;
        margin-bottom: 0;
        display: block;
        margin-top: 15px;
        text-align: left;

        /* Wide Screen CSS */
        ${sizeWideScreen(`
            font-size: 0.7vw;
        `)}
    }
`;
export const InputWrap = styled.div`
    position: relative;
    height: 50px;
    border-bottom: 1px solid #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    ${sizeMobileSm(`
        height: 40px;
    `)}
`;
export const InputMail = styled.input`
    border: none;
    background-color: transparent;
    border-radius: 0;
    height: 50px;
    width: calc(100% - 50px);
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    padding: 5px 5px 5px 15px;
    box-sizing: border-box;
    -webkit-border-radius: 0 !important; 
    -moz-border-radius: 0 !important;   
    border-radius: 0 !important;
    &:focus {
        outline: none;
    }
    ${sizeMobileSm(`
        font-size: 16px;
        height: 40px;
        padding-left: 10px;
    `)}
    &:-webkit-autofill, 
    &:-webkit-autofill, 
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus, 
    &:-webkit-autofill, 
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus, 
    &:-webkit-autofill, 
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus {
        -webkit-text-fill-color: #fff !important;
    }


    /* Wide Screen CSS */
    ${sizeWideScreen(`
        font-size: 0.8vw;
    `)}
`;
export const SubmitBtn = styled(Link)`
    width: 60px;
    height: 50px;
    background-color: transparent;
    border-radius: 0;
    border: none;
    position: relative;
    left: 0;
    transition: .4s all ease;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &:focus {
        outline: none;
        box-shadow: none;
    }
    &:hover {
        left: 6px;
        ${sizeTabletLg(`
            left: 0;
        `)}
    }
    ${sizeMobileSm(`
        height: 40px;
        width: 50px;
    `)}
`;
export const ArrowSubmit = styled.img`
    width: 95%;
`;
export const FooterCopy = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    ${BodySm} {
        line-height: 1.3;
        margin: 0 0 0 25px;
        ${sizeMobileLg(css`
            margin: 25px 0 0;
        `)}
    }
    &.thmFootCopy{
        ${sizeTablet (`
            justify-content: flex-start;
            align-items: flex-start;
            img {
                width: 56px;
            }
            p {
                font-size: 16px;
            }
        `)}
    }
`;
export const CopyText = styled.img`

`;
export const FooterLogoLink = styled(Link)`

`;
export const FooterLogo = styled.img`
   
`;

export const FooterMenu = styled.ul`
    
`;
export const PageName = styled.li`
    margin-bottom: 20px;
    &.thmFootLinks{
        margin-bottom: 15px;
    }
`;
export const PageLink = styled(Link)`
    font-size: 32px;
    font-weight: 400;
    color: #fff;
    text-transform: uppercase;
    transition: .4s all ease;
    ${sizeLaptopMd(css`
        font-size: 22px;
    `)}
    ${sizeMobileLg(css`
        font-size: 20px;
    `)}
    &.active,
    &:hover {
        color: #C3BFC2;
    }
`;

export const FooterInfo = styled.div`

.vCOC {
    text-decoration: underline;
    margin-bottom: 20px;
    display: block;
    &:hover {
        color: #C3BFC2 !important;
        text-decoration: none !important;
    }  
}
    
`;
export const InfolList = styled.ul`
display: flex;
margin-top: 25px;
gap: 16px;
`;
export const InfoItem = styled.li`
    text-decoration: underline;
    margin-bottom: 15px;
    font-size:16px;
    line-height: 18px;
    font-weight: 400;
    color: #fff;
`;
export const InfoLink = styled(Link)`
    font-size:16px;
    line-height: 22px;
    font-weight: 400;
    color: #fff;
    transition: 0.4s all ease;
    text-decoration: underline;
    display: block;
    &:hover {
        color: #C3BFC2;
    }

    /* Wide Screen CSS */
    ${sizeWideScreen(`
        font-size: 0.75vw;
    `)}
`;
export const Avatar = styled.img`
    margin-left: 5px;
`;


export const SocialIcons = styled.ul`
    
`;

export const SocialItem = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const SocialIcon = styled.a`

    display: flex;
    transition: 0.4s all ease;
    &:hover {
        transform: scale(1.2);
    }
`;


// Mobile CSS Start
export const FooterInfoMobile = styled.div`
    display: none;
    ${sizeTabletLg(css`
        display: block;
    `)}
`;

export const FooterCopyMobile = styled.div`
    display: none;
    ${sizeTabletLg(css`
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-top: 70px;
        text-align: center;
    `)}
    ${BodySm} {
        line-height: 1.5;
        margin-bottom: 20px;
        ${sizeMobileMd(css`
            font-size: 12px;
        `)}
    }
`;

export const FooterDol = styled.div`
    display: flex;
    flex-direction: column;
    color: #fff;
`;

export const DolTopText = styled.div`
    font-size: ${({ fontSize }) => fontSize || "1.5em"};
    font-weight: ${({ fontWeight }) => fontWeight || "400"};
    text-transform: ${({ textTransform }) => textTransform || "inherit"};
    font-family: ${({ fontFamily }) => fontFamily || "inherit"};
    font-style: ${({ fontStyle }) => fontStyle || "inherit"};
    line-height: ${({ lineHeight }) => lineHeight || "inherit"};
    margin-bottom: 30px;
`;
export const InfoA = styled.a`
    color: #fff;
`;


import React from 'react'
import ReleaseIcon from "../assets/icons/ReleaseIcon";
import PodcastIcon from "../assets/icons/PodcastIcon";
import EventsIcon from "../assets/icons/EventsIcon";
import BlogIcon from "../assets/icons/BlogIcon";

export const DynamicContent = true
export const Verbose = true

// 
// Determine which r3c instance to use.  This decision is based on the value of the
// REACT_APP_RC_ENV shell environment variable.  We will also set the Google reCAPTCHA
// site key appropriately.
//
// NOTE: For local, we currently use `r3c-dev` and a common Google reCAPTCHA key; one that
// allows `localhost` -- the rest use a "real" site key that allows *.realchemistry.com
//

const r3cUrls =
{
  local: 'https://57nwwhnpid.execute-api.us-east-1.amazonaws.com/dev',
  dev: 'https://57nwwhnpid.execute-api.us-east-1.amazonaws.com/dev',
  exp: 'https://lmfoa0avtd.execute-api.us-east-1.amazonaws.com/exp',
  rvw: 'https://6c7xoo31va.execute-api.us-east-1.amazonaws.com/rvw',
  tst: 'https://3bdygmteic.execute-api.us-east-1.amazonaws.com/tst',
  master: 'https://bxx2opi688.execute-api.us-east-1.amazonaws.com/master'
}

const liveRecaptchaSiteKey = '6LdzECAcAAAAAMgqY7EGrm73YazXRegHJ_Ft2UuD'
const localRecaptchaSiteKey = '6LfKyGwcAAAAAPk6Fl6T4esD83nBfA-KTlB-d883'

export var RecaptchaSiteKey = liveRecaptchaSiteKey
export var RecaptchaBotScore = .5

let env = process.env.REACT_APP_RC_ENV

console.log(`REACT_APP_RC_ENV = ${env}`)

if (!env) {
  console.warn(`REACT_APP_RC_ENV unset -- defaulting to local`)
  env = 'local'
}

if (!r3cUrls[env]) {
  console.warn(`REACT_APP_RC_ENV ${env} unknown; using local`)
  env = 'local'
}

if (env === 'local') {
  RecaptchaSiteKey = localRecaptchaSiteKey
  env = 'dev'
}

console.log(`RC env = ${env}`)
console.log(`RecaptchaSiteKey = ${RecaptchaSiteKey}`)

export const r3cBase = r3cUrls[env]
export const r3cJobDetail = r3cBase + '/content/job'
export const r3cPageContent = r3cBase + '/content/page'
export const r3cPardotProxy = r3cBase + '/pardot'
export const r3cRssFeed = r3cBase + '/feed'
export const r3cVerifyRecaptcha = r3cBase + '/verify-recaptcha'
export const r3cContent = r3cBase + '/content'


//
// Used by PlayMarquee
//

export const playArray = Array.from(Array(20).keys()).map((item) => {
  return { itemRef: React.createRef(null) }
})

export const eyeBrows = {
  'all': 'NEWS + EVENTS',
  'PressRelease': 'PRESS RELEASES',
  'Podcast': 'PODCASTS',
  'Event': 'EVENTS',
  'BlogPost': 'BLOG'
}

export const newsEventsPages = {
  '/news-events': 'all',
  '/all-news': 'listAll',
  '/blog': 'BlogPost',
  '/events': 'Event',
  '/podcasts': 'Podcast',
  '/press-releases': 'PressRelease',
  '/financial': 'financial'

}
export const mediaIcons = {
  'PressRelease': <ReleaseIcon />,
  'Podcast': <PodcastIcon />,
  'Event': <EventsIcon />,
  'BlogPost': <BlogIcon />
}


export const categoryColors = {
  Blogs: {
    borderColor: '#FF0000', // Red
    textColor: '#FF0000', // Red
    hoverBackground: '#ffe0e0',
    hoverColor: '#cc3333', // Dark Red
    darkerFill: '#cc3333',
  },
  Podcasts: {
    borderColor: '#800080', // Purple
    textColor: '#800080', // Purple
    hoverBackground: '#f0e0ff',
    hoverColor: '#993399', // Dark Purple
    darkerFill: '#993399',
  },
  'Press Releases': {
    borderColor: '#FFA500', // Orange
    textColor: '#FFA500', // Orange
    hoverBackground: '#ffe8cc',
    hoverColor: '#ff8533', // Dark Orange
    darkerFill: '#ff8533',
  },
  Events: {
    borderColor: '#008000', // Green
    textColor: '#008000', // Green
    hoverBackground: '#e0ffe0',
    hoverColor: '#006400', // Dark Green
    darkerFill: '#006400',
  },

  Reports: {
    borderColor: '#003366',    // Deep navy blue
    textColor: '#003366',      // Deep navy blue
    hoverBackground: '#cce0ff',// A soft, light blue for hover
    hoverColor: '#002244',     // A slightly darker navy for hover text
    darkerFill: '#002244',     // Matching darker navy for filled elements
  },
  Advertising: {
    borderColor: '#808080', // Gray
    textColor: '#808080', // Gray
    hoverBackground: '#f9f9f9',
    hoverColor: '#000000', // Black
    darkerFill: '#b3b3b3',
  },
  Activation: {
    borderColor: '#FF6B6B',
    textColor: '#FF6B6B',
    hoverBackground: '#FFE6E6',
    hoverColor: '#CC5555',
    darkerFill: '#CC5555',
  },
  Advertising: {
    borderColor: '#4ECDC4',
    textColor: '#4ECDC4',
    hoverBackground: '#E0F7F5',
    hoverColor: '#3BA89F',
    darkerFill: '#3BA89F',
  },
  AnalyticsInsights: {
    borderColor: '#FF9F43',
    textColor: '#FF9F43',
    hoverBackground: '#FFF0E0',
    hoverColor: '#CC7F35',
    darkerFill: '#CC7F35',
  },
  IntegratedCommunications: {
    borderColor: '#6C5CE7',
    textColor: '#6C5CE7',
    hoverBackground: '#EAE6FF',
    hoverColor: '#5544CC',
    darkerFill: '#5544CC',
  },
  MedicalCommunications: {
    borderColor: '#00B894',
    textColor: '#00B894',
    hoverBackground: '#E0FFF5',
    hoverColor: '#00997A',
    darkerFill: '#00997A',
  },
  ArtificialIntelligence: {
    borderColor: '#0984E3',
    textColor: '#0984E3',
    hoverBackground: '#E0F0FF',
    hoverColor: '#0768B2',
    darkerFill: '#0768B2',
  },
};
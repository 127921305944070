import React from 'react';
import {
	BodySm,
	TitleLg,
	BodyRg, Row
} from 'components/elements'

import { LinkedinShareButton, TwitterShareButton } from "react-share";
import {
	ArticleInfoWrap,
	ArticleInfo
} from './elements'
import moment from 'moment'
import BlogIcon from "../../../assets/icons/BlogIcon";
import ReleaseIcon from "../../../assets/icons/ReleaseIcon";
import PodcastIcon from "../../../assets/icons/PodcastIcon";
import EventsIcon from "../../../assets/icons/EventsIcon";
import MediaLink from "../../../components/MediaLink";


function ArticleInfoSection({ articleType, articleLabel, articleTitle, authors, position, date, social, ...rest }) {

	const icons = {
		'PressRelease': <ReleaseIcon/>,
		'Podcast': <PodcastIcon/>,
		'Event': <EventsIcon/>,
		'BlogPost': <BlogIcon/>
	}
	const links = {
		'PressRelease': '/press-releases',
		'Podcast': '/podcasts',
		'Event': '/events',
		'BlogPost': '/blog'
	}

	let byline = ''

	if (authors) {
		authors.forEach((author, index) => {
			if (index === 0)
				byline += 'Written by '
			else
				byline += '&nbsp;and&nbsp;'

			byline += '<span class="article author">' + author.name + '</span>'

			if (author.position)
				byline += ' / ' + author.position
		})
	}



	return (
		<ArticleInfoWrap>
			<ArticleInfo>
				{/*<BodySm className="article type" textTransform="uppercase">{articleLabel}</BodySm>*/}
				<Row justifyContent={"center"} margin={"0"}>
					<MediaLink
						// icon={icon}
						link={links[articleType]}
						icon={icons[articleType]}
						text={articleLabel}
						filterTerm={articleType}
					/>
				</Row>
				<TitleLg className="article title">{articleTitle}</TitleLg>

				<BodyRg dangerouslySetInnerHTML={{ __html: byline }}>


				</BodyRg>
				{date ? <BodySm className="article date">{moment(date).format("MMMM D, YYYY")}</BodySm> : ''}
			</ArticleInfo>
				<LinkedinShareButton url={window.location.href}>
					<BodyRg textTransform="uppercase">SHARE ON LINKEDIN</BodyRg>
				</LinkedinShareButton>
			{/*	<TwitterShareButton url={window.location.href}>*/}
			{/*		<BodyRg textTransform="uppercase">SHARE ON TWITTER</BodyRg>*/}
			{/*	</TwitterShareButton>*/}
			{/*</AuthorSocial>*/}
		</ArticleInfoWrap>
	)
}

export default ArticleInfoSection

